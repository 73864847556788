import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { IconButton, Skeleton, VStack } from '@chakra-ui/react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    InputText,
    Icon,
} from '../../../../../../components';
import { useCreateLabel } from '../../queries';
import { EditLabelPopup } from '../EditLabelPopup/EditLabelPopup';
import { LabelPopupItem } from './components';

import styles from './LabelsPopup.module.css';
import { useGetWorkspaceLabels } from '../../../../../../queries';
import { useUpdateLabelsStateOnCreate } from '../../hooks';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../../../../constants';
import { trpc } from '@core/api/trpc';

interface LabelsPopupProps {
    isOpen: boolean;
    cardId: number;
    onClose: VoidFunction;
    children: ReactElement;
    onToggle?: VoidFunction;
    labelEditPopupRef: React.MutableRefObject<HTMLDivElement | null>;
    cardLabelIds: number[];
}

export const LabelsPopup = React.forwardRef<
    HTMLDivElement | null,
    LabelsPopupProps
>(
    (
        { isOpen, children, labelEditPopupRef, cardId, cardLabelIds, onClose },
        ref,
    ) => {
        const queryClient = useQueryClient();
        const utils = trpc.useUtils();
        const { data: labelsData, isLoading } = useGetWorkspaceLabels();
        const createLabelMutation = useCreateLabel();
        const { updateLabelsStateOnCreate } = useUpdateLabelsStateOnCreate();

        const [name, setName] = useState('');
        const [newLabelId, setNewLabelId] = useState(0);

        // Custom handler for popover open/close
        const handleOpenChange = (open: boolean) => {
            // Only close the popover if it's being closed and it's not a button click
            if (!open) {
                // Check if the click was on a close button
                const activeElement = document.activeElement;
                const isCloseButtonClick = document.querySelector(
                    '.custom-tag-close-button:active',
                );

                if (isCloseButtonClick) {
                    // If it's a close button click, don't close the popover
                    return;
                }

                onClose();
            }
        };

        // Force refresh labels data when popup is opened
        useEffect(() => {
            if (isOpen) {
                utils.workspace.getWorkspaceLabels.invalidate();
            }
        }, [isOpen, utils]);

        const onAddLabel = () =>
            createLabelMutation.mutate(
                {
                    name: name.trim(),
                    colourId: 1,
                    cardId,
                },
                {
                    onSuccess: (data) => {
                        if (!data.success) {
                            return;
                        }
                        setNewLabelId(data.labelId);

                        // Invalidate queries to ensure the UI updates
                        utils.cards.getCardAndOwner.invalidate({
                            cardId,
                        });
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.GET_BOARD_CARDS],
                        });
                    },
                },
            );

        useEffect(() => {
            if (!isOpen) {
                setName('');
                setNewLabelId(0);
            }
        }, [isOpen]);

        const isLabelAlreadyExist = useMemo(
            () => !!labelsData?.find((el) => el.name?.trim() === name.trim()),
            [name, labelsData],
        );

        const filteredLabelItems = useMemo(
            () =>
                labelsData
                    ?.filter((el) => !cardLabelIds.includes(el.id))
                    .filter((el) =>
                        el.name
                            .toLowerCase()
                            .includes(name.toLowerCase().trim()),
                    ) || [],
            [labelsData, cardLabelIds, name],
        );

        const onToggleLabelColorPickerPopup = useCallback(() => {
            if (!newLabelId) {
                return;
            }

            updateLabelsStateOnCreate();

            setNewLabelId(0);
            setName('');
        }, [newLabelId, updateLabelsStateOnCreate]);

        if (isLoading) {
            return (
                <Skeleton
                    isLoaded={false}
                    w="full"
                    h="32px"
                    startColor="gray.40"
                    endColor="gray.20"
                />
            );
        }

        return (
            <Popover open={isOpen} onOpenChange={handleOpenChange} modal={true}>
                <PopoverTrigger asChild>{children}</PopoverTrigger>
                <PopoverContent
                    ref={ref}
                    sideOffset={10}
                    align="start"
                    side="bottom"
                    className={styles.LabelsPopoverContent}
                    style={{
                        zIndex: 1500,
                        position: 'relative',
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <InputText
                        placeholder="+ Add label"
                        borderRadius="unset"
                        value={name}
                        onChange={(ev) => setName(ev.target.value)}
                        fontWeight={400}
                        h="36px"
                        boxShadow="unset"
                        borderWidth="0px"
                        bg="transparent"
                        px="8px"
                        borderColor="transparent"
                        isDisabled={createLabelMutation.isPending}
                        rightIcon={
                            <EditLabelPopup
                                name={name}
                                cardId={cardId}
                                colourId={1}
                                isCreate={true}
                                workspaceLabelId={newLabelId}
                                align="start"
                                side="right"
                                sideOffset={15}
                                alignOffset={-7}
                                ref={labelEditPopupRef}
                                isOpen={!!newLabelId}
                                onToggle={onToggleLabelColorPickerPopup}
                                onClose={() => {
                                    setNewLabelId(0);
                                    setName('');
                                }}
                            >
                                <IconButton
                                    aria-label="add-label"
                                    variant="outline"
                                    title={
                                        isLabelAlreadyExist
                                            ? 'Label with that name already exist'
                                            : ''
                                    }
                                    minW="24px"
                                    w="24px"
                                    h="24px"
                                    borderRadius="4px"
                                    isLoading={createLabelMutation.isPending}
                                    onClick={onAddLabel}
                                    isDisabled={isLabelAlreadyExist}
                                >
                                    <Icon
                                        name="plus"
                                        width="16px"
                                        height="16px"
                                    />
                                </IconButton>
                            </EditLabelPopup>
                        }
                        inputRightElementProps={{
                            visibility: name ? 'visible' : 'hidden',
                            opacity: name ? 1 : 0,
                            transition:
                                '0.2s opacity ease, 0.2s visibility ease',
                        }}
                        _placeholder={{
                            color: 'gray.35',
                            fontWeight: 400,
                        }}
                        _hover={{
                            borderColor: 'transparent',
                        }}
                        _focusVisible={{
                            borderColor: 'transparent',
                        }}
                    />
                    {filteredLabelItems?.length ? (
                        <VStack
                            borderTopWidth="1px"
                            borderTopColor="gray.15"
                            p="4px"
                            w="full"
                            align="flex-start"
                            spacing="2px"
                            maxH="300px"
                            overflow="auto"
                        >
                            {filteredLabelItems.map((el) => {
                                return (
                                    <LabelPopupItem
                                        key={el.id}
                                        {...el}
                                        labelEditPopupRef={labelEditPopupRef}
                                        cardId={cardId}
                                    />
                                );
                            })}
                        </VStack>
                    ) : null}
                </PopoverContent>
            </Popover>
        );
    },
);
