import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Box,
    Flex,
    HStack,
    Text,
    Input,
    useOutsideClick,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';

interface ChatInfoSectionProps {
    cardId: number;
    initialInfo?: string;
    onInfoChange?: (info: string) => void;
}

export const ChatInfoSection: React.FC<ChatInfoSectionProps> = ({
    cardId,
    initialInfo = '',
    onInfoChange,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [info, setInfo] = useState(initialInfo);
    const inputRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInfo(initialInfo);
    }, [initialInfo]);

    const handleInfoChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setInfo(newValue);
        },
        [],
    );

    const handleSave = useCallback(() => {
        setIsEditing(false);
        if (info !== initialInfo) {
            onInfoChange?.(info);
        }
    }, [info, initialInfo, onInfoChange]);

    useOutsideClick({
        ref: inputRef,
        handler: handleSave,
    });

    const handleFieldClick = useCallback(() => {
        if (!isEditing) {
            setIsEditing(true);
        }
    }, [isEditing]);

    return (
        <HStack spacing={1} align="flex-start">
            <HStack
                spacing={1}
                pt="8px"
                pb="4px"
                whiteSpace="nowrap"
                w="92px"
                flexShrink={0}
            >
                <Icon
                    name="info-circle"
                    width="16px"
                    height="16px"
                    color={colors.tertiary}
                />
                <Text variant="bodyMedium" color="tertiary">
                    Info
                </Text>
            </HStack>
            <Box
                ref={inputRef}
                w="full"
                h="100%"
                bg={isEditing ? 'gray.15' : 'transparent'}
                transition="0.3s background ease"
                borderRadius="4px"
                p="6px 8px"
                minH="24px"
                maxH="calc(100vh - 200px)"
                overflow="auto"
                onClick={handleFieldClick}
                cursor="text"
            >
                <Flex flexWrap="wrap" columnGap={1} rowGap={1}>
                    {isEditing ? (
                        <Input
                            autoFocus
                            value={info}
                            onChange={handleInfoChange}
                            size="sm"
                            borderRadius="4px"
                            fontSize="12px"
                            lineHeight="20px"
                            height="20px"
                            p="0px 4px"
                            border="none"
                            spellCheck={false}
                            _focus={{
                                border: 'none',
                                boxShadow: 'none',
                            }}
                            placeholder="Enter info..."
                        />
                    ) : (
                        <Text
                            w="full"
                            fontSize="12px"
                            lineHeight="20px"
                            color="secondary"
                        >
                            {info || 'No info added'}
                        </Text>
                    )}
                </Flex>
            </Box>
        </HStack>
    );
};
