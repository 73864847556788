import { memo, PropsWithChildren, useEffect } from 'react';
import { Path, useNavigate, useParams } from '@router';
import {
    useGetCurrentUserQuery,
    useGetCurrentUserWorkspacesQuery,
} from '@core/api';
import { Spinner } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../redux/reducers/auth.reducer';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { useLocation } from 'react-router-dom';
import { useLogout } from '../../queries/useLogout';

function Redirects({ children }: PropsWithChildren) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const logout = useLogout();

    const path = location.pathname as Path;

    const { workspaceId } = useParams('/:workspaceId');

    const { isSynced } = useSelector((state: RootStore) => state.syncState);

    const getCurrentUserQuery = useGetCurrentUserQuery();
    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

    // Rework from effect to render?
    useEffect(() => {
        console.log('Redirects: ', {
            path,
            workspaceId,
            isSynced,
            currentUser: getCurrentUserQuery.data,
            currentUserWorkspaces: getCurrentUserWorkspacesQuery.data,
        });

        if (
            getCurrentUserQuery.isSuccess &&
            getCurrentUserWorkspacesQuery.isSuccess &&
            isSynced
        ) {
            // Remove it later, when deps cleared.
            dispatch(setAuthUser(getCurrentUserQuery.data));

            if (!getCurrentUserQuery.data.isOnboarded) {
                console.log('Redirects: to onboarding modal');

                navigate('/onboarding');
                return;
            }

            if (!getCurrentUserQuery.data.workSpace?.id) {
                console.log('Redirects: to workspace selector');

                navigate('/select-workspace');
                return;
            }

            if (
                workspaceId &&
                !getCurrentUserWorkspacesQuery.data.find(
                    ({ id }) => String(id) === workspaceId,
                )
            ) {
                console.log('Redirects: to workspace selector');

                navigate('/select-workspace');
                return;
            }

            if (!workspaceId && getCurrentUserQuery.data.workSpace?.id) {
                console.log('Redirects: to current workspace');

                navigate('/:workspaceId', {
                    params: {
                        workspaceId: String(
                            getCurrentUserQuery.data.workSpace.id,
                        ),
                    },
                });
                return;
            }
        }
    }, [
        getCurrentUserQuery.isSuccess,
        getCurrentUserQuery.data,
        getCurrentUserWorkspacesQuery.isSuccess,
        getCurrentUserWorkspacesQuery.data,
        workspaceId,
        isSynced,
        path,
    ]);

    if (getCurrentUserQuery.isError || getCurrentUserWorkspacesQuery.isError) {
        // const userError = getCurrentUserQuery.error;
        // const workspacesError = getCurrentUserWorkspacesQuery.error;

        // console.error('Detailed Redirects Error State:', {
        //     isUserQueryError: getCurrentUserQuery.isError,
        //     isWorkspacesQueryError: getCurrentUserWorkspacesQuery.isError,
        //     userError:
        //         userError instanceof Error
        //             ? {
        //                   message: userError.message,
        //                   stack: userError.stack,
        //               }
        //             : userError,
        //     workspacesError:
        //         workspacesError instanceof Error
        //             ? {
        //                   message: workspacesError.message,
        //                   stack: workspacesError.stack,
        //               }
        //             : workspacesError,
        //     userQueryState: getCurrentUserQuery.status,
        //     workspacesQueryState: getCurrentUserWorkspacesQuery.status,
        // });

        return (
            <div style={{ textAlign: 'center', padding: '2rem' }}>
                <p>Something went wrong while loading your data.</p>
                <p
                    style={{
                        color: '#666',
                        fontSize: '0.9rem',
                        marginTop: '0.5rem',
                    }}
                >
                    {getCurrentUserQuery.isError
                        ? 'User data failed to load. '
                        : ''}
                    {getCurrentUserWorkspacesQuery.isError ? (
                        <>
                            Workspace data failed to load.
                            <br />
                            Your session has expired. Please sign in again.
                        </>
                    ) : (
                        ''
                    )}
                </p>
                <div style={{ marginTop: '1rem' }}>
                    <button
                        onClick={() => {
                            const accessToken =
                                document.cookie
                                    .split('; ')
                                    .find((row) =>
                                        row.startsWith('accessToken='),
                                    )
                                    ?.split('=')[1] || '';
                            const refreshToken =
                                document.cookie
                                    .split('; ')
                                    .find((row) =>
                                        row.startsWith('refreshToken='),
                                    )
                                    ?.split('=')[1] || '';

                            logout.mutate({ accessToken, refreshToken });
                        }}
                        style={{
                            marginTop: '1rem',
                            padding: '0.5rem 1rem',
                            backgroundColor: '#3182ce',
                            color: 'white',
                            borderRadius: '0.375rem',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                    >
                        Sign In Again
                    </button>
                </div>
            </div>
        );
    }

    if (
        getCurrentUserQuery.isLoading ||
        getCurrentUserWorkspacesQuery.isLoading
    )
        return <Spinner size="xl" />;

    return children;
}

export default memo(Redirects);
