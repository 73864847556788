import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Alerter } from '../../../../../utils';
import { trpc } from '@core/api/trpc';
import type { DeleteMessagePayload } from '../../../types';

export const useDeleteMessage = () => {
    const queryClient = useQueryClient();

    // Use the tRPC mutation instead of the legacy REST API
    const deleteMessageMutation = trpc.messages.deleteMessage.useMutation({
        onSuccess: (data, variables) => {
            if (data.success) {
                Alerter.success('Message successfully deleted');
                // Invalidate chat messages query to refresh the list
                queryClient.invalidateQueries({
                    queryKey: [
                        'chat-messages',
                        variables.cardId,
                        variables.workspaceId,
                    ],
                });
            }
        },
        onError: (error) => {
            Alerter.error(
                error?.message || 'Error occurred while deleting message',
            );
        },
    });

    return deleteMessageMutation;
};
