import React from 'react';
import { IconButtonProps, TagLabelProps, TagProps } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';

interface CustomTagProps {
    label: string;
    bgColor: string;
    labelColor: string;
    wrapperProps?: TagProps;
    labelProps?: TagLabelProps;
    enableCloseButton?: boolean;
    closeButtonProps?: IconButtonProps;
    closeIconColor?: string;
    style?: React.CSSProperties;
}

export const CustomTag: React.FC<CustomTagProps> = ({
    label,
    bgColor,
    labelColor,
    wrapperProps,
    labelProps,
    enableCloseButton,
    closeButtonProps,
    closeIconColor = colors.primary,
    style,
}) => {
    // Create a separate handler for the close button click
    const handleCloseClick = (e: React.MouseEvent) => {
        // Stop the event in its tracks
        e.stopPropagation();
        e.preventDefault();

        // Call the provided onClick handler if it exists
        if (closeButtonProps?.onClick) {
            closeButtonProps.onClick(e as any);
        }
    };

    return (
        <div
            style={{
                display: 'inline-flex',
                alignItems: 'center',
                height: '22px',
                minHeight: '22px',
                borderRadius: '100px',
                padding: '0 8px',
                backgroundColor: bgColor,
                ...style,
                ...(wrapperProps as any)?.style,
            }}
        >
            <div
                style={{
                    fontSize: '13px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    color: labelColor,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: (labelProps?.maxW as string) || 'auto',
                    ...(labelProps as any)?.style,
                }}
            >
                {label}
            </div>

            {enableCloseButton && (
                <div
                    className="custom-tag-close-button"
                    onClick={handleCloseClick}
                    onMouseDown={(e) => e.stopPropagation()}
                    style={{
                        width: '14px',
                        height: '14px',
                        marginLeft: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: 'rgba(255, 255, 255, 0.1)',
                        borderRadius: '50%',
                        cursor: 'pointer',
                        pointerEvents: 'all',
                        position: 'relative',
                        zIndex: 9999,
                    }}
                    onMouseOver={(e) => {
                        (e.currentTarget as HTMLDivElement).style.background =
                            'rgba(255, 255, 255, 0.2)';
                    }}
                    onMouseOut={(e) => {
                        (e.currentTarget as HTMLDivElement).style.background =
                            'rgba(255, 255, 255, 0.1)';
                    }}
                >
                    <Icon
                        name="rounded-close-cross"
                        width="8px"
                        height="9px"
                        color={closeIconColor}
                    />
                </div>
            )}
        </div>
    );
};
