import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useGetWorkspaceId } from '../../../hooks';
import { trpc } from '@core/api/trpc';

export const useArchiveNotification = () => {
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();
    const utils = trpc.useContext();

    const archiveNotificationMutation =
        trpc.notifications.archiveNotication.useMutation({
            onMutate: async (variables) => {
                // Cancel any outgoing refetches
                await queryClient.cancelQueries({
                    queryKey: [
                        QueryKeys.GET_NOTIFICATIONS,
                        workspaceId,
                        'inbox',
                    ],
                });
                await utils.notifications.getUserWorkspaceNotifications.cancel();

                // Snapshot the previous value
                const previousNotifications = queryClient.getQueryData([
                    QueryKeys.GET_NOTIFICATIONS,
                    workspaceId,
                    'inbox',
                ]);

                // Optimistically update the notifications list
                queryClient.setQueryData(
                    [QueryKeys.GET_NOTIFICATIONS, workspaceId, 'inbox'],
                    (old: any) => {
                        if (!old?.items) return old;
                        return {
                            ...old,
                            items: old.items.filter(
                                (notification: any) =>
                                    notification.id !==
                                    variables.notificationId,
                            ),
                            total: old.total - 1,
                        };
                    },
                );

                return { previousNotifications };
            },
            onError: (error: any, _variables, context) => {
                // Revert to the previous value if there's an error
                queryClient.setQueryData(
                    [QueryKeys.GET_NOTIFICATIONS, workspaceId, 'inbox'],
                    context?.previousNotifications,
                );
                Alerter.error(
                    error?.message ||
                        'Error occurred while updating notification',
                );
            },
            onSuccess: () => {
                Alerter.success(
                    'Notification status was successfully updated!',
                );
            },
            onSettled: () => {
                // Invalidate both React Query and tRPC queries
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_NOTIFICATIONS],
                });
                utils.notifications.getUserWorkspaceNotifications.invalidate();

                // Force refetch
                queryClient.refetchQueries({
                    queryKey: [
                        QueryKeys.GET_NOTIFICATIONS,
                        workspaceId,
                        'inbox',
                    ],
                });
            },
        });

    return {
        archiveNotification: (notificationId: number) =>
            archiveNotificationMutation.mutate({
                workspaceId: workspaceId,
                notificationId,
            }),
    };
};
