import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import {
    Button,
    useToast,
    Text,
    VStack,
    Flex,
    useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient, useQuery } from '@tanstack/react-query';

import { mergeCardModalAtom } from '@atoms/modals.atoms';
import { useMergeCards } from '@queries/useMergeCards';
// import { useNavigate } from 'react-router-dom';
import type { RootStore } from '../../redux/createStore';

import { ModalWrapper, Loader, EmptyItemsText } from '../../components';
import { useGetWorkspaceId } from '../../hooks';
import { useTelegram } from '../../services';
import { fetchChats } from '../../services/telegram/utils';
import { ChatItem } from '../select-team-chat/components/ChatItem';

// -------------------------------------------------------------------
// Subcomponent: Renders the list of chats or a loader or empty message
// -------------------------------------------------------------------
function ChatList({
    chats,
    isLoading,
    selectedChatId,
    onSelect,
    style,
}: {
    chats: Array<{
        id: string;
        title: string;
        accessHash?: string;
    }> | null;
    isLoading: boolean;
    selectedChatId: string | null;
    onSelect: (id: string, title: string) => void;
    style?: React.CSSProperties;
}) {
    if (isLoading) {
        return (
            <Loader
                centerProps={{
                    h: '200px',
                    justifyContent: 'center',
                    w: '100%',
                }}
            />
        );
    }

    if (!chats || chats.length === 0) {
        return (
            <EmptyItemsText
                text="Chats are not found"
                wrapperProps={{
                    width: 'full',
                    border: 'none',
                }}
            />
        );
    }

    return (
        <Flex
            flexDirection="column"
            align="start"
            borderRadius="8px"
            borderWidth="1px"
            borderColor="gray.20"
            bg="gray.10"
            w="100%"
            maxH="400px"
            overflowY="auto"
            style={{
                ...style,
            }}
        >
            {chats.map((chat) => (
                <ChatItem
                    key={chat.id}
                    id={chat.id}
                    name={chat.title}
                    accessHash={chat.accessHash}
                    isChecked={chat.id === selectedChatId}
                    onCheckedChange={() => onSelect(chat.id, chat.title)}
                />
            ))}
        </Flex>
    );
}

// -------------------------------------------------------------------
// Subcomponent: Duplicate Deal Alert Modal
// -------------------------------------------------------------------
function DuplicateDealAlert({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) {
    return (
        <ModalWrapper
            isOpen={isOpen}
            onClose={onClose}
            modalContentProps={{
                maxWidth: '320px',
                width: '90%',
                padding: '24px',
            }}
            modalBodyProps={{
                p: '0px',
            }}
        >
            <Flex flexDirection="column" align="center" gap="24px">
                <Text fontWeight={600} fontSize="16px" textAlign="center">
                    Duplicate deal
                </Text>
                <Text textAlign="center" color="secondary" mt={0}>
                    This Telegram chat is already connected to another deal in
                    this workspace. Choose another chat or move the history to
                    the existing deal.
                </Text>
                <Button onClick={onClose} variant="outline" w="full">
                    Okay
                </Button>
            </Flex>
        </ModalWrapper>
    );
}

// -------------------------------------------------------------------
// Main Component: MergeCardModal
// -------------------------------------------------------------------
export const MergeCardModal = () => {
    // Jotai atom for modal open/close + sourceCardId
    const [{ isOpen, sourceCardId }, setMergeCardModal] =
        useAtom(mergeCardModalAtom);

    const workspaceId = useGetWorkspaceId();
    const toast = useToast();
    const queryClient = useQueryClient();
    const tg = useTelegram();
    // const navigate = useNavigate();

    // For the "duplicate deal" modal
    const {
        isOpen: isAlertOpen,
        onOpen: openAlert,
        onClose: closeAlert,
    } = useDisclosure();

    const { isSynced } = useSelector((state: RootStore) => state.syncState);

    const [selectedChat, setSelectedChat] = useState<{
        id: string;
        title: string;
    } | null>(null);

    const handleClose = useCallback(() => {
        setMergeCardModal({
            isOpen: false,
            sourceCardId: null,
            targetCardId: null,
        });
        setSelectedChat(null);
    }, [setMergeCardModal]);

    const { mutate: mergeCards, isPending } = useMergeCards(handleClose, {
        onError: (error) => {
            if (error.message === 'duplicate_deal') {
                openAlert();
            }
        },
    });

    const { data: chatsData, isLoading: isChatsLoading } = useQuery({
        queryKey: ['tgChats', workspaceId],
        queryFn: async () => {
            if (!tg || !workspaceId) return [];
            const res = await fetchChats(queryClient, tg, 25, workspaceId);
            return (
                res?.chats?.map((chat) => ({
                    id: chat.id,
                    title: chat.title,
                    type: chat.type,
                    accessHash: chat.accessHash,
                })) ?? []
            );
        },
        enabled: !!tg && !!isSynced && !!workspaceId,
    });

    const handleSelectChat = useCallback(
        (id: string, title: string) => {
            if (selectedChat?.id === id) {
                setSelectedChat(null);
            } else {
                setSelectedChat({ id, title });
            }
        },
        [selectedChat],
    );

    const handleMerge = useCallback(() => {
        if (!workspaceId || !selectedChat || !sourceCardId) {
            toast({
                title: 'Please select a chat to merge with',
                status: 'error',
                duration: 3000,
            });
            return;
        }

        mergeCards({
            workspaceId,
            sourceCardId,
            targetChatId: Number(selectedChat.id),
        });
    }, [mergeCards, toast, workspaceId, selectedChat, sourceCardId]);

    return (
        <>
            {/* Main Modal */}
            <ModalWrapper
                isOpen={isOpen}
                onClose={handleClose}
                modalContentProps={{
                    maxWidth: '400px',
                    padding: '40px',
                }}
                modalBodyProps={{
                    p: '0px',
                }}
            >
                <Flex flexDirection="column" align="start" gap="32px">
                    <VStack w="100%" spacing={4}>
                        <Text
                            fontWeight={600}
                            fontSize="20px"
                            lineHeight="24px"
                            textAlign="center"
                        >
                            Connect Telegram chat
                        </Text>
                        <Text textAlign="center" color="secondary">
                            Connect a Telegram chat to the deal by syncing it
                            with Dise and merging two cards
                        </Text>
                    </VStack>

                    {/* List or loader */}
                    <ChatList
                        chats={chatsData || null}
                        isLoading={isChatsLoading}
                        style={{
                            height: '288px',
                        }}
                        selectedChatId={selectedChat?.id ?? null}
                        onSelect={handleSelectChat}
                    />

                    <VStack w="full" spacing={3}>
                        <Button
                            w="full"
                            maxW="200px"
                            variant="outline"
                            colorScheme="blue"
                            alignSelf="center"
                            shadow="sm"
                            onClick={handleMerge}
                            isLoading={isPending}
                            isDisabled={!selectedChat}
                        >
                            Merge
                        </Button>
                        <Button
                            onClick={handleClose}
                            w="full"
                            maxW="200px"
                            variant="ghost"
                            alignSelf="center"
                        >
                            Cancel
                        </Button>
                    </VStack>
                </Flex>
            </ModalWrapper>

            {/* Alert Modal for "duplicate deal" */}
            <DuplicateDealAlert isOpen={isAlertOpen} onClose={closeAlert} />
        </>
    );
};
