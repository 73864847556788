import { Alerter } from '../../../../../utils';
import { trpc } from '../../../../../core/api/trpc';
import { LabelItemType } from '../../../../../types';

export const useCreateLabel = () => {
    const utils = trpc.useUtils();
    const createLabelMutation = trpc.labels.createWorkspaceLabel.useMutation({
        onMutate: async (variables) => {
            await utils.workspace.getWorkspaceLabels.cancel();
            const previousLabels = utils.workspace.getWorkspaceLabels.getData();

            // Optimistically add the new label to the workspace labels list
            // This ensures it appears in dropdowns immediately
            const newOptimisticLabel = {
                id: 0, // Temporary ID, will be replaced with real one
                name: variables.name,
                colourId: variables.colourId,
                workspaceId: 0, // Will be filled by server
                changedAt: new Date().toISOString(),
                createdAt: new Date().toISOString(),
                workspaceCardId: variables.cardId ? 0 : null, // If cardId provided, it's assigned
                createdById: 0,
                changedById: 0,
            };

            utils.workspace.getWorkspaceLabels.setData(undefined, [
                ...(previousLabels || []),
                newOptimisticLabel,
            ]);

            return { previousLabels };
        },
        onSettled: () => {
            // Always invalidate workspace labels to ensure the list is up to date
            utils.workspace.getWorkspaceLabels.invalidate();

            // If a card was specified, also invalidate card data
            if (createLabelMutation.variables?.cardId) {
                // Invalidate card queries to show the new label on the card
                utils.cards.getCardAndOwner.invalidate({
                    cardId: createLabelMutation.variables.cardId,
                });
            }
        },
        onSuccess: (data) => {
            Alerter.success('Label was successfully created!');

            // If the label was successfully created and assigned to a card, update the board cards
            if (data.isAssigned) {
                // Force refresh of board cards to show the new label
                utils.workspace.getWorkspaceCards.invalidate();
            }
        },
        onError: (error) => {
            Alerter.error(error.message);
        },
    });

    return createLabelMutation;

    // return useMutation<ApiResponse<number>, unknown, CreateLabelPayload>({
    //     mutationKey: [QueryKeys.CREATE_LABEL],
    //     mutationFn: async (payload) => {
    //         const result = await createLabelMutation.mutateAsync({
    //             name: payload.name,
    //             colourId: payload.colourId,
    //         });

    //         // Format the response to match the legacy API format
    //         return {
    //             value: result.labelId,
    //             status: 200,
    //             success: result.success,
    //             errors: [],
    //             messages: [
    //                 {
    //                     title: 'Success',
    //                     message:
    //                         result.message || 'Label was successfully created!',
    //                 },
    //             ],
    //         };
    //     },
    //     onSuccess: (data, variables) => {
    //         if (!data.success) {
    //             Alerter.error(data.errors?.[0]?.message);
    //             return;
    //         }

    //         // Invalidate queries that might be affected by this mutation
    //         queryClient.invalidateQueries({
    //             queryKey: [
    //                 QueryKeys.GET_WORKSPACE_LABELS,
    //                 variables.workspaceId,
    //             ],
    //         });

    //         Alerter.success(
    //             data.messages?.[0]?.message ||
    //                 'Label was successfully created!',
    //         );
    //     },
    // });
};
