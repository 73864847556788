import { Outlet, useLocation } from 'react-router-dom';
import AuthRedirects from './_auth-redirects';
import { Sidebar } from '../components/MainLayout/components';
import { useEffect } from 'react';

import {
    CreateWorkspaceModal,
    InviteMembersModal,
    OnboardingModal,
    SelectTeamChatModal,
    SyncSettingsModal,
    CreateCardModal,
    MergeCardModal,
    BatchSendingModal,
    ConfModeModal,
} from '../modals';
import { ChatPageHeader, DefaultPageHeader } from '../widgets';
import { TelegramHiddenFrame } from '../components';
import css from './_app.module.css';
import { Path, useParams } from '@router';

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
    );
};

export const Catch = () => {
    return <div>Something went wrong... Caught at _app error boundary</div>;
};

export const Pending = () => <div>Loading from _app...</div>;

export default function App() {
    const location = useLocation();

    const pathname = location.pathname as Path;

    const { workspaceId } = useParams('/:workspaceId');

    useEffect(() => {
        if (isMobileDevice()) {
            window.location.href = 'https://t.me/diseCRM_bot/app';
        }
    }, []);

    const isChat = pathname.includes('/chat');
    const isTelegramSettings = pathname.includes('/telegram-setting');
    const isOnboarding = pathname.includes('/onboarding');
    const isSelectWorkspace = pathname.includes('/select-workspace');
    const isLogin = pathname.includes('/login');

    return (
        <>
            <section className={css.layout}>
                {workspaceId &&
                !isLogin &&
                !isOnboarding &&
                !isSelectWorkspace ? (
                    <Sidebar />
                ) : null}

                <main className={css.main}>
                    {isChat ? <ChatPageHeader /> : null}
                    {isTelegramSettings ? (
                        <DefaultPageHeader
                            title="Telegram Client Settings"
                            iconName="telegram-icon"
                        />
                    ) : null}
                    <section className={css.sublayout}>
                        <TelegramHiddenFrame />

                        <AuthRedirects>
                            <Outlet />
                        </AuthRedirects>
                    </section>
                </main>
            </section>

            <OnboardingModal />

            <InviteMembersModal />

            <CreateWorkspaceModal />

            <CreateCardModal />

            <MergeCardModal />

            <SelectTeamChatModal />

            <BatchSendingModal />

            <SyncSettingsModal />

            <ConfModeModal />
        </>
    );
}
