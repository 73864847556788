import axios from 'axios';

import { ApiResponse } from '../../types';
import { store } from '../../redux/createStore';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;
const BASE_URL_SUBPATH = 'api';

if (!BASE_URL) {
    throw new Error('No VITE_API_BASE_URL provided!');
}

// Create better response when creating new API on Supabase
interface LegacyApiResponse<T> extends ApiResponse<T> {}

const axiosWithAuth = axios.create();

axiosWithAuth.defaults.baseURL = `${BASE_URL}/${BASE_URL_SUBPATH}`;

axiosWithAuth.interceptors.request.use(async (config) => {
    try {
        const token = store.getState().auth.token.accessToken;

        if (token) {
            config.headers.setAuthorization(`Bearer ${token}`);
        }

        return config;
    } catch (error) {
        console.error(`Auth internal error: ${error}`);

        return config;
    }
});

export { axiosWithAuth };

export type { LegacyApiResponse };
