import { Button } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { Icon } from '../../../components';
import { syncSettingsModalAtom } from '../../../atoms/modals.atoms';

interface SyncSettingsButtonProps {
    name?: string;
    iconName?: string;
}

export const SyncSettingsButton = ({
    name = 'Sync Settings',
    iconName = 'settings',
}: SyncSettingsButtonProps) => {
    const setSyncSettingsModal = useSetAtom(syncSettingsModalAtom);

    const handleOpenSyncSettings = () => {
        setSyncSettingsModal({ isOpen: true });
    };

    return (
        <Button
            leftIcon={<Icon name={iconName} height="14px" width="14px" />}
            mr="16px"
            variant="outline"
            bg="gray.10"
            color="secondary"
            size="sm"
            iconSpacing={1}
            px={2}
            height="24px"
            borderRadius="4px"
            onClick={handleOpenSyncSettings}
        >
            {name}
        </Button>
    );
};
