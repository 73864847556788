import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useTelegram } from '../services';
import { atom, useAtom } from 'jotai';
import { useGetTelegramFolders } from '../services/telegram/queries';
import { allowedForSyncChatTypes } from '../constants';
import { fetchChatsInFolder } from '../services/telegram/utils';
import { useEffect } from 'react';
import {
    ApiChat,
    ApiChatFullInfo,
    ApiMessage,
    ApiPeer,
} from '../../../telegram-tt/src/api/types';
import { useGetCurrentUserWorkspaceFoldersQuery } from '@core/api';

const selectedFoldersAtom = atom(new Set<number>());
// export const useSelectedFolders = () => useAtom(selectedFoldersAtom);

// UNUSED with cycles useSyncFolders --- useTgFolders, remove with old wync-chats|onboarding?
export const useTgFolders = () => {
    const tg = useTelegram();
    const { workspaceId } = useParams();
    const queryClient = useQueryClient();

    const { data: userFolders } = useGetCurrentUserWorkspaceFoldersQuery();
    const { data: telegramFolders } = useGetTelegramFolders(true);

    const [selectedFolders, setSelectedFolders] = useAtom(selectedFoldersAtom);

    const selected = Array.from(selectedFolders);

    /**
     * Load chats for selected folders
     */
    const loadTgFoldersChatsQuery = useQuery({
        queryKey: [
            'syncFoldersQuery',
            workspaceId,
            Array.from(selectedFolders.entries()).join(','),
        ],
        queryFn: () => loadTgFoldersWithChats(true),
        enabled:
            !!workspaceId && !!tg?.custom?.proxy && selectedFolders.size > 0,
        // refetchInterval: 5000,
    });

    /**
     * Set selected folders based on user folders and telegram folders
     */
    useEffect(() => {
        if (telegramFolders && userFolders) {
            const userFolderIds = new Set(
                userFolders.folders.map((folder) => folder.telegramFolderId),
            );
            const selectedFolderIds = telegramFolders.list
                .filter((folder) => userFolderIds.has(folder.id))
                .map((folder) => folder.id);

            setSelectedFolders(new Set(selectedFolderIds));
        }
    }, [telegramFolders, userFolders, setSelectedFolders]);

    const loadTgFoldersWithChats = async (silent = false) => {
        if (!tg?.custom?.proxy || !workspaceId) {
            throw new Error('WorkspaceId or Telegram Proxy is undefined');
        }

        if (selectedFolders.size === 0) {
            if (!silent) {
                console.warn('No folders selected');
            }
            return;
        }

        const foldersSelectedArray = Array.from(selectedFolders);

        const chatPromises = foldersSelectedArray.map((folderId) =>
            fetchChatsInFolder(queryClient, tg.custom, folderId),
        );

        const promiseResult = await Promise.all(chatPromises);

        const payload = promiseResult
            .map((elem, index) => ({
                telegramFolderId: foldersSelectedArray[index],
                chats:
                    elem?.filter(
                        (elem) =>
                            allowedForSyncChatTypes.includes(
                                elem.chat?.type || '',
                            ) && elem.chat?.title,
                    ) ?? [],
            }))
            .filter((elem) => elem.chats?.length);

        return payload;

        // const payload = await prepareFoldersSyncPayload({
        //     foldersArray: foldersSelectedArray,
        //     promises: chatPromises,
        // });

        // return payload;
    };

    const toggle = (folderId: number) => {
        setSelectedFolders((prev) => {
            const updatedSet = new Set(prev);
            updatedSet.has(folderId)
                ? updatedSet.delete(folderId)
                : updatedSet.add(folderId);
            return updatedSet;
        });
    };

    /**
     * Normalize chats to be used in the app
     * Example: { 1: { telegramChatId: 1, title: 'Chat 1' }, 2: { telegramChatId: 2, title: 'Chat 2' } }
     */
    const normalizedChats = loadTgFoldersChatsQuery.data?.reduce(
        (acc, folder) => {
            folder.chats.forEach((chat) => {
                acc[chat.id] = chat;
            });
            return acc;
        },
        {} as Record<
            number,
            {
                id: number;
                chat: ApiChat | undefined;
                fullInfo: ApiChatFullInfo | undefined;
                peerInfo: ApiPeer | undefined;
                msg: ApiMessage | undefined;
            }
        >,
    );

    return {
        selected,
        all: loadTgFoldersChatsQuery.data,
        toggle,
        chats: normalizedChats,
    };
};
