import { useCreateWorkspaceMutation } from '@core/api/mutations/create-workspace';
import { useSetAtom } from 'jotai';
import { createdWorkspaceIdAtom } from '@features/create-workspace-new/state';
import { useTelegramLock } from '../../../../services/telegram/lockContext';
import { useQueryClient } from '@tanstack/react-query';
import { useSetCurrentWorkspaceMutation } from '@core/api/mutations/set-current-workspace';
import { useGetCurrentUserQuery } from '@core/api/queries/current-user';
import { useGetCurrentUserWorkspacesQuery } from '@core/api/queries/current-user-workspaces';
import { trpc } from '@core/api/trpc';
import { useModals, useNavigate } from '@router';
import {
    useGetAllChats,
    getAllChatsHistory,
} from '../../../../hooks/useGetAllChats';
import { allowedForSyncChatTypes, QueryKeys } from '../../../../constants';
import { useState } from 'react';

export const useCreateConference = () => {
    const [isLoading, setIsLoading] = useState(false);
    const modals = useModals();
    const navigate = useNavigate();
    const createWorkspaceMutation = useCreateWorkspaceMutation();
    const setCreatedWorkspaceId = useSetAtom(createdWorkspaceIdAtom);
    const lock = useTelegramLock();
    const syncCardsMutation = trpc.workspace.syncCards.useMutation();
    const queryClient = useQueryClient();
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();
    const getCurrentUserQuery = useGetCurrentUserQuery();
    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();
    const updateConferenceMutation =
        trpc.conference.updateConferenceStarted.useMutation();
    // const { setupWorkspaceStages } = useWorkspaceStages();

    const handleCreateWorkspace = async (conferenceName: string) => {
        if (!conferenceName.trim()) return;

        setIsLoading(true);
        try {
            console.log('Creating new workspace...');
            const formData = new FormData();
            formData.append('Name', conferenceName);
            console.log('Conference name:', conferenceName);
            const newWorkspaceId = await createWorkspaceMutation.mutateAsync({
                name: conferenceName,
            });

            setCreatedWorkspaceId(newWorkspaceId);

            // try {
            //   await setupWorkspaceStages(newWorkspaceId);
            // } catch (error) {
            //   console.error('Error setting up stages:', error);
            // }

            console.log('Locking...', lock);
            const targetDate = new Date();
            updateConferenceMutation.mutate({
                workspaceId: newWorkspaceId,
                targetDate: targetDate,
            });

            try {
                await lock.scheduleBackgroundEvent(async (tg) => {
                    if (!newWorkspaceId) return console.log('No workspace id');
                    if (!tg) return console.log('No tg');
                    const allChats = await useGetAllChats(tg, targetDate);
                    if (!allChats) return console.log('No all chats');
                    const chatHistories = await getAllChatsHistory(
                        tg,
                        allChats,
                        targetDate,
                    );
                    const chatsIds = chatHistories.map((card) => card.chatId);
                    const resolvedChatsInfos = await Promise.allSettled(
                        chatsIds.map((chatId: string) =>
                            tg.custom.proxy.getChatById(Number(chatId)),
                        ),
                    );
                    const chatsByIds = resolvedChatsInfos.reduce<
                        Record<string, any>
                    >((acc, chat) => {
                        if (
                            chat.status === 'fulfilled' &&
                            chat.value?.chatShortInfo &&
                            allowedForSyncChatTypes.includes(
                                chat.value.chatShortInfo.type,
                            )
                        ) {
                            acc[chat.value.chatShortInfo.id] =
                                chat.value.chatShortInfo;
                        }
                        return acc;
                    }, {});

                    const folderPayload = {
                        telegramFolderId: 0,
                        chats: Object.values(chatsByIds).map((chat) => ({
                            telegramChatId: Number(chat.id),
                            name: chat.title || 'Unknown chat name',
                        })),
                    };

                    await syncCardsMutation.mutateAsync({
                        workspaceId: newWorkspaceId,
                        folders: [folderPayload],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [QueryKeys.GET_BOARD_CARDS],
                    });
                    queryClient.invalidateQueries({
                        queryKey: [
                            QueryKeys.TG_CHATS,
                            newWorkspaceId.toString(),
                            'individual',
                        ],
                    });

                    try {
                        await setCurrentWorkspaceMutation.mutateAsync({
                            workspaceId: newWorkspaceId,
                        });
                        await getCurrentUserQuery.refetch();
                    } catch (error) {
                        console.error('Error creating workspace:', error);
                    }
                    await getCurrentUserWorkspacesQuery.refetch();
                    modals.close();
                    navigate('/:workspaceId', {
                        params: { workspaceId: String(newWorkspaceId) },
                    });
                });
            } catch (error) {
                console.error('Error creating conference:', error);
            } finally {
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error creating workspace:', error);
            setIsLoading(false);
        }
    };

    return {
        handleCreateWorkspace,
        isLoading,
    };
};
