import { BoardCardItemResponse } from '@features/Dashboard/types';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { useChats } from '../../../../hooks/useChats';
import { useMemo, useCallback } from 'react';

type FilterHandler<T> = (
    card: BoardCardItemResponse,
    chats: ReturnType<typeof useChats>,
    filterValue: T,
) => boolean;

const filtersSchema = z
    .object({
        owner: z.string(),
        labels: z.string(),
        lastActivity: z
            .string()
            .transform((x) => {
                // Handle empty string case
                if (!x) return null;
                try {
                    return JSON.parse(x);
                } catch (e) {
                    console.warn('Failed to parse lastActivity JSON:', x);
                    return null;
                }
            })
            .pipe(
                z
                    .object({
                        operator: z.union([z.literal('lt'), z.literal('gt')]),
                        timeMs: z.number(),
                    })
                    .nullable(),
            ),
    })
    .partial();

export type Filters = z.infer<typeof filtersSchema>;

export const useCardFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    // Memoize filter values to reduce parsing operations on each render
    const filterValues = useMemo(() => {
        const values: Partial<Record<keyof Filters, any>> = {};

        for (const filterName of Object.keys(filterHandlers) as Array<
            keyof Filters
        >) {
            const param = searchParams.get(filterName);
            if (param === null) continue;

            const result = filtersSchema.shape[filterName].safeParse(param);
            if (result.success) {
                values[filterName] = result.data;
            } else {
                console.warn(
                    `Failed to parse filter ${filterName} with value ${param}`,
                );
            }
        }

        return values;
    }, [searchParams]);

    // Memoize the active filters
    const activeFilters = useMemo(() => {
        return Object.keys(filterHandlers).filter((x) =>
            searchParams.has(x as string),
        ) as Array<keyof Filters>;
    }, [searchParams]);

    // Optimize filter application with useCallback and early return for common case
    const applyFilters = useCallback(
        (card: BoardCardItemResponse, chats: ReturnType<typeof useChats>) => {
            // Quick return if no active filters
            if (activeFilters.length === 0) return true;

            for (const filterName of activeFilters) {
                const filterValue = filterValues[filterName];
                if (filterValue === undefined) continue;

                const filterHandler = filterHandlers[
                    filterName
                ] as FilterHandler<typeof filterValue>;

                // Early return if any filter fails
                if (!filterHandler(card, chats, filterValue)) {
                    console.log(
                        `Filter ${filterName} failed for card ${card.cardName}`,
                    );
                    return false;
                }
            }

            return true;
        },
        [activeFilters, filterValues],
    );

    const addFilter = useCallback(
        <T extends keyof Filters>(
            filterName: T,
            filterValue: NonNullable<Filters[T]>,
        ) => {
            const newParams = new URLSearchParams(searchParams);
            newParams.set(
                filterName,
                typeof filterValue === 'string'
                    ? filterValue
                    : JSON.stringify(filterValue),
            );
            setSearchParams(newParams);
        },
        [searchParams, setSearchParams],
    );

    const removeFilter = useCallback(
        (filterName: keyof Filters) => {
            const newParams = new URLSearchParams(searchParams);
            newParams.delete(filterName);
            setSearchParams(newParams);
        },
        [searchParams, setSearchParams],
    );

    const getFilterValue = useCallback(
        <T extends keyof Filters>(filterName: T): Filters[T] => {
            return filterValues[filterName] as Filters[T];
        },
        [filterValues],
    );

    const hasActiveFilters = useCallback(() => {
        return activeFilters.length > 0;
    }, [activeFilters]);

    return {
        applyFilters,
        addFilter,
        removeFilter,
        getFilterValue,
        hasActiveFilters,
    };
};

type FilterHandlers = Required<{
    [FilterName in keyof Filters]: FilterHandler<
        Exclude<Filters[FilterName], undefined>
    >;
}>;

const filterHandlers: FilterHandlers = {
    owner: (card, _chats, owner) => {
        return card?.ownerId === Number(owner);
    },
    labels: (card, _chats, labels) => {
        if (!labels) return true;

        // Convert comma-separated label names instead of IDs
        const labelNames = labels.split(',').map((name) => name.toLowerCase());
        console.log('Filtering by label names:', labelNames);

        // Get card label names
        const cardLabelNames =
            card.labels?.map((label) => label.name.toLowerCase()) || [];
        console.log('Card label names for', card.cardName, ':', cardLabelNames);

        // Check if all filter label names exist in the card's labels
        const isMatch = labelNames.every((filteredLabelName) =>
            cardLabelNames.includes(filteredLabelName),
        );

        console.log('Match result:', isMatch);
        return isMatch;
    },
    lastActivity: (card, chats, lastActivity) => {
        // If lastActivity is null, don't apply any filtering
        if (!lastActivity) return true;

        const chat = chats?.getChatInfo(card.chatTelegramId.toString());
        const time = Date.now() - lastActivity.timeMs;
        const lastMessageDateMs = (chat?.lastMessage.date ?? 0) * 1000;

        switch (lastActivity.operator) {
            case 'lt':
                return time < lastMessageDateMs;
            case 'gt':
                return time > lastMessageDateMs;
            default:
                return true;
        }
    },
};
