import { Alerter } from '../../../utils';
import { trpc } from '@core/api/trpc';

export const useCreateCardTask = () => {
    const utils = trpc.useUtils();
    const mutation = trpc.cardTasks.createCardTask.useMutation({
        onSuccess: () => {
            Alerter.success('Task was successfully created!');
            utils.cardTasks.getCardTasks.invalidate();
        },
    });

    return mutation;
};
