import React from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { LabelWithImage } from '../../../../../../../components';
import { getAvatarBg } from '../../../../../../../utils';

interface OwnerPopupItemProps {
    member: {
        id: number;
        firstName: string | null;
        lastName: string | null;
        thumbnail: string | null;
        telegramUserId: number;
    };
    workspaceId: number;
    cardId: number;
    onOwnerChange: (userId: number | null) => void;
    isSelected: boolean;
}

export const OwnerPopupItem: React.FC<OwnerPopupItemProps> = ({
    member,
    onOwnerChange,
    isSelected,
}) => {
    return (
        <HStack
            w="full"
            p="4px"
            bg={isSelected ? '#F5F5F5' : 'white'}
            transition="0.3s background ease"
            cursor="pointer"
            borderRadius="4px"
            onClick={() => onOwnerChange(member.id)}
            _hover={{
                bg: '#F5F5F5',
            }}
        >
            <LabelWithImage
                label={`${member.firstName || ''} ${member.lastName || ''}`}
                imageLink={member.thumbnail || ''}
                includeAvatar={true}
                avatarProps={{
                    bg: getAvatarBg(member.telegramUserId || 0),
                }}
            />
        </HStack>
    );
};
