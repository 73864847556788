import { Box, Flex } from '@chakra-ui/react';
import { MenuItemProps } from './types';
import styles from './index.module.css';

export const MenuItem: React.FC<MenuItemProps & { onClick?: () => void }> = ({ icon, name, isCompany, onClick }) => {
  return (
    <Flex className={styles.menuItem} onClick={onClick} style={{ cursor: 'pointer' }}>
      <Flex className={styles.menuItemContent}>
        <Box className={styles.leftIcon}>
          {isCompany ? (
            <Box className={styles.company} />
          ) : (
            <Box className={styles.icon} backgroundColor="blue.500" borderRadius="2px" />
          )}
        </Box>
        <Box className={styles.itemName}>{name}</Box>
      </Flex>
    </Flex>
  );
};