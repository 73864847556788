import { useMutation } from '@tanstack/react-query';
import { Alerter } from '../../../utils';
import { useGetCurrentUserQuery } from '@core/api';
import { useDispatch } from 'react-redux';
import { setAuthUser } from '../../../redux/reducers/auth.reducer';
import { trpc } from '@core/api/trpc';

export const useSetTeamChat = () => {
    const dispatch = useDispatch();
    const getCurrentUserQuery = useGetCurrentUserQuery();

    return trpc.workspace.setTeamChat.useMutation({
        onSuccess: async (data) => {
            if (!data.success) {
                return Alerter.error('Error when adding command chat');
            }

            // Temporal solution, remove later
            const currentUser = await getCurrentUserQuery.refetch();
            if (!currentUser?.data) return;
            dispatch(setAuthUser(currentUser.data));
            //

            Alerter.success('The team chat has been successfully added!');
        },
    });
};
