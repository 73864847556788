import { trpc } from '@core/api/trpc';
import { Alerter } from '../../../utils';

export const useUpdateWorkspaceLogo = () => {
    const utils = trpc.useUtils();

    return trpc.workspace.updateWorkspaceLogo.useMutation({
        onSuccess: (data) => {
            console.log('data', data);
            Alerter.success('Workspace logo successfully updated');
            utils.workspace.getWorkspaceInfo.invalidate();
            utils.workspace.getCurrentUserWorkspaces.invalidate();
        },
        onError: (error) => {
            Alerter.error(
                error.message || 'Error occurred while updating workspace logo',
            );
        },
    });
};
