import { trpc } from '@core/api/trpc';
import { useGetWorkspaceId } from '../../../hooks';

const LIMIT = 30;

export const useGetUserWorkspaceNotifications = (isArchived: boolean) => {
    const workspaceId = useGetWorkspaceId();

    return trpc.notifications.getUserWorkspaceNotifications.useInfiniteQuery(
        {
            workspaceId: workspaceId!,
            isArchived,
            limit: LIMIT,
        },
        {
            getNextPageParam: (lastPage: { nextCursor: number | undefined }) =>
                lastPage.nextCursor,
            enabled: !!workspaceId,
            refetchOnWindowFocus: false,
        },
    );
};
