import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { useSignedUrl } from '../../../../hooks/useSignedUrl';

interface IWorkspaceItemImageProps {
    imageLink?: string;
}

export const WorkspaceItemImage: React.FC<IWorkspaceItemImageProps> = ({
    imageLink,
}) => {
    // Only use signed URL for storage paths (not placeholder URLs)
    const isStoragePath = imageLink?.includes('workspace-files');
    const signedUrl = useSignedUrl(isStoragePath ? imageLink || null : null);

    return (
        <Box
            w="16px"
            minW="16px"
            h="16px"
            borderRadius="2px"
            bg={imageLink ? 'transparent' : 'blue.50'}
        >
            <Image
                src={signedUrl || imageLink}
                w="100%"
                h="100%"
                objectFit="cover"
                borderRadius="2px"
            />
        </Box>
    );
};
