import { Alerter } from '../../../utils';
import { trpc } from '../trpc';

export function useInviteMembersToWorkspaceMutation(workspaceId?: number) {
    const utils = trpc.useUtils();

    return trpc.workspace.inviteUserToWorkspace.useMutation({
        onSuccess(data) {
            Alerter.success(data.message || 'Users invited to workspace');
            utils.workspace.getWorkspaceMembers.invalidate();
        },
        onError(error) {
            Alerter.error(error.message);
        },
    });
}
