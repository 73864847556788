import { useEffect, useMemo, useCallback } from 'react';
import {
    Box,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    TabIndicator,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { TabWithIcon } from '../../components';
import {
    InternalChat,
    Activity,
    Templates,
    Tasks,
    Reminders,
    Info,
} from './tabs';
import { RootStore } from '../../redux/createStore';
import { useTelegram } from '../../services';
import { useGetCardActivitiesOptions } from './queries';
import { useUserAvatars, useGetWorkspaceId } from '../../hooks';
import { ApiResponse } from '../../types';
import { QueryKeys, CHAT_TABS, TEAM_CHAT_TABS } from '../../constants';
import { resetChatTeamMembersData } from '../../redux/reducers/chat-state.reducer';
import { useGetBoardCards } from '../Dashboard/queries';
import { useParams, useNavigate } from '../../router';
import { useChats } from '../../hooks/useChats';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useNavigateWithFilters } from '../../hooks/useNavigateWithFilters';
import { trpc } from '@core/api/trpc';

const ChatPage = () => {
    const { chatId: id } = useParams('/:workspaceId/chat/:chatId');
    const cardId = id ? +id : 0;

    const workspaceId = useGetWorkspaceId();
    const tg = useTelegram();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const { data: chatData, isLoading } = trpc.cards.getCardAndOwner.useQuery(
        {
            cardId,
            workspaceId: workspaceId,
        },
        {
            staleTime: 60000,
            suspense: false,
        },
    );

    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigateWithFilters = useNavigateWithFilters();

    useEffect(() => {
        if (location.state?.filterParams) {
            const { filterParams } = location.state;
            const newParams = new URLSearchParams(searchParams);

            Object.entries(filterParams).forEach(([key, value]) => {
                if (value) {
                    newParams.set(key, value as string);
                }
            });

            setSearchParams(newParams);
        }
    }, [location.state, setSearchParams, searchParams]);

    const { isSynced } = useSelector((state: RootStore) => state.syncState);
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);
    const { teamChat } = useSelector((state: RootStore) => state.auth.user);

    const isTeamChat = useMemo(
        () => teamChat?.cardId === cardId,
        [teamChat, cardId],
    );

    const chatTelegramId = useMemo(
        () => chatData?.card.chatTelegramId?.toString() ?? '',
        [chatData?.card.chatTelegramId],
    );

    const cardTitle = useMemo(
        () => chatData?.card.cardName ?? '',
        [chatData?.card.cardName],
    );

    const cardLabels = useMemo(
        () => chatData?.card.labels ?? [],
        [chatData?.card.labels],
    );

    const chat = useChats().getChatInfo(chatTelegramId);

    const idToFetch = useMemo(
        () => (isTeamChat ? 0 : id ? +id : 0),
        [id, isTeamChat],
    );

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    useUserAvatars(teamTelegramIds?.value?.telegramUserIds);

    useGetCardActivitiesOptions(
        !!workspaceId && !!idToFetch,
        workspaceId,
        idToFetch,
    );

    const handleBackToDashboard = useCallback(() => {
        const filterParams = location.state?.filterParams || {
            filter: searchParams.get('filter'),
            labels: searchParams.get('labels'),
            lastActivity: searchParams.get('lastActivity'),
            owner: searchParams.get('owner'),
        };

        const queryParams = new URLSearchParams();
        Object.entries(filterParams).forEach(([key, value]) => {
            if (value) queryParams.set(key, value.toString());
        });

        const queryString = queryParams.toString();
        const path = `/${workspaceId}${queryString ? `?${queryString}` : ''}`;
        navigateWithFilters(path);
    }, [location.state, searchParams, workspaceId, navigateWithFilters]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleBackToDashboard();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleBackToDashboard]);

    useEffect(() => {
        if (chatTelegramId && tg && isSynced) {
            tg?.actions.proxy.openChat({
                id: chatTelegramId.toString(),
            });
            tg?.iframe.contentWindow?.focus();

            return () => {
                dispatch(resetChatTeamMembersData());
            };
        }
    }, [tg, chatTelegramId, dispatch, isSynced]);

    return (
        <Box as="section" id="chat-layout" w="100%" h="100%">
            <Tabs
                variant="unstyled"
                backgroundColor="white"
                borderRadius="8px"
                borderWidth="2px"
                boxShadow="0px 0px 4px 0px #0000000A"
                borderColor="gray.20"
                height={'100%'}
            >
                <Box position={'relative'} ml={'8px'}>
                    <TabList
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                        columnGap="8px"
                    >
                        {(isTeamChat ? TEAM_CHAT_TABS : CHAT_TABS).map(
                            (elem, index) => (
                                <TabWithIcon
                                    key={index}
                                    iconName={elem.iconName}
                                    label={elem.label}
                                />
                            ),
                        )}
                    </TabList>

                    <TabIndicator
                        mt="-1.2px"
                        height="1px"
                        bg="primary"
                        borderRadius="1px"
                    />
                </Box>

                <TabPanels height="calc(100vh - 105px)">
                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <InternalChat
                                key={id}
                                cardId={cardId}
                                workspaceId={workspaceId}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <Info
                                cardTitle={cardTitle ?? ''}
                                workspaceId={workspaceId}
                                cardId={cardId}
                                isCardDataLoading={isLoading}
                                cardLabels={cardLabels}
                                chat={chat}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0" height="100%">
                            <Activity
                                key={id}
                                workspaceId={workspaceId}
                                cardId={cardId}
                            />
                        </TabPanel>
                    ) : null}

                    {!isTeamChat ? (
                        <TabPanel p="0">
                            <Templates
                                cardId={cardId}
                                isUserHasAccess={!!chat}
                            />
                        </TabPanel>
                    ) : null}

                    <TabPanel p="0">
                        <Tasks workspaceId={workspaceId} cardId={cardId} />
                    </TabPanel>

                    {!isTeamChat ? (
                        <TabPanel p="0">
                            <Reminders
                                workspaceId={workspaceId}
                                cardId={cardId}
                            />
                        </TabPanel>
                    ) : null}
                </TabPanels>
            </Tabs>
        </Box>
    );
};

export default ChatPage;
