import { Box, Flex } from "@chakra-ui/react";
import { ConfModeStep } from "../types";
import css from './index.module.css';
import { useRef, useState, useEffect } from "react";
import { CreateConference } from "./components/CreateConference";
import { ExistingConference } from "./components/ExistingConference";

function OnboardingPrivacyControl({ goToNext }: ConfModeStep) {
  const [activeTab, setActiveTab] = useState<'create' | 'existing'>('create');
  const [conferenceName, setConferenceName] = useState('');
  const [selectedConferenceId, setSelectedConferenceId] = useState<string>('');
  const createBtnRef = useRef<HTMLButtonElement>(null);
  const existingBtnRef = useRef<HTMLButtonElement>(null);
  const [underlineStyle, setUnderlineStyle] = useState({ left: 0, width: 0 });

  const handleTabChange = (tab: 'create' | 'existing') => {
    const targetRef = tab === 'create' ? createBtnRef : existingBtnRef;
    if (targetRef.current) {
      const { offsetLeft, offsetWidth } = targetRef.current;
      setUnderlineStyle({ left: offsetLeft, width: offsetWidth });
    }
    setActiveTab(tab);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConferenceName(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (createBtnRef.current) {
        const { offsetLeft, offsetWidth } = createBtnRef.current;
        setUnderlineStyle({ left: offsetLeft, width: offsetWidth });
      }
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box height="200px" display="flex" flexDirection="column">
      <div className={css.toggleContainer}>
        <button
          ref={createBtnRef}
          className={css.toggleButton}
          data-active={activeTab === 'create'}
          onClick={() => handleTabChange('create')}
        >
          Create new Conference
        </button>
        <button
          ref={existingBtnRef}
          className={css.toggleButton}
          data-active={activeTab === 'existing'}
          onClick={() => handleTabChange('existing')}
        >
          Add to existing
        </button>
        <div 
          className={css.underline} 
          style={{ 
            left: underlineStyle.left,
            width: underlineStyle.width 
          }} 
        />
      </div>

      <Flex flex={1} flexDirection="column" justifyContent="center" alignItems="center" mt="10px">
        {activeTab === 'create' ? (
          <CreateConference
            conferenceName={conferenceName}
            onNameChange={handleInputChange}
          />
        ) : (
          <ExistingConference
            selectedConferenceId={selectedConferenceId}
            onConferenceSelect={setSelectedConferenceId}
          />
        )}
      </Flex>
    </Box>
  );
}

export default OnboardingPrivacyControl;
