import { combineReducers } from 'redux';
import authReducer from './auth.reducer';
import onboardingReducer from './onboarding.reducer';
import workspaceReducer from './workspace.reducer';
import avatarsReducer from './avatars.reducer';
import loginFlowReducer from './login-flow.reducer';
import telegramStateReducer from './telegram-state.reducer';
import chatStateReducer from './chat-state.reducer';
import pageNavigationStateReducer from './page-navigation-state.reducer';
import searchRecentStackReducer from './search-recent-stack.reducer';
import syncStateReducer from './sync-state.reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    onboarding: onboardingReducer,
    // signalR: signalRReducer,
    workspace: workspaceReducer,
    avatars: avatarsReducer,
    loginFlow: loginFlowReducer,
    telegramState: telegramStateReducer,
    chatState: chatStateReducer,
    pageNavigationState: pageNavigationStateReducer,
    searchRecentStack: searchRecentStackReducer,
    syncState: syncStateReducer,
});

export default rootReducer;
