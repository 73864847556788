import { atom } from 'jotai';

type CreateCardModalState = {
    isOpen: boolean;
    statusId?: number;
};

type MergeCardModalState = {
    isOpen: boolean;
    sourceCardId?: number | null;
    targetCardId?: number | null;
};

type BatchSendingModalState = {
    isOpen: boolean;
    initialStep?: number;
};

type ConfModeModalState = {
    isOpen: boolean;
};

type SyncSettingsModalState = {
    isOpen: boolean;
};

type CreateWorkspaceModalState = {
    isOpen: boolean;
};

export const selectTeamChatModalOpenAtom = atom(false);
export const createCardModalAtom = atom<CreateCardModalState>({
    isOpen: false,
});
export const mergeCardModalAtom = atom<MergeCardModalState>({ isOpen: false });
export const batchSendingModalAtom = atom<BatchSendingModalState>({
    isOpen: false,
    initialStep: 0,
});
export const confModeModalAtom = atom<ConfModeModalState>({ isOpen: false });
export const syncSettingsModalAtom = atom<SyncSettingsModalState>({
    isOpen: false,
});
export const createWorkspaceModalAtom = atom<CreateWorkspaceModalState>({
    isOpen: false,
});
