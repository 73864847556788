import { colors } from '../theme/colors';

export const PipelineDefaultStages = [
    {
        name: 'Lead',
        color: colors.turquoise[20],
        textColor: colors.turquoise[60],
    },
    { name: 'Contacted', color: colors.sky[20], textColor: colors.sky[60] },
    {
        name: 'Negotiation',
        color: colors.purple[20],
        textColor: colors.purple[60],
    },
    {
        name: 'Ready to start',
        color: colors.pink[20],
        textColor: colors.pink[60],
    },
    { name: 'Closed', color: colors.orange[20], textColor: colors.orange[60] },
    { name: 'Stage 6', color: '#FFE3D3', textColor: '#743b1b' },
    { name: 'Stage 7', color: '#FFF6D7', textColor: '#746224' },
    { name: 'Stage 8', color: '#DDFCD8' },
    { name: 'Stage 9', color: '#D1DFFB' },
    { name: 'Stage 10', color: '#E9DFFF' },
];
