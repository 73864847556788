import { Outlet } from 'react-router-dom';
import {
    useGetWorkspaceCardsStatuses,
    useGetWorkspaceMembers,
} from '../../queries';
import { useSubscribeToEvents } from './hooks';
import { useGetWorkspaceId } from '../../hooks';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useWorkspaceCardTitles } from '../../hooks/useWorkspaceCardTitles';
import { useWorkspaceCardInfo } from '../../hooks/useWorkspaceCardInfo';

export const MainLayout = () => {
    const workspaceId = useGetWorkspaceId();

    useGetBoardCards();
    useGetWorkspaceCardsStatuses(!!workspaceId);
    useGetWorkspaceMembers();
    useSubscribeToEvents();
    useWorkspaceCardTitles(workspaceId);
    useWorkspaceCardInfo(workspaceId);

    return <Outlet />;
};
