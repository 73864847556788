import React from 'react';
import { useAtom } from 'jotai';
import { ModalWrapper } from '../../components';
import { ModalBody } from './components';
import { selectTeamChatModalOpenAtom } from '../../atoms/modals.atoms';

export const SelectTeamChatModal: React.FC = () => {
    const [isOpen, setIsOpen] = useAtom(selectTeamChatModalOpenAtom);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <ModalWrapper isOpen={isOpen} onClose={onClose}>
            <ModalBody onClose={onClose} />
        </ModalWrapper>
    );
};
