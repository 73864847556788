import React from 'react';
import { MenuItem, Text } from '@chakra-ui/react';
import { Icon } from '../../../Icon/Icon';
import { colors } from '../../../../theme/colors';
import { useSetAtom } from 'jotai';
import { createWorkspaceModalAtom } from '../../../../atoms/modals.atoms';

export const AddWorkspaceButton: React.FC = () => {
    const setCreateWorkspaceModal = useSetAtom(createWorkspaceModalAtom);

    const handleClick = () => {
        setCreateWorkspaceModal({ isOpen: true });
    };

    return (
        <MenuItem
            height="40px"
            onClick={handleClick}
            px="8px"
            gap="8px"
            _hover={{ bg: 'transparentLight.10' }}
            _focus={{ bg: 'transparentLight.10' }}
        >
            <Icon
                name="plus"
                height="16px"
                width="16px"
                color={colors.secondary}
            />
            <Text color="secondary" fontSize="13px" fontWeight={400}>
                Add workspace
            </Text>
        </MenuItem>
    );
};
