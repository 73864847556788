import React from 'react';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    useSteps,
    Box,
} from '@chakra-ui/react';

import { useModals } from '@router';
import { useAtom } from 'jotai';
import { confModeModalAtom } from '../../atoms/modals.atoms';

import Onboarding from './toggle-telegram';
// При желании можно подключить ваш CSS-модуль для общей стилизации обёртки
import css from './index.module.css';
import { ConferenceWorkspace } from './onboarding';
// Список шагов. Можно добавить больше при необходимости.

const steps = [
    { key: 'test', Content: ConferenceWorkspace },
    { key: 'onboarding', Content: Onboarding },
    // { key: 'create-conf-workspace', Content: CreateConferenceWorkspace },
];

interface ConfModeProps {
    isModal?: boolean;
}

function ConfMode({ isModal = false }: ConfModeProps) {
    const modals = useModals();
    const [, setConfModeModal] = useAtom(confModeModalAtom);

    // Подключение механизма шагов Chakra
    const { activeStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: steps.length,
    });

    // Текущий шаг
    const currentStep = steps[activeStep];
    const CurrentStepComponent = currentStep.Content;

    // Закрытие модального окна
    const handleCancel = () => {
        if (isModal) {
            setConfModeModal({ isOpen: false });
        } else {
            modals.close();
        }
    };

    const content = (
        <CurrentStepComponent
            key={currentStep.key}
            goToNext={goToNext}
            goToPrevious={goToPrevious}
            cancel={handleCancel}
        />
    );

    // If used as a modal component, don't render the Modal wrapper
    if (isModal) {
        return <Box className={css.body}>{content}</Box>;
    }

    return (
        <Modal isOpen={true} isCentered onClose={handleCancel}>
            <ModalOverlay />
            {/* Пример обёртки c вашими классами */}
            <ModalContent className={css.content}>
                <ModalBody className={css.body}>{content}</ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default ConfMode;
