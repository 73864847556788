import { useAtom } from 'jotai';
import { ModalWrapper } from '../../components';
import { confModeModalAtom } from '../../atoms/modals.atoms';
import ConferenceMode from '@features/ConfMode';

export const ConfModeModal = () => {
    const [confModeModal, setConfModeModal] = useAtom(confModeModalAtom);

    return (
        <ModalWrapper
            isOpen={confModeModal.isOpen}
            onClose={() => setConfModeModal({ isOpen: false })}
        >
            <ConferenceMode isModal={true} />
        </ModalWrapper>
    );
};
