import { CardActivityItem } from '../../../types';
import { trpc } from '@core/api/trpc';

const PAGE_SIZE = 40;

export const useGetCardActivitiesList = (
    workspaceId?: number | null,
    cardId?: number,
    offset?: number,
) => {
    return trpc.activities.getCardActivitiesList.useQuery(
        {
            workspaceId: workspaceId ?? undefined,
            cardId,
			offset,
			pageParam: PAGE_SIZE,
        },
        {
            enabled: !!workspaceId && !!cardId,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    );
};
