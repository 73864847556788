import React, { useEffect } from 'react';
import { Button, Text, Center, VStack } from '@chakra-ui/react';
import { IconPlugConnectedX } from '@tabler/icons-react';

interface IErrorPlaceholderProps {
    onReload: VoidFunction;
}

export const ErrorPlaceholder: React.FC<IErrorPlaceholderProps> = ({
    onReload,
}) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onReload();
        }, 30000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [onReload]);

    return (
        <Center
            h="100%"
            bg="white"
            borderRadius="8px"
            border="2px solid #ebebeb"
        >
            <VStack spacing={3}>
                <IconPlugConnectedX size={48} color="gray" stroke={1} />
                <Text textAlign="center" color="gray.700">
                    Seems like there are problems with Telegram loading
                </Text>
                <Button variant="outline" onClick={onReload} mt={2} w="40%">
                    Reload
                </Button>
            </VStack>
        </Center>
    );
};
