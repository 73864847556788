import { useState, useEffect } from 'react';
import { trpc } from '@core/api/trpc';

const urlCache = new Map<string, { url: string; expiry: number }>();

export const useSignedUrl = (storagePath: string | null) => {
    const [url, setUrl] = useState<string | null>(null);
    const utils = trpc.useUtils();

    useEffect(() => {
        if (!storagePath) {
            setUrl(null);
            return;
        }

        // Check cache first
        const cached = urlCache.get(storagePath);
        if (cached && cached.expiry > Date.now()) {
            setUrl(cached.url);
            return;
        }

        async function fetchUrl() {
            try {
                const result = await utils.storage.createSignedUrl.fetch({
                    path: storagePath || '',
                });
                if (result?.signedUrl) {
                    // Cache for 45 minutes
                    urlCache.set(storagePath || '', {
                        url: result.signedUrl,
                        expiry: Date.now() + 45 * 60 * 1000,
                    });
                    setUrl(result.signedUrl);
                }
            } catch (error) {
                console.error('Failed to get signed URL:', error);
                setUrl(null);
            }
        }

        fetchUrl();
        const interval = setInterval(fetchUrl, 45 * 60 * 1000);
        return () => clearInterval(interval);
    }, [storagePath, utils]);

    return url;
};
