import { Box, Button, Text } from "@chakra-ui/react";
import { InputText } from "@components/index";
import { useCreateConference } from "../hooks/useCreateConference";

interface CreateConferenceProps {
  conferenceName: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CreateConference = ({ conferenceName, onNameChange }: CreateConferenceProps) => {
  const { handleCreateWorkspace, isLoading } = useCreateConference();

  return (
    <Box width="200px">
      <Text color={"#B3B3B3"} fontSize={"13px"} fontWeight={"600"} mb={"0px"}>
        CONFERENCE NAME
      </Text>
      <InputText 
        placeholder={'AI Action Summit Feb 2025'} 
        width="100%"
        value={conferenceName}
        onChange={onNameChange}
      />
      <Button 
        onClick={() => handleCreateWorkspace(conferenceName)}
        isDisabled={!conferenceName.trim() || isLoading}
        mt={4}
        width="100%"
      >
        Create and Start
      </Button>
    </Box>
  );
}; 