import { atom } from 'jotai';
import { BoardCardItemResponse } from '../features/Dashboard/types';

export type TasksGroupedByStatus = {
    [key: string]: BoardCardItemResponse[];
};

export const tasksAtom = atom<TasksGroupedByStatus>({});

// Track whether the dashboard is sorted in reverse order
export const isSortedReverseAtom = atom<boolean>(false);

type MoveTaskParams = {
    tasks: TasksGroupedByStatus;
    sourceStatusId: string;
    destinationStatusId: string;
    sourceIndex: number;
    destinationIndex: number;
};

export const moveTask = ({
    tasks,
    sourceStatusId,
    sourceIndex,
    destinationStatusId,
    destinationIndex,
}: MoveTaskParams): TasksGroupedByStatus => {
    if (sourceStatusId === destinationStatusId) {
        return tasks;
    }

    const sourceTasks = [...tasks[sourceStatusId]];
    const destinationTasks = [...tasks[destinationStatusId]];
    const [movedTask] = sourceTasks.splice(sourceIndex, 1);

    destinationTasks.splice(destinationIndex, 0, movedTask);

    return {
        ...tasks,
        [sourceStatusId]: sourceTasks,
        [destinationStatusId]: destinationTasks,
    };
};

export const moveTaskToEnd = ({
    tasks,
    sourceStatusId,
    sourceIndex,
    destinationStatusId,
}: Omit<MoveTaskParams, 'destinationIndex'>): TasksGroupedByStatus => {
    const destinationTasks = [...tasks[destinationStatusId]];
    const destinationIndex = destinationTasks.length;

    return moveTask({
        tasks,
        sourceStatusId,
        sourceIndex,
        destinationStatusId,
        destinationIndex,
    });
};
