/* eslint-disable indent */

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    ApiResponseEmpty,
    LabelItemType,
    PayloadError,
} from '../../../../../types';
import { Alerter } from '../../../../../utils';
import { QueryKeys } from '../../../../../constants';
import { trpc } from '../../../../../core/api/trpc';

export interface UpdateLabelPayload {
    workspaceId: number;
    id: number; // This is the ID of the workspace_labels table entry
    name: string;
    colourId: number;
}

export const useUpdateLabel = () => {
    const utils = trpc.useUtils();

    const updateLabelMutation = trpc.labels.updateWorkspaceLabel.useMutation({
        onMutate: async (variables) => {
            await utils.workspace.getWorkspaceLabels.cancel();
            const previousLabels =
                await utils.workspace.getWorkspaceLabels.getData();
            utils.workspace.getWorkspaceLabels.setData(
                undefined,
                previousLabels?.map((label) => {
                    if (label.id === variables.labelId) {
                        return {
                            colourId: variables.colourId,
                            name: variables.name ?? '',
                            id: variables.labelId,
                            workspaceCardId: null,
                        };
                    }
                    return {
                        colourId: label.colourId,
                        name: label.name ?? '',
                        id: label.id,
                        workspaceCardId: label.workspaceCardId,
                    };
                }),
            );
            return { previousLabels };
        },
        onSuccess: () => {
            Alerter.success('Label was updated successfully!');
        },
        onSettled: () => {
            utils.workspace.getWorkspaceLabels.invalidate();
        },
    });

    return updateLabelMutation;

    // return useMutation<ApiResponseEmpty, unknown, UpdateLabelPayload>({
    //     mutationKey: [QueryKeys.UPDATE_LABEL],
    //     mutationFn: async (payload) => {
    //         const { workspaceId, id, colourId, name } = payload;

    //         try {
    //             // Note: The backend expects 'labelId' but we're actually sending the workspaceLabelId
    //             // This is because the backend is looking for workspace_labels.id, not workspace_labels.labelId
    //             const result = await updateLabelMutation.mutateAsync({
    //                 workspaceId,
    //                 labelId: id, // This is actually the workspace_labels.id
    //                 colourId,
    //                 name, // Pass the name to the backend
    //             });

    //             // Format the response to match the legacy API format
    //             return {
    //                 success: true,
    //                 status: 200,
    //                 errors: [],
    //                 messages: [],
    //                 value: result,
    //             };
    //         } catch (error: any) {
    //             console.error('Error updating label:', error);

    //             // Format error response to match legacy API format
    //             const payloadError: PayloadError = {
    //                 key: 'label',
    //                 message: error.message || 'Failed to update label',
    //             };

    //             return {
    //                 success: false,
    //                 status: error.data?.httpStatus || 500,
    //                 errors: [payloadError],
    //                 messages: [],
    //                 value: null,
    //             };
    //         }
    //     },
    //     // Optimistically update the cache before the server responds
    //     onMutate: async (variables) => {
    //         // Cancel any outgoing refetches to avoid overwriting our optimistic update
    //         await utils.workspace.getWorkspaceLabels.cancel();

    //         // Snapshot the previous value
    //         const previousLabels =
    //             await utils.workspace.getWorkspaceLabels.getData();

    //         // Optimistically update to the new value
    //         if (previousLabels) {
    //             utils.workspace.getWorkspaceLabels.setData(
    //                 undefined,
    //                 previousLabels.map((label) => {
    //                     if (label.id === variables.id) {
    //                         return {
    //                             ...label,
    //                             name: variables.name,
    //                             colourId: variables.colourId,
    //                         };
    //                     }
    //                     return label;
    //                 }),
    //             );
    //         }

    //         // Return the previous labels so we can roll back if something goes wrong
    //         return { previousLabels };
    //     },
    //     // If the mutation fails, use the context returned from onMutate to roll back
    //     onError: (_err, variables, context) => {
    //         if ((context as any)?.previousLabels) {
    //             queryClient.setQueryData(
    //                 [QueryKeys.GET_WORKSPACE_LABELS, variables.workspaceId],
    //                 (context as any).previousLabels,
    //             );
    //         }
    //         Alerter.error('Failed to update label. Please try again.');
    //     },
    //     onSuccess: () => {
    //         // Use TRPC utils to invalidate the query
    //         utils.workspace.getWorkspaceLabels.invalidate();

    //         // Show appropriate success message
    //         if (isAfterCreate) {
    //             Alerter.success('Label color successfully updated!');
    //         } else {
    //             Alerter.success(`Label was successfully updated!`);
    //         }
    //     },
    // });
};
