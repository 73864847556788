import { Button, Link, Text } from '@chakra-ui/react';
import { Icon } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { BOT_USERNAME } from '../../tg-config';

import css from './index.module.css';

import { useGetOtp } from '../../queries/auth';
import { sleep } from '../../utils';
import { setAuthStatus } from '../../redux/reducers/auth.reducer';
import { useState } from 'react';
import { useTelegramLock } from '../../services/telegram/lockContext';
import { setShowTelegramModal } from '../../redux/reducers/login-flow.reducer';
import { trpc } from '@core/api/trpc';
import { DID } from '../../queries/auth/did';

export const Loader = () => 'Route loader';

export const Action = () => 'Route action';

export const Catch = () => <div>Something went wrong...</div>;

export const Pending = () => <div>Loading...</div>;

export default function LoginPage() {
    const dispatch = useDispatch();

    const lock = useTelegramLock();

    const getOtpMutation = useGetOtp();
    const refreshTokenMutation = trpc.auth.refreshToken.useMutation();
    const getJwtMutation = trpc.auth.jwt.useMutation();
    const { telegramConnected, userId } = useSelector(
        (state: RootStore) => state.loginFlow,
    );

    const [isCrmLoginLoading, setIsCrmLoginLoading] = useState(false);

    const handleConnectTelegram = () => {
        dispatch(setShowTelegramModal(true));
    };

    const handleCrmLogin = async () => {
        setIsCrmLoginLoading(true);
        const MAX_RETRIES = 5;
        const BASE_DELAY = 3000;

        let otpCode = '';

        for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
            try {
                if (!userId) return;

                const telegramUserId = Number(userId);

                if (attempt === 0) {
                    const { otpCode: newOtpCode } =
                        await getOtpMutation.mutateAsync({
                            telegramUserId,
                        });
                    otpCode = newOtpCode;
                    console.log('otpCode', otpCode);
                    await lock.scheduleBackgroundEvent(async (tg) =>
                        tg?.actions.proxy.openChatByUsername({
                            username: BOT_USERNAME,
                            startParam: otpCode,
                        }),
                    );
                    // Initial delay to allow user to interact with Telegram
                    await sleep(10000);
                }

                // Get user data from Telegram
                const userData = await lock.scheduleBackgroundEvent(
                    async (tg) => {
                        if (!tg) return null;
                        const user =
                            await tg.custom.proxy.getUserById(telegramUserId);
                        if (!user?.userShortInfo) return null;
                        return {
                            firstName: user.userShortInfo.firstName || '',
                            lastName: user.userShortInfo.lastName || '',
                            username:
                                user.userShortInfo.usernames?.[0]?.username ||
                                '',
                        };
                    },
                );

                const data = await refreshTokenMutation.mutateAsync(
                    {
                        tgid: telegramUserId,
                        otp: otpCode, // Use the same OTP for all retries
                        did: DID,
                        firstName: userData?.firstName || '',
                        lastName: userData?.lastName || '',
                        username: userData?.username || '',
                    } as any, // Type assertion needed since the mutation type doesn't include these fields
                    {
                        onSuccess: (data, variables, context) => {
                            console.log('Mutation succeeded');
                        },
                        onSettled: (data, error, variables, context) => {
                            console.log('Full response data:', data);
                            if (data && 'headers' in data) {
                                console.log('Response headers:', data.headers);
                            }
                        },
                    },
                );

                // If we got a null token, throw an error to trigger retry
                if (!data?.token) {
                    throw new Error('Waiting for Telegram confirmation...');
                }

                const { jwt, expiry } = await getJwtMutation.mutateAsync({
                    tgid: telegramUserId,
                    refreshToken: data.refreshToken || data.token, // Use the explicit refreshToken if available
                });
                if (!jwt || !expiry) {
                    throw new Error('JWT or expiry is undefined');
                }

                console.log('data', data);
                dispatch(
                    setAuthStatus({
                        token: {
                            accessToken: jwt,
                            accessTokenExpiration: expiry.toString(),
                            refreshToken: data.token,
                        },
                        user: {
                            ...data.user,
                            avatarThumbnailLink: null,
                            avatarUrl: null,
                            teamChat: null,
                            workSpace:
                                data.workspace &&
                                data.workspace.workspacesTable.name
                                    ? {
                                          ...data.workspace,
                                          name: data.workspace.workspacesTable
                                              .name,
                                          avatarUrl:
                                              data.workspace.workspacesTable.avatarId?.toString() ||
                                              null,
                                          avatarThumbnailUrl:
                                              data.workspace.workspacesTable.thumbnailId?.toString() ||
                                              null,
                                          teamChat: null,
                                          isArchiveVisible:
                                              data.workspace.workspacesTable
                                                  .isArchiveVisible,
                                          isAdmin: false,
                                      }
                                    : null,
                        },
                    }),
                );
                return; // Success - exit the retry loop
            } catch (error) {
                console.error(`Login attempt ${attempt + 1} failed:`, error);

                if (attempt === MAX_RETRIES - 1) {
                    // Last attempt failed
                    console.error('Max retry attempts reached. Login failed.');
                    break;
                }

                // Exponential backoff with jitter
                const delay =
                    BASE_DELAY * Math.pow(2, attempt) + Math.random() * 1000;
                await sleep(delay);
            }
        }

        setIsCrmLoginLoading(false);
    };

    return (
        <section className={css.layout}>
            {/* <button onClick={() => useLogout()}>Logout</button> */}
            <div className={css.content}>
                <Icon
                    name={'logo'}
                    width={'10rem'}
                    height={'2.5rem'}
                    strokeColor={'none'}
                />

                <h2 className={css.title}>Log in</h2>

                <div className={css.controls}>
                    <Text
                        color="primary"
                        fontSize="12px"
                        lineHeight={'18px'}
                        fontWeight={400}
                    >
                        Step 1:
                    </Text>

                    {!telegramConnected ? (
                        <Button
                            isDisabled={telegramConnected}
                            onClick={handleConnectTelegram}
                        >
                            Connect Telegram
                        </Button>
                    ) : (
                        <Button isDisabled={true}>Telegram Connected</Button>
                    )}

                    <Text
                        color="primary"
                        fontSize="12px"
                        lineHeight={'18px'}
                        fontWeight={400}
                    >
                        Step 2:
                    </Text>

                    <Button
                        isDisabled={!telegramConnected}
                        isLoading={isCrmLoginLoading}
                        onClick={handleCrmLogin}
                    >
                        Log in to CRM
                    </Button>
                </div>

                <Text
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'center'}
                    color={'tertiary'}
                >
                    By using Dise, you agree to the{' '}
                    <Link
                        href="https://dise.app/terms"
                        isExternal
                        fontWeight={500}
                    >
                        Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                        href="https://dise.app/privacy"
                        isExternal
                        fontWeight={500}
                    >
                        Data Processing Agreement
                    </Link>
                    .
                </Text>
            </div>
        </section>
    );
}
