import React, {
    useEffect,
    useMemo,
    useState,
    useRef,
    useCallback,
} from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { Loader } from '../../components';
import { getBoardTabs } from './constants';
import { BoardTab, LabelsSelectPopup } from './components';
import DashboardPanelTemplateTabs from './DashboardPanelTemplate.tabs';
import { useGetBoardCards } from './queries';
import { RootStore } from '../../redux/createStore';
import { useGetWorkspaceId } from '../../hooks';
import { BoardFilterValue } from './types';
import { ApiResponse } from '../../types';
import { QueryKeys } from '../../constants';
import {
    getUnreadCards,
    getUnansweredCards,
    getMentionedCards,
    getActiveTasksCards,
    getTeamActivityCards,
    Alerter,
} from '../../utils';
import { DefaultPageHeader } from '../../widgets';
import { useChats } from '../../hooks/useChats';
import { IconFilter } from '@tabler/icons-react';
import { useCardFilters } from './components/FilterSelect/useCardFilters';
import { LastActivitySelect } from './components/LastActivitySelect';
import { ChatOwnerSelect } from './components/ChatOwnerSelect';
import { useAutoSync } from '../../hooks/useAutoSync';
import { trpc } from '@core/api/trpc';
import { useAutoSyncConfMode } from '../../hooks/useAutoSyncConfMode';
import { useWorkspaceCardTitles } from '../../hooks/useWorkspaceCardTitles';
import { useWorkspaceCardInfo } from '../../hooks/useWorkspaceCardInfo';
import { useSearchParams } from 'react-router-dom';

const DashboardPage = () => {
    // useAiChats();
    useAutoSync();
    const queryClient = useQueryClient();
    const [isSyncing, setIsSyncing] = useState(false);
    const { startSync } = useAutoSyncConfMode();
    const hasSynced = useRef(false);

    const workspaceId = useGetWorkspaceId();
    useWorkspaceCardTitles(workspaceId);
    useWorkspaceCardInfo(workspaceId);
    const { data: isConferenceStarted } =
        trpc.conference.isWorkspaceConference.useQuery({
            workspaceId: workspaceId,
        });

    useEffect(() => {
        const syncConference = async () => {
            if (isConferenceStarted && !isSyncing && !hasSynced.current) {
                setIsSyncing(true);
                try {
                    await startSync();
                    hasSynced.current = true;
                    // Alerter.success("Conference sync completed");
                } catch (error) {
                    console.error('Conference sync failed:', error);
                    Alerter.error('Conference sync failed');
                } finally {
                    setIsSyncing(false);
                }
            }
        };

        syncConference();
    }, [isConferenceStarted]);

    const isArchiveVisible = useSelector(
        (state: RootStore) => state.auth.user.workSpace?.isArchiveVisible,
    );

    const chats = useChats();

    // Remove console log to improve performance
    // console.log(chats, 'chatsdata');

    const filters = useCardFilters();
    const [filtersVisible, setFiltersVisible] = useState(() =>
        filters.hasActiveFilters(),
    );

    // Ensure filter panel is visible when there are active filters
    useEffect(() => {
        if (filters.hasActiveFilters() && !filtersVisible) {
            setFiltersVisible(true);
        }
    }, [filters.hasActiveFilters(), filtersVisible]);

    const { data, isLoading } = useGetBoardCards();

    // Remove console log to improve performance
    // console.log(data, 'data');

    const teamTelegramIds = queryClient.getQueryData<
        ApiResponse<{ telegramUserIds: number[] }>
    >([QueryKeys.GET_WORKSPACE_TELEGRAM_IDS, workspaceId]);

    const [searchParams, setSearchParams] = useSearchParams();

    // Initialize all filter types from URL params
    const [tabIndex, setTabIndex] = useState(() => {
        const filter = searchParams.get('filter');
        return filter
            ? (BoardFilterValue[filter as keyof typeof BoardFilterValue] ??
                  BoardFilterValue.All)
            : BoardFilterValue.All;
    });

    // Update URL with all filter types
    const handleTabChange = (index: number) => {
        const filterValue = BoardFilterValue[index as BoardFilterValue];
        const newParams = new URLSearchParams(searchParams);
        newParams.set('filter', filterValue);
        setSearchParams(newParams);
        setTabIndex(index);
    };

    // Modify FiltersPanel to preserve its state in URL
    const handleFilterChange = (
        filterType: string,
        value: string | string[] | null,
    ) => {
        // Remove console log to improve performance
        // console.log('FILTERS CHANGE', value);

        const newParams = new URLSearchParams(searchParams);

        if (value === null || (Array.isArray(value) && value.length === 0)) {
            newParams.delete(filterType);
        } else {
            newParams.set(
                filterType,
                Array.isArray(value) ? value.join(',') : value,
            );
        }

        setSearchParams(newParams);
    };

    // Memoize filtered cards to avoid recalculation on every render
    const filteredCards = useMemo(() => {
        return (data || []).filter((card) => filters.applyFilters(card, chats));
    }, [data, filters, chats]);

    // Memoize card counts to prevent recalculation on every render
    const cardCounts = useMemo(() => {
        const teamIds = teamTelegramIds?.value?.telegramUserIds || [];

        return {
            unread: getUnreadCards({ cards: filteredCards, chats }).length || 0,
            unanswered:
                getUnansweredCards({
                    cards: filteredCards,
                    chats,
                    teamTelegramIds: teamIds,
                }).length || 0,
            activeTasks:
                getActiveTasksCards({ cards: filteredCards }).length || 0,
            teamActivity:
                getTeamActivityCards({ cards: filteredCards }).length || 0,
            mentions: getMentionedCards({ cards: filteredCards }).length || 0,
        };
    }, [filteredCards, chats, teamTelegramIds?.value?.telegramUserIds]);

    // Memoize tabs separately from their rendering
    const tabs = useMemo(() => {
        return getBoardTabs(cardCounts);
    }, [cardCounts]);

    // Memoize tab components to avoid unnecessary re-rendering
    const renderTabs = useMemo(() => {
        return tabs.map((tab, index) => (
            <BoardTab
                key={tab.id}
                isActive={tab.filterValue === tabIndex}
                onClick={() => handleTabChange(index)}
                {...tab}
            />
        ));
    }, [tabs, tabIndex]);

    return (
        <Flex
            as="section"
            id="dashboard-layout"
            w="100%"
            h="100%"
            flexDir="column"
            pr={'0.5rem'}
        >
            <DefaultPageHeader
                title="Main Pipeline"
                iconName="users"
                isSyncSettingsEnabled={true}
                isInviteMembersEnabled={true}
            />

            {isLoading ? (
                <Loader centerHeight="100%" />
            ) : (
                <Flex
                    as="section"
                    flexDir="column"
                    borderWidth="1px"
                    borderColor="gray.20"
                    borderRadius="8px"
                    overflow="hidden"
                    bg="white"
                    h="100%"
                >
                    <Flex
                        as="header"
                        flexShrink={0}
                        columnGap={4}
                        p={2}
                        overflowX="auto"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                        alignItems={'center'}
                    >
                        {renderTabs}

                        <Flex flexGrow={1} justifyContent={'flex-end'}>
                            <OpenFiltersButton
                                opened={filtersVisible}
                                onClick={() => setFiltersVisible((p) => !p)}
                            />
                        </Flex>
                    </Flex>
                    {filtersVisible && (
                        <FiltersPanel
                            onFilterChange={handleFilterChange}
                            initialFilters={{
                                labels:
                                    searchParams
                                        .get('labels')
                                        ?.split(',')
                                        .filter(Boolean) || [],
                                lastActivity:
                                    searchParams.get('lastActivity') || '',
                                owner: searchParams.get('owner') || '',
                            }}
                        />
                    )}

                    <DashboardPanelTemplateTabs
                        tabIndex={tabIndex}
                        data={filteredCards}
                        workspaceId={workspaceId}
                        chats={chats}
                        isArchiveVisible={isArchiveVisible}
                    />

                    {/* {isSyncing && <Spinner size="sm" />} */}
                </Flex>
            )}
        </Flex>
    );
};

export default DashboardPage;

type OpenFiltersButton = {
    opened: boolean;
    onClick: () => void;
};
const OpenFiltersButton = (props: OpenFiltersButton) => {
    return (
        <HStack
            borderRadius="4px"
            gap={'4px'}
            p={'4px'}
            px={'8px'}
            w={'max-content'}
            onClick={props.onClick}
            cursor="pointer"
            spacing={2}
            bg={props.opened ? 'gray.20' : 'transparent'}
            _hover={{
                bg: 'gray.20',
            }}
            transition="0.2s background ease"
        >
            <IconFilter color="#666" size={'14px'} />
            <Text color={'gray.50'} fontSize={'13px'} fontWeight={400}>
                Filter
            </Text>
        </HStack>
    );
};

// Memoize the FiltersPanel component to prevent unnecessary re-renders
const FiltersPanel = React.memo(
    ({
        onFilterChange,
        initialFilters,
    }: {
        onFilterChange: (
            filterType: string,
            value: string | string[] | null,
        ) => void;
        initialFilters: {
            labels: string[];
            lastActivity: string;
            owner: string;
        };
    }) => {
        // Log initial values for debugging
        useEffect(() => {
            console.log('Initial filter values:', initialFilters);
        }, [initialFilters]);

        return (
            <Flex
                p={'8px'}
                bg={'white'}
                gap={'16px'}
                borderBottomWidth="1px"
                borderBottomColor="gray.15"
            >
                <LabelsSelectPopup
                    initialValue={initialFilters.labels}
                    onChange={(values) => onFilterChange('labels', values)}
                    useNameAsValue={true}
                />
                <LastActivitySelect
                    initialValue={initialFilters.lastActivity}
                    onChange={(value) => {
                        // If value is empty string, pass null to remove the filter
                        if (value === '') {
                            onFilterChange('lastActivity', null);
                        } else {
                            // The value is already a JSON string
                            onFilterChange('lastActivity', value);
                        }
                    }}
                />
                <ChatOwnerSelect
                    initialValue={initialFilters.owner}
                    onChange={(value) => onFilterChange('owner', value)}
                />
            </Flex>
        );
    },
);
