import { useCallback, useMemo, useState } from 'react';
import { Box, Flex, Text, HStack, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, InputText, TeamMember, Loader } from '../../../components';
import {
    modalSubHeaderStyleProps,
    settingsRegularTextStyleProps,
} from '../../../modals/Onboarding/steps/helper';
import { useGetWorkspaceMembers } from '../../../queries';
import { ConfirmDeleteModal } from '../components';
import { useRemoveUserFromWorkspace } from '../queries';
import { useGetTeammateByUsername, useGetWorkspaceId } from '../../../hooks';
import { RootStore } from '../../../redux/createStore';
import { Alerter } from '../../../utils';
import { updateTelegramUserState } from '../../../redux/reducers/telegram-state.reducer';
import { setAvatar } from '../../../redux/reducers/avatars.reducer';
import { useInviteMembersToWorkspaceMutation } from '@core/api';

type HandleForm = {
    username: string;
};

const MembersSettingsPage = () => {
    const dispatch = useDispatch();

    const { data, isLoading } = useGetWorkspaceMembers();
    const [memberIdToDelete, setMemberIdToDelete] = useState(0);
    const removeUserFromWorkspaceMutation = useRemoveUserFromWorkspace();
    const queryClient = useQueryClient();
    const workspaceId = useGetWorkspaceId();
    const inviteMembersMutation = useInviteMembersToWorkspaceMutation(
        workspaceId!,
    );

    const storeAvatars = useSelector((state: RootStore) => state.avatars);
    const { id: currentUserId } = useSelector(
        (state: RootStore) => state.auth.user,
    );

    const updateUserData = useCallback(
        (id: number, username: string, avatarUri?: string) => {
            dispatch(
                updateTelegramUserState({
                    id,
                    data: {
                        username: username.replace('@', ''),
                    },
                }),
            );

            if (!avatarUri?.length || storeAvatars[`${id}`] === avatarUri) {
                return;
            }

            dispatch(setAvatar({ [id]: avatarUri }));
        },
        [storeAvatars, dispatch],
    );

    const { register, handleSubmit, reset } = useForm<HandleForm>();

    const teamTelegramIds = useMemo(
        () => data?.map((user) => user.telegramUserId) || [],
        [data?.length],
    );

    const { onAddHandle, isLoading: isUserFetchLoading } =
        useGetTeammateByUsername((value) => {
            if (teamTelegramIds.includes(+(value?.id || 0))) {
                Alerter.warning(
                    `User ${value.firstName}${value.lastName ? ` ${value.lastName}` : ''} is already a part of your workspace`,
                );
                reset({ username: '' });
                return;
            }

            if (!workspaceId) {
                Alerter.error('Workspace ID is undefined');
                return;
            }

            inviteMembersMutation.mutate([
                {
                    telegramUserId: +(value?.id || 0),
                    firstName: value.firstName || '',
                    lastName: value.lastName || undefined,
                },
            ]);
            reset({ username: '' });
        });

    const onDeleteMember = () => {
        removeUserFromWorkspaceMutation.mutate(
            { userId: memberIdToDelete },
            {
                onSettled() {
                    setMemberIdToDelete(0);
                },
            },
        );
    };

    const onAdd = (data: HandleForm) => {
        onAddHandle(data.username);
    };

    return (
        <Box as="section" id="settings-members-layout" h="100%" w="100%">
            <Box
                bg="white"
                h="100%"
                borderRadius="8px"
                border="1px solid"
                borderColor="gray.20"
                overflowY="auto"
                p="32px"
            >
                <Flex flexDirection="column" gap="32px" maxW="container.sm">
                    <Text color="tertiary" fontSize="16px" fontWeight="600">
                        Members
                    </Text>
                    <Box>
                        <Text {...modalSubHeaderStyleProps}>Invite</Text>
                        <Text
                            {...settingsRegularTextStyleProps}
                            mt="12px"
                            mb="16px"
                        >
                            Add users by their Telegram usernames
                        </Text>
                        <form onSubmit={handleSubmit(onAdd)}>
                            <HStack align="flex-end">
                                <InputText {...register('username')} />
                                <Button
                                    variant="outline"
                                    type="submit"
                                    borderRadius="4px"
                                    background="gray.10"
                                    color="secondary"
                                    leftIcon={
                                        <Icon
                                            name="plus"
                                            width="14px"
                                            height="14px"
                                        />
                                    }
                                    isLoading={
                                        inviteMembersMutation.isPending ||
                                        isUserFetchLoading
                                    }
                                >
                                    Add
                                </Button>
                            </HStack>
                        </form>
                    </Box>
                    <Box>
                        <Text {...modalSubHeaderStyleProps}>Members</Text>
                        <Text {...settingsRegularTextStyleProps} my="8px">
                            Manage the members of your workspace here
                        </Text>

                        <>
                            {isLoading ? (
                                <Loader spinnerSize="md" centerHeight="300px" />
                            ) : (
                                <>
                                    {data?.length ? (
                                        <Box
                                            borderRadius="4px"
                                            bg="gray.10"
                                            border="1px solid"
                                            maxH="500px"
                                            overflowY="auto"
                                            borderColor="gray.20"
                                        >
                                            {data?.map((elem) => (
                                                <TeamMember
                                                    id={elem.telegramUserId}
                                                    key={elem.id}
                                                    name={
                                                        elem.firstName +
                                                        ' ' +
                                                        (elem.lastName || '')
                                                    }
                                                    onDelete={() =>
                                                        setMemberIdToDelete(
                                                            elem.id,
                                                        )
                                                    }
                                                    includeDelete={
                                                        elem.id !==
                                                        currentUserId
                                                    }
                                                />
                                            ))}
                                        </Box>
                                    ) : null}
                                </>
                            )}
                        </>
                    </Box>
                </Flex>
            </Box>
            <ConfirmDeleteModal
                onClose={() => {
                    setMemberIdToDelete(0);
                }}
                isOpen={!!memberIdToDelete}
                onConfirm={onDeleteMember}
                confirmLoading={removeUserFromWorkspaceMutation.isPending}
                title="Delete member"
                description="This will permanently delete this user and remove him from all his assignments."
                confirmLabel="Delete account"
            />
        </Box>
    );
};

export default MembersSettingsPage;
