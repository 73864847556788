import { useEffect } from 'react';
import { TelegramWindowContextType, useTelegram } from '../services';
import { useChats } from './useChats';
import { ApiChat, ApiMessage } from '../../../telegram-tt/src/api/types';
import { trpc } from '@core/api/trpc';
import { useQuery } from '@tanstack/react-query';
import { allowedForSyncChatTypes } from '../constants/tg-chat-types';
export const useGetAllChats = async (tg: TelegramWindowContextType, targetDate: Date) => {
    if (!tg) return;

    const targetTimestamp = dateToUnixTimestamp(targetDate);
    const allChats: Awaited<ReturnType<typeof tg.methods.proxy.fetchChats>>[] = [];
    let currentOffsetDate: number | undefined;

    while (true) {
        const chatsResult = await tg.methods.proxy.fetchChats({
            limit: 95,
            offsetDate: currentOffsetDate,
        });

        if (!chatsResult) break;
        
        allChats.push(chatsResult);
        console.log('Fetched chunk:', chatsResult);

        currentOffsetDate = chatsResult.nextOffsetDate;

        if (!currentOffsetDate || currentOffsetDate < targetTimestamp) {
            break;
        }
    }

    return allChats;
};

export function dateToUnixTimestamp(date: Date): number {
    return Math.floor(date.getTime() / 1000);
}


export const useChatsHistory = () => {
    const tg = useTelegram();
    const chats = useChats();

    const historyQuery = useQuery({
        queryKey: ['chats-history'],
        queryFn: () => loadChatsHistory(tg, Object.values(chats.data.chats)),
        enabled: !!tg && !!chats.data.chats,
        select(data) {
            // store data as a key-value pair
            return data.reduce(
                (acc: Record<string, string>, item: any) => {
                    acc[item.chatId] = item.historyText;
                    return acc;
                },
                {} as Record<string, string>,
            );
        },
    });

    return historyQuery;
};

let historyPromise: Promise<any> | null = null;

const loadChatsHistory = async (
    tg?: TelegramWindowContextType,
    chats?: ApiChat[],
) => {
    if (!tg || !chats) return;

    if (historyPromise) return historyPromise;

    historyPromise = Promise.all(
        chats.map(async (chat) => {
            const messages = await tg.methods.proxy.fetchMessages({
                chat,
                limit: 50,
                threadId: -1,
            });

            let minDate = Infinity;
            const messageCount = messages?.messages.length || 0;

            const historyText = messages?.messages.reduce(
                (acc, message, idx) => {
                    const messageDate = message?.date || 0;
                    minDate = Math.min(minDate, messageDate);

                    const sender = messages?.users.find(
                        (user) => user?.id === message?.senderId,
                    );

                    const senderName =
                        sender?.firstName ??
                        sender?.usernames?.[0]?.username ??
                        messages.chats[idx]?.title;

                    const messageText = message?.content?.text?.text || '';
                    const formattedDate = messageDate
                        ? new Date(messageDate * 1000).toLocaleString()
                        : '';

                    return `${acc}
---
${formattedDate}: ${senderName}
${messageText}`;
                },
                '',
            );

            const minDateFormatted = minDate !== Infinity 
                ? new Date(minDate * 1000).toLocaleString()
                : 'No messages';

            return {
                chatId: chat.id,
                historyText: `Total messages: ${messageCount}
Oldest message date: ${minDateFormatted}
${historyText}`,
            };
        }),
    ).finally(() => {
        historyPromise = null;
    });

    console.log('AI: MESSAGES', historyPromise);

    return historyPromise;
};

interface ChatHistoryResult {
    chatId: string;
    historyText: string;
}

export const getAllChatsHistory = async (
    tg: TelegramWindowContextType, 
    allChats: Awaited<ReturnType<typeof tg.methods.proxy.fetchChats>>[],
    targetDate: Date
): Promise<ChatHistoryResult[]> => {
    if (!tg || !allChats.length) return [];

    const targetTimestamp = dateToUnixTimestamp(targetDate);

    // Extract unique chats from all results and filter by allowed types
    const uniqueChats = allChats.reduce<ApiChat[]>((acc, result) => {
        if (!result?.chats) return acc;
        
        const newChats = result.chats.filter(
            (chat) => (
                !acc.some((existingChat) => existingChat.id === chat.id) && 
                allowedForSyncChatTypes.includes(chat.type)
            )
        );
        
        return [...acc, ...newChats];
    }, []);

    // Get history for each chat and filter based on conditions
    const histories = await Promise.all(
        uniqueChats.map(async (chat) => {
            const messages = await tg.methods.proxy.fetchMessages({
                chat,
                limit: 50,
                threadId: -1,
            });

            if (!messages?.messages.length) return null;

            // Find earliest message date
            const earliestDate = messages.messages.reduce((minDate, msg) => {
                return Math.min(minDate, msg?.date || Infinity);
            }, Infinity);

            // Check conditions:
            // 1. Earliest message is after target date
            // 2. Total messages less than 20
            if (earliestDate < targetTimestamp || messages.messages.length >= 20) {
                return null;
            }

            const historyText = messages.messages.reduce((acc, message, idx) => {
                const messageDate = message?.date || 0;
                const sender = messages.users.find(
                    (user) => user?.id === message?.senderId,
                );

                const senderName =
                    sender?.firstName ??
                    sender?.usernames?.[0]?.username ??
                    messages.chats[idx]?.title;

                const messageText = message?.content?.text?.text || '';
                const formattedDate = messageDate
                    ? new Date(messageDate * 1000).toLocaleString()
                    : '';

                return `${acc}
---
${formattedDate}: ${senderName}
${messageText}`;
            }, '');

            return {
                chatId: chat.id,
                historyText,
                messageCount: messages.messages.length,
                earliestDate
            };
        })
    );

    return histories.filter(Boolean) as ChatHistoryResult[];
};