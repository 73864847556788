import { useQuery } from '@tanstack/react-query';
import { useGetBoardCards } from '../features/Dashboard/queries';
import { useParams } from 'react-router-dom';
import { TelegramWindowContextType, useTelegram } from '../services';
import { useCallback, useEffect, useMemo } from 'react';
import {
    ApiChat,
    ApiChatFullInfo,
    ApiMessage,
    ApiUser,
    ApiUserFullInfo,
} from '../../../telegram-tt/src/api/types';
import { QueryKeys } from '../constants';
import { atom, useAtom } from 'jotai';
import { type NormalizedChatData } from '../../../telegram-tt/src/api/gramjs/methods/chats';
import { Maybe } from '../types';
import { RootStore } from '../redux/createStore';
import { useSelector } from 'react-redux';
import { trpc } from '@core/api/trpc';

function _getChatInfo(
    chatId: string,
    normalizedData: Maybe<NormalizedChatData>,
) {
    if (!normalizedData) return null;
    const { chats, messages, users, lastMessages } = normalizedData;

    if (!lastMessages || !messages || !users || !chats) return null;

    const lastMessageId = lastMessages[chatId];
    const lastMessage = lastMessageId ? messages[lastMessageId] : undefined;
    const lastMessageSender = lastMessage?.senderId
        ? users[lastMessage.senderId]
        : undefined;

    return chats[chatId]
        ? {
              ...chats[chatId],
              lastMessage: {
                  ...lastMessage,
                  sender: lastMessageSender,
              },
          }
        : null;
}

type IndividualChat = {
    chat: ApiChat | undefined;
    id: number;
    chatFullInfo: ApiChatFullInfo | undefined;
    msg: ApiMessage | undefined;
    lastMessageUserInfo: ApiUser | undefined;
    userFullInfo: ApiUserFullInfo | undefined;
};

type WorkspaceChat = {
    chatId: string;
    chatTitle: string;
};
let requestPromise: Promise<(IndividualChat | null)[] | null> | undefined;

export const resetRequestPromise = () => {
    requestPromise = undefined;
};

const getIndividualChats = async ({
    tg,
    workspaceChatIds,
}: {
    tg?: TelegramWindowContextType;
    workspaceChatIds: WorkspaceChat[];
}) => {
    if (!tg) {
        console.warn('getIndividualChats: No Telegram connection available');
        return null;
    }

    if (workspaceChatIds.length === 0) {
        console.warn('getIndividualChats: No chat IDs provided');
        return null;
    }

    // Return existing promise if one is in flight
    if (requestPromise) {
        return requestPromise;
    }

    try {
        requestPromise = (async () => {
            const chatPromises = workspaceChatIds.map(({ chatId, chatTitle }) =>
                fetchChatWithTimeout({ tg, chatId, chatTitle }),
            );

            const chat = await Promise.all(chatPromises);

            if (chat.every((c) => !c)) {
                console.warn(
                    'All chats failed to fetch, returning empty array',
                );
                return [];
            }

            return chat;
        })();

        return await requestPromise;
    } catch (error) {
        console.error('Error in getIndividualChats:', error);
        return [];
    } finally {
        requestPromise = undefined;
    }
};

const lastMessageByChatIdAtom = atom<Record<string, number>>({});
const messagesAtom = atom<Record<string, ApiMessage>>({});
const usersAtom = atom<Record<string, ApiUser>>({});
// Define empty data object outside the component to prevent recreation on each render
const EMPTY_DATA = {
    chats: {},
    users: {},
    messages: {},
    lastMessages: {},
};

export const useChats = () => {
    const tg = useTelegram();
    const { workspaceId } = useParams();
    const { data: cards, isLoading } = useGetBoardCards();

    const teamChatId = useSelector(
        (state: RootStore) => state.auth.user.workSpace?.teamChat?.id,
    );

    // Optimize workspaceChatIds computation by reducing iterations
    const workspaceChatIds = useMemo(() => {
        if (!cards?.length) return [];

        const chatIds = [];
        const processedIds = new Set();
        const teamChatIdStr = teamChatId?.toString();

        // Process card chats
        for (const card of cards) {
            const chatId = card.chatTelegramId.toString();
            if (!processedIds.has(chatId)) {
                processedIds.add(chatId);
                chatIds.push({
                    chatId,
                    chatTitle: card.cardName,
                });
            }
        }

        // Add team chat if needed (only once)
        if (teamChatIdStr && !processedIds.has(teamChatIdStr)) {
            const teamCard = cards.find(
                (card) => card.chatTelegramId === teamChatId,
            );
            chatIds.push({
                chatId: teamChatIdStr,
                chatTitle: teamCard?.cardName || '',
            });
        }

        return chatIds;
    }, [cards, teamChatId]);

    // Query individual chats
    const individualChatsQuery = useQuery({
        queryKey: [QueryKeys.TG_CHATS, workspaceId, 'individual'],
        queryFn: async () => {
            return await getIndividualChats({ tg, workspaceChatIds });
        },
        retryDelay: 6000,
        retry(failureCount) {
            return failureCount === 0;
        },
        enabled:
            !!tg && !isLoading && workspaceChatIds?.length > 0 && !!workspaceId,
    });

    const [, setLastMessageByChatId] = useAtom(lastMessageByChatIdAtom);
    const [, setMessages] = useAtom(messagesAtom);
    const [, setUsers] = useAtom(usersAtom);

    // Optimize combinedData to reduce object spread operations
    const combinedData = useMemo(() => {
        const data = individualChatsQuery.data;
        if (!data || !data.length) return null;

        const result = {
            chats: {},
            users: {},
            messages: {},
            lastMessages: {},
        };

        // Process all individual chats in a single loop
        for (const item of data) {
            if (!item || !item.chat) continue;

            const { chat, msg, lastMessageUserInfo, id } = item;

            // Add chat
            result.chats[id] = chat;

            // Add user if exists
            if (lastMessageUserInfo) {
                result.users[lastMessageUserInfo.id] = lastMessageUserInfo;
            }

            // Add message if exists
            if (msg) {
                result.messages[msg.id] = msg;
                result.lastMessages[id] = msg.id;
            }
        }

        return result;
    }, [individualChatsQuery.data]);

    // Only update atoms when combinedData changes and is not null
    useEffect(() => {
        if (!combinedData) return;

        setLastMessageByChatId(combinedData.lastMessages);
        setMessages(combinedData.messages);
        setUsers(combinedData.users);
    }, [combinedData, setLastMessageByChatId, setMessages, setUsers]);

    // Memoize getChatInfo to prevent recreation on each render
    const getChatInfo = useCallback(
        (chatId: string) => _getChatInfo(chatId, combinedData),
        [combinedData],
    );

    return {
        queryData: combinedData,
        data: combinedData || EMPTY_DATA,
        hasNextPage: false, // Individual chats are loaded all at once
        fetchNextPage: () => Promise.resolve(), // No-op since we don't have pagination
        isLoading: individualChatsQuery.isLoading,
        getChatInfo,
    };
};

/**
 * Forced to use this hook and extra atoms to ensure that the last message is updated in the state
 * and rendered in the UI
 *
 * Tried optimistic update but it didn't work as expected - the last message was not updated in the UI
 */
export const useLastMessageByChatId = (chatId?: string) => {
    const [lastMessageByChatId, setLastMessageByChatId] = useAtom(
        lastMessageByChatIdAtom,
    );
    const [messages, setMessages] = useAtom(messagesAtom);
    const [users] = useAtom(usersAtom);
    const lastMessageId = useMemo(
        () => (chatId ? lastMessageByChatId[chatId] : undefined),
        [chatId, lastMessageByChatId],
    );

    const lastMessage = useMemo(
        () => (lastMessageId ? messages[lastMessageId] : undefined),
        [lastMessageId, messages],
    );

    const lastMessageSender = useMemo(
        () => (lastMessage?.senderId ? users[lastMessage.senderId] : undefined),
        [lastMessage?.senderId, users],
    );

    const setMessage = useCallback(
        (chatId: string, lastMessage: ApiMessage) => {
            const lastMessageId = lastMessage.id;
            setLastMessageByChatId({
                ...lastMessageByChatId,
                [chatId]: lastMessageId,
            });
            setMessages({
                ...messages,
                [lastMessageId]: lastMessage,
            });
        },
        [lastMessageByChatId, messages, setLastMessageByChatId, setMessages],
    );

    return {
        message: {
            ...lastMessage,
            sender: lastMessageSender,
        },
        setMessage,
    };
};

export type ChatItem = ReturnType<ReturnType<typeof useChats>['getChatInfo']>;
function fetchChatWithTimeout({
    tg,
    chatId,
    chatTitle,
}: {
    tg: TelegramWindowContextType | undefined;
    chatId: string;
    chatTitle: string;
}) {
    const proxy = tg?.custom?.proxy;
    if (!proxy?.getChatWithLastMessageById) return Promise.resolve(null);

    return PromiseWithTimeout(proxy, chatId, chatTitle);
}

function PromiseWithTimeout(
    proxy: TelegramWindowContextType['custom']['proxy'],
    chatId: string,
    chatTitle: string,
) {
    return Promise.race([
        (
            proxy.getChatWithLastMessageById(
                +chatId,
            ) as Promise<IndividualChat | null>
        )
            // .then(async (chat) => {
            //     if (!chat) {
            //         const chatByTitle = await proxy.findChatByTitle(chatTitle);
            //         console.log('[Debug] Found chat by title:', chatByTitle);
            //         if (!chatByTitle) return null;
            //         return chatByTitle;
            //     }
            //     return chat;
            // })
            .catch((error: Error) => {
                console.error(
                    `Failed fetch for chat ID: ${chatId} (${chatTitle}):`,
                    error,
                );
                return null;
            }),
        new Promise<null>((resolve) =>
            setTimeout(() => {
                console.warn(`Timeout for chat ${chatId} (${chatTitle})`);
                // Resolve with null instead of rejecting to avoid breaking Promise.all
                resolve(null);
            }, 10000),
        ),
    ]);
}
