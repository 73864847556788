import { Alerter } from '../../../utils';
import { trpc } from '../trpc';

export interface CreateWorkspacePayload {
    name: string;
    file?: {
        base64: string;
        mimetype: string;
        originalname: string;
        size: number;
    };
}

export function useCreateWorkspaceMutation() {
    const utils = trpc.useUtils();
    return trpc.workspace.createWorkspace.useMutation({
        onSuccess: (data) => {
            console.log('Workspace created:', data);
            Alerter.success('Workspace created successfully');
        },
        onError: (error) => {
            console.error('Workspace creation error:', error);
            Alerter.error('Failed to create workspace');
        },
        onSettled: () => {
            utils.workspace.getCurrentUserWorkspaces.invalidate();
        },
    });
}
