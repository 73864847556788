import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';
import { BoardCardItemResponse } from '../../features/Dashboard/types';
import { appendLastMessageDataToCardForSearch } from '../../utils';
import { useChats } from '../../hooks/useChats';

// Constants
export const RECENT_SEARCHES_KEY = 'recent_searches';
export const MAX_RECENT_ITEMS = 5;
export const SEARCH_DEBOUNCE_MS = 0; // No debounce for immediate response
export const MIN_SEARCH_CHARS = 1; // Start searching from first character

// Type for search cache
interface SearchCache {
    [query: string]: BoardCardItemResponse[];
}

/**
 * Custom hook for managing recent searches in localStorage
 */
export const useRecentSearches = () => {
    const [recentItems, setRecentItems] = useState<BoardCardItemResponse[]>([]);

    // Load recent searches from localStorage on component mount
    useEffect(() => {
        try {
            const storedItems = localStorage.getItem(RECENT_SEARCHES_KEY);
            if (storedItems) {
                setRecentItems(JSON.parse(storedItems));
            }
        } catch (error) {
            console.error('Failed to load recent searches:', error);
        }
    }, []);

    // Add a new item to recent searches
    const addRecentItem = useCallback((item: BoardCardItemResponse) => {
        setRecentItems((prevItems) => {
            // Remove the item if it already exists
            const filteredItems = prevItems.filter(
                (prevItem) => prevItem.cardId !== item.cardId,
            );

            // Add the new item at the beginning and limit to MAX_RECENT_ITEMS
            const newItems = [item, ...filteredItems].slice(
                0,
                MAX_RECENT_ITEMS,
            );

            // Save to localStorage
            try {
                localStorage.setItem(
                    RECENT_SEARCHES_KEY,
                    JSON.stringify(newItems),
                );
            } catch (error) {
                console.error('Failed to save recent searches:', error);
            }

            return newItems;
        });
    }, []);

    return { recentItems, addRecentItem };
};

/**
 * Custom hook for search functionality with Fuse.js
 */
export const useSearch = (
    cardsData: BoardCardItemResponse[] | undefined,
    chats: ReturnType<typeof useChats>,
) => {
    const [searchResults, setSearchResults] = useState<BoardCardItemResponse[]>(
        [],
    );
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    // Cache for search results to avoid redundant searches
    const searchCacheRef = useRef<SearchCache>({});

    // Track previous search query for optimization
    const prevSearchQueryRef = useRef<string>('');

    // Use refs to track previous values and prevent unnecessary updates
    const prevCardsDataRef = useRef<BoardCardItemResponse[] | undefined>();
    const prevChatsRef = useRef<ReturnType<typeof useChats>>();

    // Store the processed cards in a ref to avoid recreating them
    const processedCardsRef = useRef<BoardCardItemResponse[]>([]);

    // Precompute search indexes for common prefixes
    const prefixIndexesRef = useRef<{
        [prefix: string]: Fuse<BoardCardItemResponse>;
    }>({});

    // Store all cards indexed by first letter for ultra-fast first character search
    const firstCharIndexRef = useRef<{
        [char: string]: BoardCardItemResponse[];
    }>({});

    // Memoize the processed cards to prevent unnecessary Fuse instance recreation
    const processedCards = useMemo(() => {
        // Only recompute if cardsData or chats.data has changed
        if (
            cardsData !== prevCardsDataRef.current ||
            chats?.data !== prevChatsRef.current?.data
        ) {
            prevCardsDataRef.current = cardsData;
            prevChatsRef.current = chats;

            if (!cardsData) return [];

            // Clear search cache when data changes
            searchCacheRef.current = {};
            prefixIndexesRef.current = {};
            firstCharIndexRef.current = {};

            const processed = appendLastMessageDataToCardForSearch(
                cardsData,
                chats || [],
            );

            // Build first character index for ultra-fast initial filtering
            processed.forEach((card) => {
                if (card.cardName) {
                    const firstChar = card.cardName.charAt(0).toLowerCase();
                    if (!firstCharIndexRef.current[firstChar]) {
                        firstCharIndexRef.current[firstChar] = [];
                    }
                    firstCharIndexRef.current[firstChar].push(card);
                }
            });

            processedCardsRef.current = processed;
            return processed;
        }

        // Return the previous processed cards if inputs haven't changed
        return processedCardsRef.current;
    }, [cardsData, chats?.data]);

    // Create and memoize the Fuse instance for search with optimized settings
    const fuseInstance = useMemo(() => {
        if (!processedCards.length) return null;

        // Create the main Fuse instance with optimized settings
        const fuse = new Fuse(processedCards, {
            keys: ['cardName'],
            includeScore: true,
            threshold: 0.3,
            shouldSort: true,
            ignoreLocation: true,
            findAllMatches: false,
            useExtendedSearch: false,
            minMatchCharLength: MIN_SEARCH_CHARS,
            // Faster sorting function
            sortFn: (a, b) => (a.score || 1) - (b.score || 1),
        });

        // Pre-compute common single-letter prefix indexes
        const alphabet = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < alphabet.length; i++) {
            const prefix = alphabet[i];
            // Find all items that match this prefix
            const results = fuse.search(prefix);
            if (results.length > 0) {
                // Create a specialized Fuse instance for this prefix
                prefixIndexesRef.current[prefix] = new Fuse(
                    results.map((r) => r.item),
                    {
                        keys: ['cardName'],
                        includeScore: true,
                        threshold: 0.3,
                        ignoreLocation: true,
                    },
                );
            }
        }

        return fuse;
    }, [processedCards]);

    // Simple string matching for ultra-fast filtering
    const simpleStringMatch = useCallback(
        (
            items: BoardCardItemResponse[],
            query: string,
        ): BoardCardItemResponse[] => {
            const lowerQuery = query.toLowerCase();
            return items
                .filter((item) =>
                    item.cardName.toLowerCase().includes(lowerQuery),
                )
                .sort((a, b) => {
                    // Sort by how early the match appears in the string
                    const aIndex = a.cardName.toLowerCase().indexOf(lowerQuery);
                    const bIndex = b.cardName.toLowerCase().indexOf(lowerQuery);
                    return aIndex - bIndex;
                });
        },
        [],
    );

    // Ultra-fast search for immediate response
    const performUltraFastSearch = useCallback(
        (query: string) => {
            if (!query.trim()) {
                setSearchResults([]);
                return;
            }

            // For cached results, use them immediately
            if (searchCacheRef.current[query]) {
                setSearchResults(searchCacheRef.current[query]);
                return;
            }

            // For single character, use the first char index
            if (query.length === 1) {
                const char = query.toLowerCase();
                if (firstCharIndexRef.current[char]) {
                    setSearchResults(firstCharIndexRef.current[char]);
                    searchCacheRef.current[query] =
                        firstCharIndexRef.current[char];
                    return;
                }
            }

            // For queries that extend previous queries, use simple string matching on previous results
            const prevQuery = prevSearchQueryRef.current;
            if (
                prevQuery &&
                query.startsWith(prevQuery) &&
                searchCacheRef.current[prevQuery]
            ) {
                const results = simpleStringMatch(
                    searchCacheRef.current[prevQuery],
                    query,
                );
                setSearchResults(results);
                searchCacheRef.current[query] = results;
                return;
            }

            // Fallback to full search
            if (fuseInstance) {
                const results = fuseInstance.search(query).map((r) => r.item);
                setSearchResults(results);
                searchCacheRef.current[query] = results;
            }
        },
        [fuseInstance, simpleStringMatch],
    );

    // Effect to handle search query changes
    useEffect(() => {
        if (!searchQuery.trim()) {
            setSearchResults([]);
            setIsSearching(false);
            return;
        }

        setIsSearching(true);

        // Perform search immediately
        performUltraFastSearch(searchQuery);

        // Update previous query ref
        prevSearchQueryRef.current = searchQuery;

        // Mark search as complete
        setIsSearching(false);
    }, [searchQuery]);

    // Optimized search query setter with input normalization
    const setOptimizedSearchQuery = useCallback((query: string) => {
        // Normalize the query to improve cache hits (lowercase, trim extra spaces)
        const normalizedQuery = query.toLowerCase().trim().replace(/\s+/g, ' ');
        setSearchQuery(normalizedQuery);
    }, []);

    return {
        searchResults,
        setSearchQuery: setOptimizedSearchQuery,
        isSearching,
    };
};
