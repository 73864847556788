/* eslint-disable indent */
import React, { memo, MouseEvent, forwardRef } from 'react';
import { Box, Flex, Text, Avatar } from '@chakra-ui/react';
import { BoardCardItemResponse } from '../../features/Dashboard/types';
import { useAvatar } from '../TaskCard/hooks';
import { useChats } from '../../hooks/useChats';
import { formatDate } from '../../utils';

// Extended type that includes the lastMessage property added by appendLastMessageDataToCardForSearch
interface CommandItemData extends BoardCardItemResponse {
    lastMessage?: {
        date?: string;
    };
}

interface CommandProps {
    data: CommandItemData;
    isHighlighted: boolean;
    onClick?: (event: MouseEvent<Element>) => void;
}

// Memoized date component to prevent unnecessary re-renders
const DateDisplay = memo(({ date }: { date?: string }) => {
    if (!date) return null;
    return (
        <Text
            fontSize="11px"
            color="gray.35"
            fontWeight={400}
            whiteSpace="nowrap"
        >
            {formatDate(date)}
        </Text>
    );
});

// Memoized Command component with forwardRef to prevent unnecessary re-renders
// and allow refs to be passed from parent components
export const Command = memo(
    forwardRef<HTMLLIElement, CommandProps>(
        ({ data, isHighlighted, ...props }, ref) => {
            const chat = useChats().getChatInfo(data.chatTelegramId.toString());
            const chatAvatar = useAvatar(chat?.id, chat?.accessHash);

            // Precompute styles based on highlight state to avoid style recalculations
            const containerStyle = {
                backgroundColor: isHighlighted ? 'gray.15' : 'transparent',
                borderRadius: '4px',
                cursor: 'pointer',
                padding: '8px',
                transition: 'background-color 0.1s ease',
                userSelect: 'none',
                width: '100%',
            };

            return (
                <Box
                    as="li"
                    role="option"
                    aria-selected={isHighlighted}
                    sx={containerStyle}
                    ref={ref}
                    {...props}
                >
                    <Flex justifyContent="space-between" alignItems="center">
                        <Flex alignItems="center" gap="8px" maxWidth="85%">
                            <Avatar
                                boxSize="14px"
                                borderRadius="2px"
                                src={chatAvatar || ''}
                                name={data.cardName}
                                sx={{
                                    '> div': {
                                        fontSize: '8px',
                                    },
                                }}
                            />
                            <Text
                                fontSize="13px"
                                fontWeight={400}
                                color="primary"
                                noOfLines={1}
                            >
                                {data.cardName}
                            </Text>
                        </Flex>
                        <DateDisplay date={data.lastMessage?.date} />
                    </Flex>
                </Box>
            );
        },
    ),
);

// Add display name for better debugging
Command.displayName = 'Command';
DateDisplay.displayName = 'DateDisplay';
