import { trpc } from '@core/api/trpc';

// Helper function to process labels (not a hook)
function processLabels(data: any[] | undefined) {
    if (!data) return [];

    // Create a map to track unique labels by name
    const labelMap = new Map();

    // First, add all non-assigned labels to the map
    data.forEach((label) => {
        if (!label.workspaceCardId) {
            labelMap.set(label.name, label);
        }
    });

    // Then, add assigned labels only if they don't already exist
    data.forEach((label) => {
        if (label.workspaceCardId && !labelMap.has(label.name)) {
            labelMap.set(label.name, label);
        }
    });

    // Convert the map back to an array
    return Array.from(labelMap.values());
}

export const useGetWorkspaceLabels = () => {
    const query = trpc.workspace.getWorkspaceLabels.useQuery(undefined, {
        refetchOnMount: 'always', // Always refetch when the component mounts
        refetchOnWindowFocus: 'always', // Always refetch when the window regains focus
        refetchOnReconnect: 'always', // Always refetch when reconnecting
        retry: 3, // Retry failed requests 3 times
        retryDelay: 1000, // Wait 1 second between retries
        networkMode: 'always', // Always attempt network requests, even if offline
    });

    // Process the result without introducing additional hooks
    const processedData = query.data ? processLabels(query.data) : [];

    return {
        ...query,
        data: processedData,
    };
};
