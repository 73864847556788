import { trpc } from '../../../core/api/trpc';
import { CreateActivityPayload } from '../types';
import { Alerter } from '../../../utils';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';

export const useCreateCardActivity = () => {
    const queryClient = useQueryClient();
    const { user } = useSelector((state: RootStore) => state.auth);

    return trpc.activities.createCardActivity.useMutation({
        // Implement optimistic updates
        onMutate: async (variables) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries({
                queryKey: [
                    'chat-messages',
                    variables.cardId,
                    variables.workspaceId,
                ],
            });

            // Get the current activity data to find the description
            const activitiesData = queryClient.getQueryData<any>([
                'trpc',
                'activities',
                'getCardActivitiesOptions',
                {
                    input: {
                        workspaceId: variables.workspaceId,
                        cardId: variables.cardId,
                    },
                },
            ]);

            const activityDescription =
                activitiesData?.workspaceCardActivities?.find(
                    (activity: any) =>
                        activity.id === variables.workspaceCardActivityId,
                )?.description || 'Loading...';

            // Get the previous data
            const previousData = queryClient.getQueryData<any>([
                'chat-messages',
                variables.cardId,
                variables.workspaceId,
            ]);

            // Create an optimistic activity message
            const optimisticMessage = {
                id: `temp-${Date.now()}`,
                messageType: 2, // Activity message type
                activity: {
                    id: `temp-activity-${Date.now()}`,
                    content: activityDescription,
                    operation: 'by',
                    authorInfo: {
                        id: user.id,
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        telegramUserId: user.telegramUserId || 0,
                    },
                    createdAt: new Date().toISOString(),
                },
                sentAt: new Date().toISOString(),
            };

            // Update the query data with our optimistic message
            if (previousData?.pages?.[0]) {
                queryClient.setQueryData(
                    ['chat-messages', variables.cardId, variables.workspaceId],
                    {
                        ...previousData,
                        pages: [
                            {
                                ...previousData.pages[0],
                                messages: [
                                    optimisticMessage,
                                    ...previousData.pages[0].messages,
                                ],
                            },
                            ...previousData.pages.slice(1),
                        ],
                    },
                );
            }

            return { previousData };
        },
        onSuccess: (data, variables, context) => {
            if (!data.success) {
                // If the mutation fails, roll back to the previous state
                if (context?.previousData) {
                    queryClient.setQueryData(
                        [
                            'chat-messages',
                            variables.cardId,
                            variables.workspaceId,
                        ],
                        context.previousData,
                    );
                }
                return;
            }

            Alerter.success('Activity was successfully created!');
        },
        onError: (error, variables, context) => {
            console.error('Error creating activity:', error);
            // If the mutation fails, roll back to the previous state
            if (context?.previousData) {
                queryClient.setQueryData(
                    ['chat-messages', variables.cardId, variables.workspaceId],
                    context.previousData,
                );
            }
            Alerter.error('Failed to create activity');
        },
        onSettled: (data, error, variables) => {
            // Always refetch after error or success to ensure we have the correct data
            queryClient.invalidateQueries({
                queryKey: [
                    'chat-messages',
                    variables.cardId,
                    variables.workspaceId,
                ],
            });
        },
    });
};
