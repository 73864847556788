import { useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    Flex,
    HStack,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { TeamChatSelector } from './components';
import { InputText } from '../../components';
import { UploadWorkspaceAvatar } from '../../modals/Onboarding/components';
import { useGetWorkspaceInfo, useUpdateWorkspaceLogo } from './queries';
import {
    updateWorkspaceResolver,
    UpdateWorkspaceForm,
} from '../../modals/Onboarding/validation';
import { DangerZone } from './components/DangerZone';
import { SectionTitle } from './components/SectionTitle';
import { SectionDescription } from './components/SectionDescription';
import { trpc } from '@core/api/trpc';
import { useGetWorkspaceId } from '../../hooks';
import { useNavigate } from 'react-router-dom';

const SettingsPage = () => {
    const utils = trpc.useUtils();

    // Hooks
    const workspaceId = useGetWorkspaceId();
    const navigate = useNavigate();

    // Form hooks
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useForm<UpdateWorkspaceForm>({ resolver: updateWorkspaceResolver });

    // Queries and mutations
    const { data: workspaceData, isLoading } = useGetWorkspaceInfo();
    const updateWorkspaceInfoMutation =
        trpc.workspace.updateWorkspaceInfo.useMutation({
            onSuccess(_, variables) {
                utils.workspace.getWorkspaceInfo.invalidate();
                utils.workspace.getCurrentUserWorkspaces.invalidate();
                reset({ name: variables.name });
            },
        });

    const updateWorkspaceLogoMutation = useUpdateWorkspaceLogo();
    const ownershipQuery = trpc.workspace.checkWorkspaceOwner.useQuery(
        { workspaceId },
        { retry: false },
    );

    const nameWatch = watch('name');

    // Effects
    useEffect(() => {
        if (ownershipQuery.isError) {
            navigate(`/${workspaceId}`);
        }
    }, [ownershipQuery.isError, workspaceId, navigate]);

    useEffect(() => {
        if (workspaceData?.workspaceName) {
            reset({ name: workspaceData?.workspaceName });
        }
    }, [workspaceData?.workspaceName, reset]);

    useEffect(() => {
        if (workspaceData?.logoUrl && !isLoading) {
            // setImageFromFile(workspaceData?.logoUrl);
        }
    }, [workspaceData?.logoUrl, isLoading]);

    // Handlers
    const showImage = async (file: File) => {
        const buffer = await file.arrayBuffer();
        const base64 = Buffer.from(buffer).toString('base64');

        updateWorkspaceLogoMutation.mutate({
            workspaceId: String(workspaceId),
            file: {
                base64,
                mimetype: file.type,
                originalname: file.name,
                size: file.size,
            },
            action: 'upload',
        });
    };

    const removeImage = () => {
        updateWorkspaceLogoMutation.mutate({
            workspaceId: String(workspaceId),
            action: 'delete',
        });
    };

    const onSubmitName = (data: UpdateWorkspaceForm) => {
        updateWorkspaceInfoMutation.mutate({ name: data.name });
    };

    // Loading state
    if (ownershipQuery.isLoading) {
        return (
            <Center h="100vh">
                <Spinner size="xl" color="blue.500" />
            </Center>
        );
    }

    console.log('logoUrl', workspaceData?.logoUrl);

    // Render
    return (
        <Box as="section" id="settings-layout" h="100%" w="100%">
            <Box
                bg="white"
                h="100%"
                borderRadius="8px"
                border="1px solid"
                borderColor="gray.20"
                p="32px"
            >
                <Flex flexDirection="column" gap="32px" maxW="container.sm">
                    <Text color="tertiary" fontSize="16px" fontWeight="600">
                        Workspace
                    </Text>
                    <UploadWorkspaceAvatar
                        imageFromFile={workspaceData?.logoUrl || ''}
                        removeImage={removeImage}
                        showImage={showImage}
                        title="Company logo"
                        removeLoading={updateWorkspaceLogoMutation.isPending}
                        uploadLoading={updateWorkspaceLogoMutation.isPending}
                        isLoading={isLoading}
                    />
                    <Box>
                        <SectionTitle>Company name</SectionTitle>
                        <SectionDescription mt="12px" mb="16px">
                            The name of your workspace. Will be used to name new
                            groups
                        </SectionDescription>
                        <form onSubmit={handleSubmit(onSubmitName)}>
                            <HStack align="flex-start" mt="16px">
                                <InputText
                                    {...register('name')}
                                    errorMsg={errors?.name?.message}
                                />
                                {nameWatch !== workspaceData?.workspaceName ? (
                                    <Button
                                        type="submit"
                                        isLoading={
                                            updateWorkspaceInfoMutation.isPending
                                        }
                                    >
                                        Save
                                    </Button>
                                ) : null}
                            </HStack>
                        </form>
                    </Box>
                    <TeamChatSelector />
                    <DangerZone />
                </Flex>
            </Box>
        </Box>
    );
};

export default SettingsPage;
