// Copy from tg codebase, unfortunately import does not work

import {
	ApiMessageEntityTypes,
	ApiFormattedText,
	ApiMessageEntity,
} from '../../../telegram-tt/src/api/types';

// import { RE_LINK_TEMPLATE } from '../config';
// import { IS_EMOJI_SUPPORTED } from './windowEnvironment';

export const RE_LINK_TEMPLATE =
	'((ftp|https?):\\/\\/)?((www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z][-a-zA-Z0-9]{1,62})\\b([-a-zA-Z0-9()@:%_+.,~#?&/=]*)';

export const ENTITY_CLASS_BY_NODE_NAME: Record<string, ApiMessageEntityTypes> =
{
	B: ApiMessageEntityTypes.Bold,
	STRONG: ApiMessageEntityTypes.Bold,
	I: ApiMessageEntityTypes.Italic,
	EM: ApiMessageEntityTypes.Italic,
	INS: ApiMessageEntityTypes.Underline,
	U: ApiMessageEntityTypes.Underline,
	S: ApiMessageEntityTypes.Strike,
	STRIKE: ApiMessageEntityTypes.Strike,
	DEL: ApiMessageEntityTypes.Strike,
	CODE: ApiMessageEntityTypes.Code,
	PRE: ApiMessageEntityTypes.Pre,
	BLOCKQUOTE: ApiMessageEntityTypes.Blockquote,
};

const MAX_TAG_DEEPNESS = 5;

function cleanHtml(html: string): string {
	let cleaned = html;

	// Remove the contenteditable div wrapper
	cleaned = cleaned.replace(/<div[^>]*>([\s\S]*?)<\/div>/s, '$1');

	// Handle both MessageEntity types and style-based formatting
	cleaned = cleaned.replace(/<span[^>]*data-entity-type="MessageEntityBold"[^>]*>([\s\S]*?)<\/span>/g, '<b>$1</b>');
	cleaned = cleaned.replace(/<ins[^>]*data-entity-type="MessageEntityUnderline"[^>]*>([\s\S]*?)<\/ins>/g, '<u>$1</u>');
	cleaned = cleaned.replace(/<em[^>]*data-entity-type="MessageEntityItalic"[^>]*>([\s\S]*?)<\/em>/g, '<i>$1</i>');
	cleaned = cleaned.replace(/<span[^>]*text-decoration-line:\s*line-through[^>]*>([\s\S]*?)<\/span>/g, '<s>$1</s>');
	cleaned = cleaned.replace(/<span[^>]*text-decoration:\s*line-through[^>]*>([\s\S]*?)<\/span>/g, '<s>$1</s>');
	cleaned = cleaned.replace(/<span class="s2"[^>]*>([\s\S]*?)<\/span>/g, '<s>$1</s>');

	// Handle both types of links/mentions
	cleaned = cleaned.replace(/<a[^>]*href="([^"]*)"[^>]*data-entity-type="MessageEntityUrl"[^>]*>([\s\S]*?)<\/a>/g,
		(_, url, text) => `<a href="${url}">${text}</a>`);
	cleaned = cleaned.replace(/<span[^>]*color:\s*rgb\(31,\s*107,\s*192\)[^>]*>([\s\S]*?)<\/span>/g,
		(_, text) => `<a href="mention:${text}">${text}</a>`);

	// Handle timestamp format
	cleaned = cleaned.replace(/(\[\d+\s+\w+\s+\d+\s+at\s+[\d:]+\s+[AP]M\]):\s*/g, '$1: ');

	// Remove unnecessary attributes
	cleaned = cleaned.replace(/\s+style="[^"]*"/g, '');
	cleaned = cleaned.replace(/\s+class="[^"]*"/g, '');
	cleaned = cleaned.replace(/\s+title="[^"]*"/g, '');
	cleaned = cleaned.replace(/\s+target="[^"]*"/g, '');
	cleaned = cleaned.replace(/\s+rel="[^"]*"/g, '');
	cleaned = cleaned.replace(/\s+dir="[^"]*"/g, '');

	// Handle paragraphs and line breaks
	cleaned = cleaned.replace(/<p[^>]*>([\s\S]*?)<\/p>/g, '$1\n');
	cleaned = cleaned.replace(/<br[^>]*>/g, '\n');
	cleaned = cleaned.replace(/\n{3,}/g, '\n\n');

	return cleaned.trim();
}

export function parseHtmlAsFormattedText(
	html: string,
	withMarkdownLinks = false,
	skipMarkdown = false,
): ApiFormattedText {
	const cleanedHtml = cleanHtml(html);

	const fragment = document.createElement('div');
	fragment.innerHTML = skipMarkdown
		? cleanedHtml
		: withMarkdownLinks
			? parseMarkdown(parseMarkdownLinks(cleanedHtml))
			: parseMarkdown(cleanedHtml);

	fixImageContent(fragment);

	// Get text content while preserving exact whitespace
	const text = fragment.innerText.replace(/\u200b+/g, '').trim();
	const entities: ApiMessageEntity[] = [];
	let textIndex = 0;

	function processNode(node: ChildNode) {
		if (node.nodeType === Node.COMMENT_NODE) {
			return;
		}

		if (node.nodeType === Node.ELEMENT_NODE) {
			const element = node as HTMLElement;
			const type = getEntityTypeFromNode(element);

			if (type && element.textContent) {
				const content = element.textContent;
				const startIndex = text.indexOf(content, textIndex);

				if (startIndex >= 0) {
					const entity = getEntityDataFromNode(element, text, startIndex);
					if (entity.entity) {
						entities.push(entity.entity);
					}
				}
			}

			// Process child nodes
			Array.from(element.childNodes).forEach(child => {
				processNode(child);
			});
		}

		// Update text index after processing node
		if (node.textContent) {
			textIndex = text.indexOf(node.textContent) + node.textContent.length;
		}
	}

	Array.from(fragment.childNodes).forEach(processNode);

	return {
		text,
		entities: entities.length ? entities : undefined,
	};
}

export function fixImageContent(fragment: HTMLDivElement) {
	fragment.querySelectorAll('img').forEach(node => {
		if (node.dataset.documentId) {
			// Custom Emoji
			node.textContent = (node as HTMLImageElement).alt || '';
		} else {
			// Regular emoji with image fallback
			node.replaceWith(node.alt || '');
		}
	});
}

function parseMarkdown(html: string) {
	let parsedHtml = html.slice(0);

	// Strip redundant nbsp's
	parsedHtml = parsedHtml.replace(/&nbsp;/g, ' ');

	// Replace <div><br></div> with newline (new line in Safari)
	parsedHtml = parsedHtml.replace(/<div><br([^>]*)?><\/div>/g, '\n');
	// Replace <br> with newline
	parsedHtml = parsedHtml.replace(/<br([^>]*)?>/g, '\n');

	// Strip redundant <div> tags
	parsedHtml = parsedHtml.replace(/<\/div>(\s*)<div>/g, '\n');
	parsedHtml = parsedHtml.replace(/<div>/g, '\n');
	parsedHtml = parsedHtml.replace(/<\/div>/g, '');

	// Pre
	parsedHtml = parsedHtml.replace(
		/^`{3}(.*?)[\n\r](.*?[\n\r]?)`{3}/gms,
		'<pre data-language="$1">$2</pre>',
	);
	parsedHtml = parsedHtml.replace(
		/^`{3}[\n\r]?(.*?)[\n\r]?`{3}/gms,
		'<pre>$1</pre>',
	);
	parsedHtml = parsedHtml.replace(/[`]{3}([^`]+)[`]{3}/g, '<pre>$1</pre>');

	// Code
	parsedHtml = parsedHtml.replace(
		/(?!<(code|pre)[^<]*|<\/)[`]{1}([^`\n]+)[`]{1}(?![^<]*<\/(code|pre)>)/g,
		'<code>$2</code>',
	);

	// Custom Emoji markdown tag
	// if (!IS_EMOJI_SUPPORTED) {
	// 	// Prepare alt text for custom emoji
	// 	parsedHtml = parsedHtml.replace(
	// 		/\[<img[^>]+alt="([^"]+)"[^>]*>]/gm,
	// 		'[$1]',
	// 	);
	// }
	parsedHtml = parsedHtml.replace(
		/(?!<(?:code|pre)[^<]*|<\/)\[([^\]\n]+)\]\(customEmoji:(\d+)\)(?![^<]*<\/(?:code|pre)>)/g,
		'<img alt="$1" data-document-id="$2">',
	);

	// Other simple markdown
	parsedHtml = parsedHtml.replace(
		/(?!<(code|pre)[^<]*|<\/)[*]{2}([^*\n]+)[*]{2}(?![^<]*<\/(code|pre)>)/g,
		'<b>$2</b>',
	);
	parsedHtml = parsedHtml.replace(
		/(?!<(code|pre)[^<]*|<\/)[_]{2}([^_\n]+)[_]{2}(?![^<]*<\/(code|pre)>)/g,
		'<i>$2</i>',
	);
	parsedHtml = parsedHtml.replace(
		/(?!<(code|pre)[^<]*|<\/)[~]{2}([^~\n]+)[~]{2}(?![^<]*<\/(code|pre)>)/g,
		'<s>$2</s>',
	);
	parsedHtml = parsedHtml.replace(
		/(?!<(code|pre)[^<]*|<\/)[|]{2}([^|\n]+)[|]{2}(?![^<]*<\/(code|pre)>)/g,
		`<span data-entity-type="${ApiMessageEntityTypes.Spoiler}">$2</span>`,
	);

	return parsedHtml;
}

function parseMarkdownLinks(html: string) {
	return html.replace(
		new RegExp(`\\[([^\\]]+?)]\\((${RE_LINK_TEMPLATE}+?)\\)`, 'g'),
		(_, text, link) => {
			const url = link.includes('://')
				? link
				: link.includes('@')
					? `mailto:${link}`
					: `https://${link}`;
			return `<a href="${url}">${text}</a>`;
		},
	);
}

function getEntityDataFromNode(
	node: ChildNode,
	rawText: string,
	textIndex: number,
): { index: number; entity?: ApiMessageEntity } {
	const type = getEntityTypeFromNode(node);

	if (!type || !node.textContent) {
		return {
			index: textIndex,
			entity: undefined,
		};
	}

	const rawIndex = rawText.indexOf(node.textContent, textIndex);
	const index = rawIndex >= 0 ? rawIndex : textIndex;
	const offset = rawText.substring(0, index).length;
	const length = node.textContent.length;

	if (type === ApiMessageEntityTypes.TextUrl) {
		return {
			index,
			entity: {
				type,
				offset,
				length,
				url: (node as HTMLAnchorElement).href,
			},
		};
	}

	if (type === ApiMessageEntityTypes.MentionName) {
		return {
			index,
			entity: {
				type,
				offset,
				length,
				userId: (node as HTMLAnchorElement).dataset.userId!,
			},
		};
	}

	if (type === ApiMessageEntityTypes.Pre) {
		return {
			index,
			entity: {
				type,
				offset,
				length,
				language: (node as HTMLPreElement).dataset.language,
			},
		};
	}

	if (type === ApiMessageEntityTypes.CustomEmoji) {
		return {
			index,
			entity: {
				type,
				offset,
				length,
				documentId: (node as HTMLImageElement).dataset.documentId!,
			},
		};
	}

	return {
		index,
		entity: {
			type,
			offset,
			length,
		},
	};
}

function getEntityTypeFromNode(node: Node): ApiMessageEntityTypes | undefined {
	if (node instanceof HTMLElement) {
		// Handle data-entity-type attributes
		const entityType = node.getAttribute('data-entity-type');
		if (entityType) {
			switch (entityType) {
				case 'MessageEntityBold':
					return ApiMessageEntityTypes.Bold;
				case 'MessageEntityItalic':
					return ApiMessageEntityTypes.Italic;
				case 'MessageEntityUnderline':
					return ApiMessageEntityTypes.Underline;
				case 'MessageEntityUrl':
					return ApiMessageEntityTypes.TextUrl;
			}
		}

		// Handle standard HTML tags
		if (ENTITY_CLASS_BY_NODE_NAME[node.nodeName]) {
			return ENTITY_CLASS_BY_NODE_NAME[node.nodeName];
		}

		// Handle links
		if (node.nodeName === 'A') {
			const anchor = node as HTMLAnchorElement;
			if (anchor.href.startsWith('mention:')) {
				return ApiMessageEntityTypes.MentionName;
			}
			return ApiMessageEntityTypes.TextUrl;
		}
	}
	return undefined;
}
