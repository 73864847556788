import { Center, Text, VStack } from '@chakra-ui/react';
import { IconUserCancel } from '@tabler/icons-react';

export const NotInChatPlaceholder = () => {
    return (
        <Center
            h="100%"
            bg="white"
            borderRadius="8px"
            border="2px solid #ebebeb"
        >
            <VStack spacing={3}>
                <IconUserCancel size={48} color="gray" stroke={1} />
                <VStack spacing={1}>
                    <Text color="gray.700">People in this chat:</Text>
                    <Text color="gray.700">not you</Text>
                </VStack>
            </VStack>
        </Center>
    );
};
