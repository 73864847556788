import React from 'react';
import { HStack, Flex, Text, Button, Tooltip } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootStore } from '../../redux/createStore';
import {
    Icon,
    CustomAvatarGroup,
    InviteMembersButton,
    CommandMenu,
} from '../../components';
import { useGetWorkspaceMembers } from '../../queries';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { SyncSettingsButton } from '../../components/MainLayout/components/sync-settings-button';
import { BaseUserData } from '../../types';
import { useAtom } from 'jotai';
import { isSortedReverseAtom } from '../../atoms/tasksAtom';

interface DefaultPageHeaderProps {
    title: string;
    iconName?: string;
    isSyncSettingsEnabled?: boolean;
    isInviteMembersEnabled?: boolean;
}

export const DefaultPageHeader: React.FC<DefaultPageHeaderProps> = ({
    title,
    iconName,
    isSyncSettingsEnabled,
    isInviteMembersEnabled,
}) => {
    const avatars = useSelector((state: RootStore) => state.avatars);

    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    const workspaceId = useGetMatchedWorkspaceId();

    const { data: workspaceMembers } = useGetWorkspaceMembers();

    // Add atom to control sort order
    const [isSortedReverse, setIsSortedReverse] = useAtom(isSortedReverseAtom);

    // Transform the workspaceMembers data to match the BaseUserData type
    const members: BaseUserData[] =
        workspaceMembers?.map((member) => ({
            id: member.id,
            firstName: member.firstName || '',
            lastName: member.lastName,
            avatarThumbnailUrl: null,
            telegramUserId: member.telegramUserId,
        })) || [];

    // Function to toggle sort order
    const handleSortClick = () => {
        setIsSortedReverse((prev) => !prev);
    };

    return (
        <Flex as="header" id="default-header" align="center" mb="0.75rem">
            <HStack
                spacing={1}
                align="center"
                h="20px"
                width="100%"
                minW="150px"
                maxWidth="calc((100vw / 2) - 240px - 184px)"
            >
                {iconName ? (
                    <Icon name={iconName} width="16px" height="16px" />
                ) : null}

                <Text noOfLines={1}>{title}</Text>
            </HStack>

            <CommandMenu />

            <HStack spacing={0} flex={1} pl="14px" justify="flex-end">
                <Tooltip
                    label={
                        isSortedReverse
                            ? 'Sort Newest First'
                            : 'Sort Oldest First'
                    }
                    placement="top"
                    bg="gray.200"
                    color="gray.600"
                    borderRadius="4px"
                    px={1}
                    py={0.5}
                    fontSize="xs"
                >
                    <Button
                        leftIcon={
                            <Icon name="sort" height="14px" width="14px" />
                        }
                        variant="outline"
                        bg={'gray.10'}
                        size="sm"
                        height="24px"
                        px={1}
                        width="40px"
                        iconSpacing={0}
                        color={'secondary'}
                        borderRadius="4px 0 0 4px"
                        borderRight="1px solid"
                        borderColor={'gray.200'}
                        position="relative"
                        onClick={handleSortClick}
                    ></Button>
                </Tooltip>
                <Tooltip
                    label="Soon"
                    placement="top"
                    bg="gray.200"
                    color="gray.600"
                    borderRadius="4px"
                    px={1}
                    py={0.5}
                    fontSize="xs"
                >
                    <Button
                        leftIcon={
                            <Icon name="tabler" height="14px" width="14px" />
                        }
                        variant="outline"
                        bg="gray.10"
                        size="sm"
                        height="24px"
                        px={1}
                        width="40px"
                        iconSpacing={0}
                        color="secondary"
                        borderRadius="0 4px 4px 0"
                        borderLeft="none"
                        position="relative"
                        mr="16px"
                    ></Button>
                </Tooltip>
                {workSpace?.isAdmin ? (
                    <Button
                        leftIcon={
                            <Icon name="edit" height="16px" width="16px" />
                        }
                        variant="outline"
                        as={Link}
                        to={`/${workspaceId}/settings/pipeline`}
                        bg="gray.10"
                        size="sm"
                        height="24px"
                        px={2}
                        iconSpacing={1}
                        color="secondary"
                        borderRadius="4px"
                        mr="16px"
                    >
                        Customize
                    </Button>
                ) : null}

                {isSyncSettingsEnabled ? (
                    <SyncSettingsButton
                        name="Sync settings"
                        iconName="refresh"
                    />
                ) : null}

                <CustomAvatarGroup items={members} avatars={avatars} />

                {workSpace?.isAdmin && isInviteMembersEnabled ? (
                    <InviteMembersButton />
                ) : null}
            </HStack>
        </Flex>
    );
};
