import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { TasksSection } from '../components';
import {
    useGetAllCurrentUserTasks,
    useGetAllTasksOtherUsers,
} from '../queries';
import { CardTaskStatusEnum } from '../../../types';
import { Loader } from '../../../components';
import { useUpdateCardTaskStatus } from '../../Chat/queries';
import { Alerter } from '../../../utils';
import { QueryKeys } from '../../../constants';
import { useGetWorkspaceId } from '../../../hooks';

interface TasksPanelTemplateTabsProps {
    filterId: CardTaskStatusEnum;
}

export const TasksPanelTemplateTabs: React.FC<TasksPanelTemplateTabsProps> = ({
    filterId,
}) => {
    const queryClient = useQueryClient();
    const workspaceId = useGetWorkspaceId();

    const updateTaskMutation = useUpdateCardTaskStatus();

    const { data: currentUserTasksData, isFetching: currentUserTasksLoading } =
        useGetAllCurrentUserTasks(workspaceId, filterId);

    const { data: othersUsersTasksData, isFetching: othersUsersTasksLoading } =
        useGetAllTasksOtherUsers(CardTaskStatusEnum.TODO, workspaceId);

    const currentUserTasks = currentUserTasksData?.tasks || [];
    const otherUsersTasks = othersUsersTasksData || [];

    const onUpdateTaskStatus = (
        cardTaskId: number,
        status: CardTaskStatusEnum,
        isCardArchived: boolean,
        telegramUsersIds: number[],
        workspaceId?: number,
    ) => {
        try {
            if (!workspaceId) {
                return Alerter.error('Workspace is missing');
            }

            updateTaskMutation.mutate(
                {
                    bodyPayload: {
                        workspaceId,
                        status,
                        cardTaskId,
                        telegramUsersIds,
                    },
                    isCardArchived,
                },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({
                            queryKey: [QueryKeys.GET_ALL_CURRENT_USER_TASKS],
                        });
                    },
                },
            );
        } catch (error) {
            console.log('onUpdateTaskStatus: ', error);
        }
    };

    const isLoading = currentUserTasksLoading || othersUsersTasksLoading;
    const emptyData = !currentUserTasks.length && !otherUsersTasks.length;

    if (isLoading) {
        return <Loader centerHeight="calc(100vh - 170px)" />;
    }

    if (!isLoading && emptyData) {
        return (
            <Flex justify="center" align="center" h="calc(100vh - 160px)">
                <Text fontSize="24px" fontWeight={600}>
                    There are no tasks at the moment
                </Text>
            </Flex>
        );
    }

    return (
        <Box>
            <TasksSection
                title="Your tasks"
                data={currentUserTasks}
                totalCount={currentUserTasksData?.count || 0}
                isSinglePageSection={!otherUsersTasks.length}
                onUpdateTaskStatus={(
                    cardTaskId,
                    status,
                    isCardArchived,
                    telegramUsersIds,
                ) =>
                    onUpdateTaskStatus(
                        cardTaskId,
                        status,
                        isCardArchived,
                        telegramUsersIds,
                        workspaceId,
                    )
                }
            />
            <TasksSection
                title="Other tasks"
                data={otherUsersTasks}
                totalCount={othersUsersTasksData?.length || 0}
                isSinglePageSection={!currentUserTasks.length}
                sectionProps={{
                    mt: currentUserTasks.length ? 8 : 0,
                }}
            />
        </Box>
    );
};
