import { trpc } from "@core/api/trpc";
import { useGetCurrentUserQuery } from "@core/api/queries/current-user";
import { useSetCurrentWorkspaceMutation } from "@core/api/mutations/set-current-workspace";
import { useGetCurrentUserWorkspacesQuery } from "@core/api/queries/current-user-workspaces";
import { useModals, useNavigate } from "@router";
import { useState } from "react";

export const useExistingConference = () => {
  const [isLoading, setIsLoading] = useState(false);
  const modals = useModals();
  const navigate = useNavigate();
  const getCurrentUserQuery = useGetCurrentUserQuery();
  const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();
  const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();
  const setStartConferenceMutation = trpc.conference.updateConferenceStarted.useMutation();;
  const { data: conferences } = trpc.conference.getAllConferences.useQuery({
    userId: Number(getCurrentUserQuery.data?.id),
  });

  const handleAddToExisting = async (selectedConferenceId: string) => {
    if (!selectedConferenceId) return;
    
    setIsLoading(true);
    try {
      const workspaceId = parseInt(selectedConferenceId);
      await setStartConferenceMutation.mutateAsync({workspaceId, targetDate: new Date()});
      await setCurrentWorkspaceMutation.mutateAsync({workspaceId});
      await getCurrentUserQuery.refetch();
      await getCurrentUserWorkspacesQuery.refetch();
      modals.close();
      navigate('/:workspaceId', { params: { workspaceId: selectedConferenceId } });
    } catch (error) {
      console.error('Error setting workspace:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    conferences,
    handleAddToExisting,
    isLoading
  };
}; 