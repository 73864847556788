import { trpc } from '@core/api/trpc';
import { BoardCardItemResponse } from '@features/Dashboard/types';
import { useGetMatchedWorkspaceId } from './useGetWorkspaceId';
import { RootStore } from '../redux/createStore';
import { useSelector } from 'react-redux';

export const useGetCardById = (cardId: number) => {
    const userData = useSelector((state: RootStore) => state.auth.user);

    try {
        const workspaceId = useGetMatchedWorkspaceId();
        const getCardQuery = trpc.cards.getCardAndOwner.useQuery(
            {
                cardId,
                workspaceId,
            },
            {
                enabled: !!cardId && !!userData,
            },
        );

        const getCardDetailsQuery = trpc.cards.getCardById.useQuery(
            {
                cardId,
            },
            {
                enabled: !!cardId && !!userData,
            },
        );

        const isLoading =
            getCardQuery.isLoading || getCardDetailsQuery.isLoading;
        const error = getCardQuery.error || getCardDetailsQuery.error;

        if (isLoading) {
            return { isLoading: true, error: null, matchedCard: null };
        }

        if (error) {
            return { isLoading: false, error, matchedCard: null };
        }

        if (!getCardQuery.data?.card || !getCardDetailsQuery.data) {
            return { isLoading: false, error: null, matchedCard: null };
        }

        const card = getCardQuery.data.card;
        return {
            isLoading: false,
            error: null,
            matchedCard: {
                cardId: card.cardId,
                cardName: card.cardName || '',
                chatTelegramId: getCardDetailsQuery.data.telegramChatId,
                trackedLastTelegramMessageUnixTime:
                    card.trackedLastTelegramMessageUnixTime,
                createdById: card.createdById || 0,
                workspaceCardId: card.id,
                status: card.status,
            },
        };
    } catch (error) {
        console.error(error);
        return { isLoading: false, error, matchedCard: null };
    }
};
