import { Alerter } from '../../../../../utils';
import { trpc } from '@core/api/trpc';

export const useCreateReminder = () => {
    const utils = trpc.useUtils();

    return trpc.reminders.createReminder.useMutation({
        onSuccess: () => {
            Alerter.success('Reminder was successfully created!');
            utils.reminders.getCardReminders.invalidate();
        },
        onError: (error) => {
            Alerter.error(`Error creating reminder: ${error.message}`);
        },
    });
};
