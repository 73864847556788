import { useQueryClient } from '@tanstack/react-query';
import { trpc } from '../core/api/trpc';
import { UpdateCardTrackStatusPayload } from '../types';
import { Alerter } from '../utils';
import { QueryKeys } from '../constants';
import { BoardCardItemResponse } from '../features/Dashboard/types';
import { ApiResponse } from '../types';

export const useUpdateCardTrackStatus = (silent: boolean) => {
    const queryClient = useQueryClient();
    return trpc.trackStatus.updateCardTrackStatus.useMutation({
        onSuccess: (data, variables) => {
            if (data.success) {
                if (!silent) {
                    Alerter.success(
                        data?.messages?.[0]?.message ||
                            'Stage successfully changed',
                    );
                }
                queryClient.setQueryData<
                    ApiResponse<{ cards: BoardCardItemResponse[] }>
                >(
                    [QueryKeys.GET_BOARD_CARDS, variables.workspaceId],
                    (oldData) => {
                        if (oldData) {
                            const updatedCards = oldData.value.cards.map(
                                (card) => {
                                    if (card.cardId !== variables.cardId) {
                                        return card;
                                    }

                                    return {
                                        ...card,
                                        trackedLastTelegramMessageUnixTime:
                                            variables.trackedLastTelegramMessageUnixTime,
                                    };
                                },
                            );
                            return {
                                ...oldData,
                                value: {
                                    cards: updatedCards,
                                },
                            };
                        }
                        return oldData;
                    },
                );
            }
        },
    });
};
