import { ApiChat } from '../../../telegram-tt/src/api/types';
import { allowedForSyncChatTypes } from '../constants';
import { useTelegramLock } from '../services/telegram/lockContext';
import { useGetWorkspaceId } from './useGetWorkspaceId';
import { useGetAllChats, getAllChatsHistory } from './useGetAllChats';
import { trpc } from '@core/api/trpc';

export const useAutoSyncConfMode = () => {
    const lock = useTelegramLock();
    const syncChatsMutation = trpc.workspace.syncCards.useMutation();
    const workspaceId = useGetWorkspaceId();
    const { data: targetDate } = trpc.conference.getTargetDate.useQuery(
        {
            workspaceId: workspaceId,
        },
        {
            retry: false,
        },
    );

    const startSync = async () => {
        if (!targetDate) {
            console.log('No active conference found, skipping sync');
            return;
        }
        const parsedTargetDate = new Date(targetDate);

        try {
            await lock.scheduleBackgroundEvent(async (tg) => {
                if (!workspaceId || !tg) return;

                const allChats = await useGetAllChats(tg, parsedTargetDate);
                if (!allChats) return;

                console.log('All chats:', allChats);
                const chatHistories = await getAllChatsHistory(
                    tg,
                    allChats,
                    parsedTargetDate,
                );
                const chatsIds = chatHistories.map((card) => card.chatId);
                console.log('Chats ids:', chatsIds);

                const resolvedChatsInfos = await Promise.allSettled(
                    chatsIds.map((chatId: string) =>
                        tg.custom.proxy.getChatById(Number(chatId)),
                    ),
                );
                console.log('Resolved chats infos:', resolvedChatsInfos);

                const chatsByIds = resolvedChatsInfos.reduce<
                    Record<string, ApiChat>
                >((acc, chat) => {
                    if (
                        chat.status === 'fulfilled' &&
                        chat.value?.chatShortInfo &&
                        allowedForSyncChatTypes.includes(
                            chat.value.chatShortInfo.type,
                        )
                    ) {
                        acc[chat.value.chatShortInfo.id] =
                            chat.value.chatShortInfo;
                    }
                    return acc;
                }, {});

                // Create a single folder payload with all chats
                const folderPayload = {
                    telegramFolderId: 0, // Using 0 as default folder ID
                    chats: Object.values(chatsByIds).map((chat) => ({
                        telegramChatId: Number(chat.id),
                        name: chat.title || 'Unknown chat name',
                    })),
                };

                // Sync chats with the workspace
                await syncChatsMutation.mutateAsync({
                    workspaceId: workspaceId,
                    folders: [folderPayload],
                });
            });
        } catch (error) {
            console.error('Error syncing chats:', error);
            throw error; // Re-throw to handle in the component
        }
    };

    return { startSync };
};
