import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
    selectTeamChatModalOpen: boolean;
};

const initialState: InitialState = {
    selectTeamChatModalOpen: false,
};

const workspaceReducer = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setSelectTeamChatModalOpenState: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.selectTeamChatModalOpen = action.payload;
        },
    },
});

export const { setSelectTeamChatModalOpenState } = workspaceReducer.actions;

export default workspaceReducer.reducer;
