import { useQueryClient } from '@tanstack/react-query';
import { trpc } from '@core/api/trpc';
import { QueryKeys } from '../../../../../constants';

export const useUpdateLabelsStateOnCreate = () => {
    const utils = trpc.useUtils();
    const queryClient = useQueryClient();

    const updateLabelsStateOnCreate = () => {
        // Invalidate all label and card-related queries to ensure UI updates
        utils.workspace.getWorkspaceLabels.invalidate();
        utils.workspace.getWorkspaceCards.invalidate();
        utils.cards.getCardAndOwner.invalidate();
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_BOARD_CARDS],
        });
    };

    return { updateLabelsStateOnCreate };
};
