import { useMemo } from 'react';
import { Text } from '@chakra-ui/react';
import { CustomTag, Icon } from '../../../components';
import { useGetWorkspaceMembers } from '../../../queries';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';
// import { getAvatarBg } from '../../../utils';

type ChatOwnerSelectProps = {
    initialValue: string;
    onChange: (value: string | null) => void;
};

export const ChatOwnerSelect = ({
    initialValue,
    onChange,
}: ChatOwnerSelectProps) => {
    const { data, isLoading } = useGetWorkspaceMembers();
    const activeOwnerId = initialValue ? Number(initialValue) : 0;

    const selectedOwnerData = useMemo(() => {
        const matchedOwner = data?.find((el) => el.id === activeOwnerId);
        if (!matchedOwner) {
            return;
        }
        return {
            id: matchedOwner.id,
            name: `${matchedOwner.firstName} ${matchedOwner.lastName || ''}`,
            // TODO: add avatar
            // imageLink: matchedOwner.avatarThumbnailUrl,
            imageLink: '',
        };
    }, [activeOwnerId, data]);

    return (
        <FilterSelect
            title="Owner"
            items={
                data?.map((x) => {
                    return (
                        <FilterSelectItem
                            key={x.id}
                            onClick={() => onChange(x.id.toString())}
                        >
                            <CustomTag
                                label={`${x.firstName} ${x.lastName || ''}`}
                                bgColor="transparent"
                                labelColor="inherit"
                                wrapperProps={{
                                    maxW: '100%',
                                    textAlign: 'left',
                                }}
                            />
                        </FilterSelectItem>
                    );
                }) ?? []
            }
            isLoading={isLoading}
            selectedItem={
                selectedOwnerData ? (
                    <CustomTag
                        label={selectedOwnerData.name}
                        bgColor="transparent"
                        labelColor="inherit"
                        enableCloseButton={true}
                        wrapperProps={{
                            pr: '3px',
                            maxW: '160px',
                        }}
                        closeButtonProps={{
                            'aria-label': 'clear owner filter',
                            onClick: (ev) => {
                                ev.stopPropagation();
                                onChange(null);
                            },
                        }}
                    />
                ) : null
            }
            emptyItemsMessage={
                <Text color="secondary" textAlign="center">
                    No owners {data?.length ? 'anymore' : 'yet'}
                </Text>
            }
            icon={
                <Icon
                    name="user-circle"
                    height="16"
                    width="16"
                    color={'inherit'}
                />
            }
        />
    );
};
