import { trpc } from '@core/api/trpc';
import { useGetWorkspaceId } from '../../../../../hooks';

export const useGetTemplates = () => {
	const workspaceId = useGetWorkspaceId();

	return trpc.templates.getTemplates.useQuery(
		{ workspaceId },
		{
			enabled: !!workspaceId,
		}
	);
};
