import React from 'react';
import { Flex } from '@chakra-ui/react';
import { TextareaActions } from './TextareaActions';
import { Textarea } from './Textarea';

interface TextareaGroupProps {
    cardId: number;
    workspaceId?: number;
    onSendMessage?: (message: string, taggedUsers?: number[]) => void;
    isLoading?: boolean;
}

export const TextareaGroup: React.FC<TextareaGroupProps> = ({
    cardId,
    workspaceId,
    onSendMessage,
    isLoading,
}) => {
    return (
        <Flex
            borderTopWidth="1px"
            borderTopColor="#919EAB29"
            height="auto"
            px="12px"
            maxH="300px"
            transition="height 0.2s linear"
            pb="24px"
            columnGap="10px"
        >
            <Textarea
                cardId={cardId}
                workspaceId={workspaceId}
                onSendMessage={onSendMessage}
                isLoading={isLoading}
            />
            <TextareaActions cardId={cardId} workspaceId={workspaceId} />
        </Flex>
    );
};
