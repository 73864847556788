/* eslint-disable indent */
import React, { useEffect, useState, useMemo } from 'react';
import { Flex, Center, Button, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootStore } from '../../../../redux/createStore';
import {
    Message,
    Action,
    TextareaGroup,
    TasksPreview,
    ActionItems,
} from './components';
import { trpc } from '@core/api/trpc';
import { InternalMessage } from '../../types';
import { Loader, EmptyItemsText } from '../../../../components';
import { useGetCardTasks } from '../../queries';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../../constants';
import { useLocation } from 'react-router-dom';
import { SettingsIcon } from '@chakra-ui/icons';
import { useChatsHistory } from '../../../../hooks/ai/useAiChats';
import { useAtom } from 'jotai';
import { flagsAtom } from '../../../../featureFlags/store';
import { useInfiniteQuery } from '@tanstack/react-query';

interface InternalChatProps {
    cardId: number;
    workspaceId?: number;
}

interface SendMessageError {
    message: string;
}

export const InternalChat: React.FC<InternalChatProps> = ({
    cardId,
    workspaceId,
}) => {
    const [flags] = useAtom(flagsAtom);
    const { state } = useLocation();
    const trpcUtils = trpc.useUtils();
    const queryClient = useQueryClient();
    const toast = useToast();
    const { user } = useSelector((state: RootStore) => state.auth);

    const { chatTelegramId } = state || {};
    const historyQuery = useChatsHistory();

    const [arrivedMessages, setArrivedMessages] = useState<InternalMessage[]>(
        [],
    );

    // Use trpc-powered infinite query for chat messages
    const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } =
        useInfiniteQuery({
            queryKey: ['chat-messages', cardId, workspaceId],
            queryFn: async ({ pageParam = 0 }) => {
                const result =
                    await trpcUtils.client.messages.getInfiniteChatMessages.query(
                        {
                            workspaceId,
                            cardId,
                            additionalOffset: arrivedMessages.length,
                            pageParam,
                        },
                    );
                return { ...result, pageParam };
            },
            getNextPageParam: (lastPage: any) => {
                return lastPage.messages.length === 50 // PAGE_SIZE
                    ? lastPage.pageParam + 1
                    : undefined;
            },
            initialPageParam: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            staleTime: 60000, 
            enabled: !!workspaceId && !!cardId,
        });

    const { data: tasksData } = useGetCardTasks(workspaceId, cardId);

    // Send message mutation
    const sendMessageMutation = trpc.messages.sendMessage.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['chat-messages', cardId, workspaceId],
            });
        },
        onError: (error: SendMessageError) => {
            toast({
                title: 'Failed to send message',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });

    // Mark chat as read mutation
    const markChatAsReadMutation = trpc.messages.markChatAsRead.useMutation({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['chat-messages', cardId, workspaceId],
            });
        },
    });

    // AI chat analysis mutation
    const { mutate: analyzeChat, isPending: isAnalyzingChat } =
        trpc.aiChats.analyzeChat.useMutation({
            onMutate: () => {
                trpcUtils.aiChats.getActions.invalidate();
                toast({
                    title: 'Analysis started',
                    description:
                        'The AI is analyzing your chat history. A summary will appear shortly.',
                    status: 'info',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onSuccess: () => {
                toast({
                    title: 'Analysis completed',
                    description: 'The AI has analyzed your chat history.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onError: (error) => {
                toast({
                    title: 'Analysis failed',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            },
            onSettled: () => {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
                });
                queryClient.invalidateQueries({
                    queryKey: ['chat-messages', cardId, workspaceId],
                });
                trpcUtils.aiChats.getActions.invalidate();
            },
        });

    // Mark chat as read when the component mounts
    useEffect(() => {
        if (workspaceId && cardId) {
            markChatAsReadMutation.mutate({
                workspaceId: Number(workspaceId),
                cardId: Number(cardId),
            });
        }
    }, [workspaceId, cardId]);

    const combinedWithIncomingRows = useMemo(
        () => [
            ...arrivedMessages,
            ...(data?.pages?.flatMap((page: any) => page?.messages || []) ||
                []),
        ],
        [arrivedMessages, data?.pages],
    );
    console.log(combinedWithIncomingRows, 'combinedWithIncomingRows');

    const handleAnalyzeChat = () => {
        if (!chatTelegramId || !historyQuery.data) {
            toast({
                title: 'Cannot analyze chat',
                description: 'No Telegram chat history available',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const historyText = historyQuery.data[chatTelegramId.toString()];
        if (!historyText) {
            toast({
                title: 'Cannot analyze chat',
                description: 'No chat history found for this chat',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        analyzeChat({
            chatId: cardId.toString(),
            userId: user.id.toString(),
            historyText,
            workspaceId: workspaceId?.toString() || '',
            cardId: cardId.toString(),
        });
    };

    // Function to handle sending messages
    const handleSendMessage = (message: string, taggedUsers: number[] = []) => {
        if (!workspaceId || !cardId || !message.trim()) return;

        // Ensure taggedUsers is an array of numbers
        const cleanedTaggedUsers = Array.isArray(taggedUsers)
            ? taggedUsers
            : [];

        // Log the values being sent for debugging
        console.log('Sending message with params:', {
            workspaceId: Number(workspaceId),
            cardId: Number(cardId),
            text: message,
            taggedUsers: cleanedTaggedUsers,
        });

        sendMessageMutation.mutate({
            workspaceId: Number(workspaceId),
            cardId: Number(cardId),
            text: message,
            taggedUsers: cleanedTaggedUsers,
        });
    };

    // Fix the linter error by correctly accessing tasksData
    const calculatedHeight = useMemo(() => {
        // Access task length safely with a fallback
        const taskLength = Array.isArray(tasksData) ? tasksData.length : 0;
        return `calc(100vh - 180px - ${taskLength > 3 ? '150px' : `${taskLength * 50}px`})`;
    }, [tasksData]);

    const memoizedMessages = useMemo(() => {
        return combinedWithIncomingRows.map((elem) => {
            if (elem.messageType === 2 && elem.activity) {
                return (
                    <Action
                        key={elem.id}
                        cardId={cardId}
                        workspaceId={workspaceId || 0}
                        messageId={elem.id}
                        currentUserId={user.id}
                        isAdmin={user.workSpace?.isAdmin}
                        {...elem.activity}
                    />
                );
            } else {
                return (
                    <Message
                        key={elem.id}
                        {...elem}
                        isMine={user.id === elem.senderId}
                        cardId={cardId}
                        workspaceId={workspaceId || 0}
                        chatTelegramId={chatTelegramId || 0}
                        userTelegramId={user.telegramUserId}
                    />
                );
            }
        });
    }, [
        combinedWithIncomingRows,
        cardId,
        workspaceId,
        user.id,
        user.workSpace?.isAdmin,
        user.telegramUserId,
        chatTelegramId,
    ]);

    return (
        <Flex flexDirection="column" height="100%" flex={1} position="relative">
            {flags.ai_actions && (
                <Flex justifyContent="flex-end" p={4}>
                    <Button
                        colorScheme="blue"
                        size="sm"
                        onClick={handleAnalyzeChat}
                        leftIcon={<SettingsIcon />}
                        isLoading={isAnalyzingChat}
                        isDisabled={
                            !historyQuery.data?.[chatTelegramId?.toString()]
                        }
                    >
                        Analyze Chat
                    </Button>
                </Flex>
            )}
            <TasksPreview cardId={cardId} workspaceId={workspaceId} />
            {workspaceId && flags.ai_actions && (
                <ActionItems cardId={cardId} workspaceId={workspaceId || 0} />
            )}
            {combinedWithIncomingRows.length ? (
                <Flex
                    id="messages-section"
                    overflowY="auto"
                    h="100%"
                    direction="column-reverse"
                    minH="240px"
                    sx={{
                        scrollbarWidth: 'thin',
                    }}
                >
                    <InfiniteScroll
                        dataLength={combinedWithIncomingRows.length}
                        refreshFunction={fetchNextPage}
                        next={fetchNextPage}
                        hasMore={hasNextPage}
                        loader={null}
                        scrollableTarget="messages-section"
                        inverse={true}
                        scrollThreshold={200}
                        style={{
                            display: 'flex',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                            paddingLeft: '24px',
                            paddingRight: '24px',
                            gap: '20px',
                            flexDirection: 'column-reverse',
                        }}
                    >
                        {isPending && !isFetchingNextPage ? (
                            <Loader
                                centerHeight={calculatedHeight}
                                centerProps={{ flexShrink: 0 }}
                            />
                        ) : (
                            <>
                                {memoizedMessages}
                                {isFetchingNextPage ? (
                                    <Loader
                                        centerHeight="80px"
                                        spinnerSize="sm"
                                    />
                                ) : null}
                            </>
                        )}
                    </InfiniteScroll>
                </Flex>
            ) : (
                <Center height={calculatedHeight} flex={1}>
                    <EmptyItemsText
                        text="No messages yet"
                        wrapperProps={{ flex: 0.8 }}
                    />
                </Center>
            )}
            <TextareaGroup
                cardId={cardId}
                workspaceId={workspaceId}
                onSendMessage={handleSendMessage}
                isLoading={sendMessageMutation.isPending}
            />
        </Flex>
    );
};
