/* eslint-disable indent */

import { BoardCardItemResponse } from '../features/Dashboard/types';
import { useChats } from '../hooks/useChats';
import { CardDefaultStatusEnum, LabelItemType } from '../types';

interface BaseCardsPayload {
    cards: BoardCardItemResponse[];
    statusId?: number;
}

interface UnreadCardsPayload extends BaseCardsPayload {
    chats: ReturnType<typeof useChats>;
}

interface UnansweredCardsPayload extends UnreadCardsPayload {
    teamTelegramIds: number[];
}

const isNotArchived = (card: BoardCardItemResponse) =>
    card.status.typeId !== CardDefaultStatusEnum.ARCHIVE;

const filterByStatus = (card: BoardCardItemResponse, statusId?: number) =>
    statusId === undefined || card.status.id === statusId;

export const getUnreadCards = ({
    cards,
    chats,
    statusId,
}: UnreadCardsPayload) => {
    if (!cards.length || !chats) return [];

    return cards.filter((card) => {
        if (
            !isNotArchived(card) ||
            (statusId !== undefined && card.status.id !== statusId)
        ) {
            return false;
        }

        const tgChat = chats.getChatInfo(card.chatTelegramId.toString());
        return !!tgChat?.unreadCount;
    });
};

export const getUnansweredCards = ({
    cards,
    chats,
    teamTelegramIds,
    statusId,
}: UnansweredCardsPayload) => {
    if (!cards.length || !chats || !teamTelegramIds.length) return [];

    const teamIdSet = new Set(teamTelegramIds);

    return cards.filter((card) => {
        if (
            !isNotArchived(card) ||
            (statusId !== undefined && card.status.id !== statusId)
        ) {
            return false;
        }

        const tgChat = chats.getChatInfo(card.chatTelegramId.toString());
        if (!tgChat) return false;

        const senderId = +(tgChat?.lastMessage.sender?.id ?? 0);
        const isLastMessageFromTeam = teamIdSet.has(senderId);

        return (
            !isLastMessageFromTeam &&
            card.trackedLastTelegramMessageUnixTime !== tgChat.lastMessage.date
        );
    });
};

export const getMentionedCards = ({ cards, statusId }: BaseCardsPayload) => {
    if (!cards.length) return [];

    return cards.filter(
        (card) =>
            isNotArchived(card) &&
            (statusId === undefined || card.status.id === statusId) &&
            card.currentUserTagged &&
            !!card.message?.unreadCount,
    );
};

export const getActiveTasksCards = ({ cards, statusId }: BaseCardsPayload) => {
    if (!cards.length) return [];

    return cards.filter(
        (card) =>
            isNotArchived(card) &&
            (statusId === undefined || card.status.id === statusId) &&
            !!card.tasksCount,
    );
};

export const getTeamActivityCards = ({ cards, statusId }: BaseCardsPayload) => {
    if (!cards.length) return [];

    return cards.filter(
        (card) =>
            isNotArchived(card) &&
            (statusId === undefined || card.status.id === statusId) &&
            !!card.message?.unreadCount,
    );
};
