import React, {
    useState,
    useCallback,
    useMemo,
    useRef,
    useEffect,
} from 'react';
import { useCombobox } from 'downshift';
import {
    Box,
    Flex,
    List,
    Text,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Center,
    Spinner,
} from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '../Icon/Icon';
import { getCtrlKeyName } from '../../utils';
import { Command } from './Command';
import { BoardCardItemResponse } from '../../features/Dashboard/types';
import { colors } from '../../theme/colors';
import { useGetBoardCards } from '../../features/Dashboard/queries';
import { useGetWorkspaceId } from '../../hooks';
import { useChats } from '../../hooks/useChats';
import { useRecentSearches, useSearch } from './hooks';
import { VirtualizedList } from './VirtualizedList';

// Constants
const COMMAND_ITEM_HEIGHT = 42; // Approximate height of each command item in pixels
const MAX_LIST_HEIGHT = 300;

// Memoized overlay component to prevent unnecessary re-renders
const SearchOverlay = React.memo(
    ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
        <AnimatePresence mode="wait">
            {isOpen && (
                <Box
                    as={motion.div}
                    display="flex"
                    key="search-overlay"
                    flexDirection="column"
                    alignItems="center"
                    overflow="hidden"
                    position="fixed"
                    top="0"
                    left="0"
                    zIndex="50"
                    width="100vw"
                    height="100vh"
                    userSelect="none"
                    backgroundColor={'#0000004D'}
                    initial={{ opacity: 0, pointerEvents: 'none' }}
                    animate={{ opacity: 1, pointerEvents: 'auto' }}
                    exit={{ opacity: 0, pointerEvents: 'none' }}
                    transition={{ duration: '0.2s' }}
                    onClick={onClose}
                />
            )}
        </AnimatePresence>
    ),
);

// Memoized header text component
const HeaderText = React.memo(
    ({ isSearchResults }: { isSearchResults: boolean }) => (
        <Text
            textTransform="uppercase"
            color="gray.35"
            fontSize="11px"
            py="8px"
            pr="4px"
            pl="10px"
            fontWeight={600}
        >
            {isSearchResults ? 'Chats' : 'Recent'}
        </Text>
    ),
);

// Memoized empty results component
const EmptyResults = React.memo(() => (
    <Center py="30px">
        <Text color="gray.35" fontSize="12px">
            No search results
        </Text>
    </Center>
));

// Memoized loading indicator component
const LoadingIndicator = React.memo(() => (
    <Center py="30px">
        <Spinner size="sm" color="gray.35" />
    </Center>
));

// Memoized search input component to prevent re-renders during typing
const SearchInput = React.memo(
    ({
        getInputProps,
        isSearching,
        inputValue,
        inputRef,
    }: {
        getInputProps: any;
        isSearching: boolean;
        inputValue: string;
        inputRef: React.RefObject<HTMLInputElement>;
    }) => {
        const handleFocus = useCallback(() => {
            const props = getInputProps({
                onFocus: () => {
                    // This will be handled by the parent component
                },
            });
            return props.onFocus;
        }, [getInputProps]);

        const handleBlur = useCallback(() => {
            const props = getInputProps({
                onBlur: () => {
                    // This will be handled by the parent component
                },
            });
            return props.onBlur;
        }, [getInputProps]);

        const handleKeyDown = useCallback(
            (event: React.KeyboardEvent) => {
                if (event.key === 'Home' || event.key === 'End') {
                    (event.nativeEvent as any).preventDownshiftDefault = true;
                }
                const props = getInputProps({
                    onKeyDown: () => {},
                });
                if (props.onKeyDown) {
                    props.onKeyDown(event);
                }
            },
            [getInputProps],
        );

        const inputProps = getInputProps({
            onFocus: handleFocus,
            onBlur: handleBlur,
            onKeyDown: handleKeyDown,
            ref: inputRef,
        });

        return (
            <InputGroup>
                <InputLeftElement height="100%" w="16px" minW="16px" ml="8px">
                    {isSearching && inputValue ? (
                        <Spinner size="xs" color={colors.gray[35]} />
                    ) : (
                        <Icon
                            name="search"
                            width="16px"
                            height="16px"
                            color={colors.gray[35]}
                        />
                    )}
                </InputLeftElement>
                <Input
                    {...inputProps}
                    placeholder="Search"
                    height="28px"
                    border="none"
                    borderRadius="0"
                    fontSize="13px"
                    borderBottom="1px solid"
                    pl="32px"
                    borderBottomColor="gray.20"
                    color="primary"
                    fontWeight={400}
                    _hover={{
                        borderBottomColor: 'gray.25',
                    }}
                    _placeholder={{ color: 'gray.35' }}
                    _focusVisible={{ outline: 'none' }}
                    spellCheck={false}
                    autoComplete="off"
                    autoCorrect="off"
                />
                <InputRightElement height="100%">
                    <Text fontSize="13px" fontWeight={400} color="gray.35">
                        {getCtrlKeyName()}+/
                    </Text>
                </InputRightElement>
            </InputGroup>
        );
    },
);

// Memoized results list component
const ResultsList = React.memo(
    ({
        suggestions,
        listHeight,
        renderCommandItem,
        getMenuProps,
    }: {
        suggestions: BoardCardItemResponse[];
        listHeight: number;
        renderCommandItem: (
            item: BoardCardItemResponse,
            index: number,
        ) => JSX.Element;
        getMenuProps: any;
    }) => (
        <div {...getMenuProps({}, { suppressRefError: true })}>
            <List spacing={0} styleType="none" width="100%">
                <VirtualizedList
                    items={suggestions}
                    height={listHeight}
                    itemHeight={COMMAND_ITEM_HEIGHT}
                    renderItem={renderCommandItem}
                    overscan={10}
                />
            </List>
        </div>
    ),
);

export const CommandMenu: React.FC = () => {
    const navigate = useNavigate();
    const workspaceId = useGetWorkspaceId();
    const { data: cardsData } = useGetBoardCards();
    const { recentItems, addRecentItem } = useRecentSearches();
    const chats = useChats();
    const location = useLocation();

    // UI state
    const [isOpen, setIsOpen] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    // Refs for performance optimization
    const inputRef = useRef<HTMLInputElement>(null);
    const modalStateRef = useRef({ isOpen, isAnimating });

    // Update ref when state changes
    useEffect(() => {
        modalStateRef.current = { isOpen, isAnimating };
    }, [isOpen, isAnimating]);

    // Use the search hook with isSearching state
    const { searchResults, setSearchQuery, isSearching } = useSearch(
        cardsData,
        chats,
    );

    const handleSelectedItemChange = useCallback(
        (item: BoardCardItemResponse) => {
            navigate(`/${workspaceId}/chat/${item.cardId}`, {
                state: {
                    chatTelegramId: item.chatTelegramId,
                    statusId: item.status.id,
                },
            });

            // Add to recent searches
            addRecentItem(item);

            // Close the menu
            setIsOpen(false);
        },
        [navigate, workspaceId, addRecentItem],
    );

    const {
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        openMenu,
        inputValue,
    } = useCombobox({
        items: searchResults.length ? searchResults : recentItems,
        onSelectedItemChange: (changes) => {
            if (changes.selectedItem) {
                handleSelectedItemChange(changes.selectedItem);
            }
        },
        onIsOpenChange: (changes) => {
            if (changes.type === '__input_click__' && inputFocused) {
                return;
            }
            setIsOpen(changes.isOpen || false);
        },
        onInputValueChange: ({ inputValue }) => {
            setSearchQuery(inputValue || '');
        },
        onStateChange: (changes) => {
            if (changes.type === '__input_keydown_escape__') {
                document.activeElement instanceof HTMLElement &&
                    document.activeElement.blur();
                setIsOpen(false);
            }
        },
        itemToString: (item) => item?.cardName || '',
    });

    // Handle closing the menu with debounce to prevent rapid open/close cycles
    const handleClose = useCallback(() => {
        if (modalStateRef.current.isAnimating) return;
        setIsAnimating(true);
        setIsOpen(false);
        // Reset animation state after animation completes
        setTimeout(() => {
            setIsAnimating(false);
        }, 300);
    }, []);

    // Close the modal when location changes (tab navigation)
    useEffect(() => {
        if (isOpen) {
            handleClose();
        }
    }, [location.pathname, isOpen, handleClose]);

    // Handle input focus with debounce
    const handleInputFocus = useCallback(() => {
        if (modalStateRef.current.isAnimating) return;
        setInputFocused(true);
        setIsAnimating(true);
        setIsOpen(true);
        // Reset animation state after animation completes
        setTimeout(() => {
            setIsAnimating(false);
        }, 300);
    }, []);

    // Handle input blur
    const handleInputBlur = useCallback(() => {
        setInputFocused(false);
    }, []);

    // Determine which items to show
    const suggestions = useMemo(() => {
        if (searchResults.length) {
            return searchResults;
        }
        if (inputValue && recentItems.length) {
            return [];
        }
        return recentItems;
    }, [searchResults, inputValue, recentItems]);

    // Memoized list height calculation
    const listHeight = useMemo(
        () =>
            Math.min(suggestions.length * COMMAND_ITEM_HEIGHT, MAX_LIST_HEIGHT),
        [suggestions.length],
    );

    // Render function for virtualized list items
    const renderCommandItem = useCallback(
        (item: BoardCardItemResponse, index: number) => (
            <Command
                key={`${item.cardId}-${index}`}
                isHighlighted={highlightedIndex === index}
                {...getItemProps({
                    item,
                    index,
                })}
                data={item}
            />
        ),
        [highlightedIndex, getItemProps],
    );

    // Determine if we should show loading state
    const showLoading = isSearching && inputValue && !searchResults.length;

    // Keyboard shortcut to open the menu
    useHotkeys(
        ['meta+/', 'ctrl+/'],
        () => {
            if (modalStateRef.current.isAnimating) return;
            setIsAnimating(true);
            openMenu();
            setIsOpen(true);
            // Focus the input when opened via keyboard shortcut
            setTimeout(() => {
                inputRef.current?.focus();
                setIsAnimating(false);
            }, 300);
        },
        { enableOnFormTags: true },
    );

    return (
        <>
            <SearchOverlay isOpen={isOpen} onClose={handleClose} />
            <Box
                bg={isOpen ? 'gray.10' : undefined}
                pb={'2px'}
                borderTopRadius="8px"
                width="100%"
                maxW="368px"
                border="1px solid"
                borderColor={isOpen ? 'gray.15' : 'transparent'}
                borderBottomColor="transparent"
                borderBottom={isOpen ? '0' : undefined}
                zIndex={55}
                boxShadow={isOpen ? '2px 4px 16px 0px #0000001F' : undefined}
                position="relative"
                onClick={(e) => e.stopPropagation()}
            >
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignSelf="center"
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                >
                    <SearchInput
                        getInputProps={getInputProps}
                        isSearching={isSearching}
                        inputValue={inputValue}
                        inputRef={inputRef}
                    />

                    {/* Hidden menu element that's always in the DOM to satisfy Downshift */}
                    <Box display="none">
                        <List
                            {...getMenuProps({}, { suppressRefError: true })}
                        />
                    </Box>
                    <AnimatePresence mode="wait">
                        {isOpen && (
                            <Box
                                as={motion.div}
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: '0.2s' }}
                                w="calc(100% + 2px)"
                                position="absolute"
                                border="1px solid"
                                borderColor="gray.15"
                                borderTop="0"
                                top="28px"
                                left="-1px"
                                bg="gray.10"
                                borderBottomRadius="8px"
                                p="4px"
                            >
                                {suggestions.length > 0 && !showLoading && (
                                    <HeaderText
                                        isSearchResults={
                                            searchResults.length > 0
                                        }
                                    />
                                )}

                                {showLoading ? (
                                    <LoadingIndicator />
                                ) : suggestions.length > 0 ? (
                                    <ResultsList
                                        suggestions={suggestions}
                                        listHeight={listHeight}
                                        renderCommandItem={renderCommandItem}
                                        getMenuProps={getMenuProps}
                                    />
                                ) : inputValue ? (
                                    <EmptyResults />
                                ) : null}
                            </Box>
                        )}
                    </AnimatePresence>
                </Flex>
            </Box>
        </>
    );
};
