import { MenuItemProps } from './types';

export const mockWorkspaces: MenuItemProps[] = [
  {
    icon: '🏢',
    name: 'TechConf 2024',
    isCompany: true,
  },
  {
    icon: '🎯',
    name: 'Marketing Summit',
    isCompany: true,
  },
  {
    icon: '💡',
    name: 'Innovation Forum',
    isCompany: false,
  },
  {
    icon: '🌐',
    name: 'Global Connect',
    isCompany: true,
  },
  
];

export const mockHandlers = {
  onStartCollecting: () => {
    console.log('Start collecting leads');
  },
}; 