import React from 'react';
import { Box, Flex, IconButton, Text, useDisclosure } from '@chakra-ui/react';
import parse, { domToReact } from 'html-react-parser';
import { Template as TemplateType } from '../../../../../types';
import { ActionsMenu, Icon } from '../../../../../components';
import { TemplatePopup } from './TemplatePopup';
import { trpc } from '@core/api/trpc';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';

interface ITemplateProps extends Omit<TemplateType, 'content'> {
    onContentClick?: VoidFunction;
    content: string | null;
    isTeamTemplate?: boolean;
}

export const Template: React.FC<ITemplateProps> = ({
    content,
    id,
    onContentClick,
    isTeamTemplate,
}) => {
    const {
        onToggle: onToggleTemplatePopup,
        isOpen: isTemplatePopupOpen,
        onClose: onCloseTemplatePopup,
    } = useDisclosure();
    const workspaceId = useGetWorkspaceId();
    const utils = trpc.useUtils();
    const deleteTemplateMutation = trpc.templates.deleteTemplate.useMutation({
        onMutate: async ({ templateId }) => {
            // Cancel any outgoing refetches
            await utils.templates.getTemplates.cancel();

            // Snapshot the previous value
            const previousTemplates = utils.templates.getTemplates.getData({
                workspaceId,
            });

            // Optimistically update to the new value
            utils.templates.getTemplates.setData({ workspaceId }, (old) => {
                if (!old) return old;
                const newTemplates = old.data.filter(
                    (t) => t.id !== templateId,
                );
                return {
                    ...old,
                    data: newTemplates,
                };
            });

            // Return a context object with the snapshotted value
            return { previousTemplates };
        },
        onError: (err, variables, context) => {
            // If the mutation fails, use the context returned from onMutate to roll back
            if (context?.previousTemplates) {
                utils.templates.getTemplates.setData(
                    { workspaceId },
                    context.previousTemplates,
                );
            }
            Alerter.error('Failed to delete template');
        },
        onSuccess(data) {
            if (data.success) {
                if (isTemplatePopupOpen) {
                    onCloseTemplatePopup();
                }
                Alerter.success('Template successfully deleted');
            }
        },
        onSettled: () => {
            // Always refetch after error or success to make sure our optimistic update is correct
            utils.templates.getTemplates.invalidate({ workspaceId });
        },
    });

    const onDeleteTemplate = () => {
        deleteTemplateMutation.mutate({ templateId: id });
    };

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
        e.dataTransfer.setData('text/plain', id.toString());
        e.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Flex
            px={4}
            py={3}
            alignItems="center"
            justifyContent="space-between"
            _last={{ borderBottomWidth: 0 }}
            borderBottomWidth="1px"
            borderBottomColor="gray.15"
            position="relative"
            overflow="hidden"
            draggable={!isTeamTemplate}
            onDragStart={handleDragStart}
            _hover={{
                '.actions-menu': {
                    opacity: 1,
                    visibility: 'visible',
                    transform: 'translateY(-50%) translateX(0)',
                },
                '.dnd-icon': {
                    opacity: 1,
                    visibility: 'visible',
                    transform: 'translateX(0)',
                },
            }}
        >
            <Box
                className="dnd-icon"
                position="absolute"
                left="0"
                top="0"
                bottom="0"
                width="24px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                opacity={0}
                visibility="hidden"
                transform="translateX(-100%)"
                transition="all 0.2s"
                background="linear-gradient(to right, white 0%, white 80%, rgba(255, 255, 255, 0) 100%)"
                zIndex={1}
            >
                <Icon name="dnd" height="16" width="16" color="gray.35" />
            </Box>
            <Box width="100%" onClick={onContentClick}>
                <Text
                    color="primary"
                    fontSize="13px"
                    fontWeight="500"
                    textAlign="left"
                    as="button"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    noOfLines={1}
                    sx={{
                        '& *': { display: 'inline !important' },
                        a: { textDecoration: 'underline' },
                    }}
                >
                    {parse((content || '').replace(/(\r\n|\n|\r)/g, ' '), {
                        replace: (domNode) => {
                            if (
                                (domNode as any)?.type === 'text' &&
                                (domNode as any).data
                            ) {
                                return (domNode as any).data.replace(
                                    /(\r\n|\n|\r)/g,
                                    ' ',
                                );
                            }
                            if ((domNode as any)?.name) {
                                const nodeName = (
                                    domNode as any
                                ).name.toLowerCase();
                                if (nodeName === 'br') {
                                    return ' ';
                                }
                                const blockTags = [
                                    'p',
                                    'div',
                                    'section',
                                    'article',
                                    'header',
                                    'footer',
                                    'ul',
                                    'ol',
                                    'li',
                                    'pre',
                                ];
                                if (blockTags.includes(nodeName)) {
                                    return (
                                        <span
                                            style={{
                                                display: 'inline',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {domToReact(
                                                (domNode as any).children,
                                            )}
                                        </span>
                                    );
                                }
                            }
                            return domNode;
                        },
                    })}
                </Text>
            </Box>
            <ActionsMenu
                isOpen={isTemplatePopupOpen}
                includeDelete={true}
                deleteButtonProps={{
                    onClick: onDeleteTemplate,
                    isLoading: deleteTemplateMutation.isPending,
                }}
            >
                <TemplatePopup
                    isOpen={isTemplatePopupOpen}
                    onClose={onCloseTemplatePopup}
                    onToggle={onToggleTemplatePopup}
                    template={content || ''}
                    templateId={id}
                >
                    <IconButton
                        aria-label="edit template"
                        minW="auto"
                        variant="ghost"
                        boxSize="24px"
                        borderRadius="4px"
                        onClick={onToggleTemplatePopup}
                    >
                        <Icon name="edit" height="16" width="16" />
                    </IconButton>
                </TemplatePopup>
            </ActionsMenu>
        </Flex>
    );
};
