import { ApiUser } from '../../../telegram-tt/src/api/types';
import { colors } from '../theme/colors';
import {
    CardTaskPriorityEnum,
    BaseUserData,
    CardDefaultStatusEnum,
} from '../types';
import { PipelineDefaultStages } from '../constants/pipeline-default-stages';

// Map standard status names to their positions for color mapping
const STATUS_NAME_TO_POSITION: { [key: string]: number } = {
    Lead: 1,
    Contacted: 2,
    Negotiation: 3,
    'Ready to start': 4,
    Closed: 5,
    Archive: 6,
};

export const getTaskStatusByType = ({
    position,
    typeId,
    name,
}: {
    position: number;
    typeId: number;
    name?: string;
}) => {
    // If we have a standard name, use its position for color mapping
    if (name && STATUS_NAME_TO_POSITION[name]) {
        position = STATUS_NAME_TO_POSITION[name];
    }

    if (typeId === CardDefaultStatusEnum.ARCHIVE) {
        return {
            textColor: 'gray.60',
            bgColor: 'gray.20',
        };
    }

    // Use PipelineDefaultStages as source of truth for colors
    // Position is 1-based, array is 0-based
    if (position >= 1 && position <= PipelineDefaultStages.length) {
        const stage = PipelineDefaultStages[position - 1];
        return {
            title: stage.name,
            textColor: stage.textColor,
            bgColor: stage.color,
        };
    }

    // Fallback for positions outside the defined range
    return {
        textColor: 'gray.60',
        bgColor: 'gray.20',
    };
};

export const getPriorityStatusById = (type: CardTaskPriorityEnum) => {
    switch (type) {
        case CardTaskPriorityEnum.LOW:
            return {
                title: 'Low',
                textColor: 'yellow.60',
                bgColor: 'yellow.20',
            };
        case CardTaskPriorityEnum.MEDIUM:
            return {
                title: 'Medium',
                textColor: 'orange.60',
                bgColor: 'orange.20',
            };
        case CardTaskPriorityEnum.HIGH:
            return {
                title: 'High',
                textColor: 'red.60',
                bgColor: 'red.20',
            };
        default:
            return {
                title: 'Unknown',
                textColor: 'gray.60',
                bgColor: 'gray.20',
            };
    }
};

export const getActivityIconByTypeId = (typeId: number) => {
    switch (typeId) {
        case 1:
            return 'video';
        case 2:
            return 'phone';
        case 3:
            return 'video-off';
        case 4:
            return 'note';
        case 5:
        case 6:
            return 'checkbox';
        default:
            return '';
    }
};

export const getLabelInfoByType = (typeId: number) => {
    switch (typeId) {
        case 1:
            return {
                textColor: colors.green[60],
                bgColor: colors.green[20],
            };
        case 2:
            return {
                textColor: colors.turquoise[60],
                bgColor: colors.turquoise[20],
            };
        case 3:
            return {
                textColor: colors.sky[60],
                bgColor: colors.sky[20],
            };
        case 4:
            return {
                textColor: colors.blue[60],
                bgColor: colors.blue[20],
            };
        case 5:
            return {
                textColor: colors.purple[60],
                bgColor: colors.purple[20],
            };
        case 6:
            return {
                textColor: colors.pink[60],
                bgColor: colors.pink[20],
            };

        default:
            return {
                textColor: colors.gray[60],
                bgColor: colors.gray[20],
            };
    }
};

export const getActivitySubjectName = (
    subjectInfo: { firstName?: string; lastName?: string } | null,
) =>
    [subjectInfo?.firstName, subjectInfo?.lastName].filter(Boolean).join(' ') ||
    '';
