import { useAtom } from 'jotai';
import { trpc } from '@core/api/trpc';
import { cardTitlesAtom } from '../atoms/cardTitlesAtom';
import { useEffect } from 'react';

export const useWorkspaceCardTitles = (workspaceId?: number) => {
    const [cardTitles, setCardTitles] = useAtom(cardTitlesAtom);
    
    const { data } = trpc.cards.getAllTitlesByWorkspaceId.useQuery(
        { workspaceId: workspaceId || 0 },
        { enabled: !!workspaceId }
    );

    useEffect(() => {
        if (data?.length && workspaceId) {
            const titlesMap = data.reduce((acc, card) => ({
                ...acc,
                [card.cardId]: card.chatTitle || ''
            }), {});
            setCardTitles(titlesMap);
        }
    }, [data, workspaceId, setCardTitles]);

    return cardTitles;
}; 