import React from 'react';
import { Center, CenterProps, Spinner, SpinnerProps } from '@chakra-ui/react';

interface ILoaderProps {
    spinnerSize?: string;
    centerHeight?: string;
    centerProps?: CenterProps;
    spinnerProps?: SpinnerProps;
}

export const Loader: React.FC<ILoaderProps> = ({
    spinnerSize = 'xl',
    centerHeight,
    centerProps,
    spinnerProps,
}) => (
    <Center h={centerHeight} w="100%" {...centerProps}>
        <Spinner
            thickness="4px"
            speed="1s"
            emptyColor="gray.30"
            color="primary"
            size={spinnerSize}
            {...spinnerProps}
        />
    </Center>
);
