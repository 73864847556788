import React, { ReactNode } from 'react';
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalContentProps,
    ModalHeader,
    ModalOverlay,
    ModalBodyProps,
} from '@chakra-ui/react';

interface ModalWrapperProps {
    isOpen: boolean;
    onClose?: VoidFunction;
    modalContentProps?: ModalContentProps;
    modalBodyProps?: ModalBodyProps;
    modalTitle?: string;
    shouldClose?: boolean;
    children?: ReactNode;
    size?: string;
}

export const ModalWrapper: React.FC<ModalWrapperProps> = ({
    onClose,
    isOpen,
    modalTitle,
    shouldClose = true,
    children,
    modalContentProps = {},
    modalBodyProps,
    size = 'md',
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose?.()}
            size={size}
            isCentered={true}
            closeOnOverlayClick={shouldClose}
            autoFocus={false}
            closeOnEsc={shouldClose}
        >
            <ModalOverlay />
            <ModalContent
                p={'35px'}
                borderRadius={'8px'}
                {...modalContentProps}
            >
                {modalTitle ? <ModalHeader>{modalTitle}</ModalHeader> : null}
                {shouldClose ? <ModalCloseButton /> : null}
                <ModalBody {...modalBodyProps}>{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
