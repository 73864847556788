import { useEffect, useCallback, memo } from 'react';
import { useAtom } from 'jotai';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useSteps,
} from '@chakra-ui/react';

import { batchSendingModalAtom } from '../../atoms/modals.atoms';
import {
    enteredMessageAtom,
    selectedCardsAtom,
} from '../../features/batch-sending/state';
import { useUnsavedChangesChecker } from '../../hooks';

import BatchSendingSelectCards from '../../features/batch-sending/select-cards';
import BatchSendingEnterMessage from '../../features/batch-sending/enter-message';
import BatchSendingLaunchProcess from '../../features/batch-sending/launch-process';

import css from './batch-sending.module.css';

const steps = [
    { key: 'selector', Content: BatchSendingSelectCards },
    { key: 'message', Content: BatchSendingEnterMessage },
    { key: 'process', Content: BatchSendingLaunchProcess },
];

export const BatchSendingModal = memo(() => {
    const [selectedCards, setSelectedCards] = useAtom(selectedCardsAtom);
    const [enteredMessage, setEnteredMessage] = useAtom(enteredMessageAtom);
    const [{ isOpen, initialStep }, setBatchSendingModal] = useAtom(
        batchSendingModalAtom,
    );

    useUnsavedChangesChecker(!!selectedCards.length || !!enteredMessage.length);

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: initialStep || 0,
        count: steps.length,
    });

    // Update active step when initialStep changes
    useEffect(() => {
        if (initialStep !== undefined) {
            setActiveStep(initialStep);
        }
    }, [initialStep, setActiveStep]);

    const currentStep = steps[activeStep];
    const CurrentStepComponent = currentStep.Content;

    const handleCancel = useCallback(() => {
        setBatchSendingModal({ isOpen: false, initialStep: 0 });
        setSelectedCards([]);
        setEnteredMessage('');
    }, [setBatchSendingModal, setSelectedCards, setEnteredMessage]);

    // Don't render anything if the modal is not open
    if (!isOpen) return null;

    return (
        <Modal isOpen={isOpen} isCentered={true} onClose={handleCancel}>
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalHeader className={css.header}>
                    <h2 className={css.title}>Batch Send</h2>

                    <span className={css.stepsCount}>
                        Step {activeStep + 1}/3
                    </span>
                </ModalHeader>

                <ModalBody className={css.body}>
                    <CurrentStepComponent
                        key={currentStep.key}
                        goToNext={goToNext}
                        goToPrevious={goToPrevious}
                        cancel={handleCancel}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
});
