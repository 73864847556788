import {
    Text,
    Menu,
    MenuButton,
    MenuList,
    Button,
    MenuGroup,
    HStack,
    Skeleton,
} from '@chakra-ui/react';
import { WorkspaceSelectItem } from './WorkspaceSelectItem';
import { WorkspaceItemImage } from './WorkspaceItemImage';
import { AddWorkspaceButton } from './AddWorkspaceButton';
import { IconChevronDown } from '@tabler/icons-react';
import { useGetCurrentUserWorkspacesQuery } from '@core/api';
import { useParams } from '@router';

export const WorkspaceSelect = () => {
    const { workspaceId } = useParams('/:workspaceId');

    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

    if (getCurrentUserWorkspacesQuery.isError) return <>Error</>;

    if (getCurrentUserWorkspacesQuery.isLoading) {
        return (
            <Button
                background="transparent"
                color="primary"
                height="auto"
                borderRadius="4px"
                p="2px 2px 2px 4px"
                _hover={{ bg: 'transparentLight.5' }}
            >
                <HStack spacing={2}>
                    <Skeleton width="24px" height="24px" borderRadius="4px" />
                    <Skeleton width="120px" height="20px" />
                </HStack>
            </Button>
        );
    }

    if (!getCurrentUserWorkspacesQuery.data?.length) return <>Empty</>;

    const currentWorkspace = getCurrentUserWorkspacesQuery.data.find(
        ({ id }) => String(id) === workspaceId,
    );

    if (!currentWorkspace) return <>Empty</>;

    return (
        <Menu
            autoSelect={false}
            styleConfig={{
                '& [role="menu"]': {
                    width: '200px !important',
                },
                '& .chakra-portal': {
                    '& > *': {
                        width: '200px !important',
                    },
                },
            }}
        >
            <MenuButton
                as={Button}
                rightIcon={<IconChevronDown size={'1rem'} />}
                background="transparent"
                color="primary"
                height="auto"
                borderRadius="4px"
                iconSpacing="4px"
                p="2px 2px 2px 4px"
                _hover={{
                    bg: 'transparentLight.5',
                }}
                _active={{
                    bg: 'transparentLight.10',
                }}
            >
                <HStack spacing={2}>
                    <WorkspaceItemImage
                        imageLink={currentWorkspace.avatarThumbnailUrl}
                    />

                    <Text fontWeight={500} lineHeight="20px" isTruncated={true}>
                        {currentWorkspace.name}
                    </Text>
                </HStack>
            </MenuButton>

            <MenuList
                py={0}
                borderRadius="8px"
                overflow="hidden"
                border="1px solid"
                borderColor="gray.20"
                maxH="600px"
                overflowY="auto"
                mt="6px"
                w="200px"
                bg="white"
                zIndex="1000"
            >
                <MenuGroup
                    title="Your workspaces"
                    fontSize="9px"
                    fontWeight="600"
                    color="gray.35"
                    padding="4.5px 8px"
                    textTransform="uppercase"
                    background="#00000005"
                    margin="0"
                    h="20px"
                    w="200px"
                >
                    {getCurrentUserWorkspacesQuery.data.map(
                        ({ id, name, avatarThumbnailUrl }) => (
                            <WorkspaceSelectItem
                                key={id}
                                id={id}
                                name={name || ''}
                                imageLink={avatarThumbnailUrl}
                            />
                        ),
                    )}

                    <AddWorkspaceButton />
                </MenuGroup>
            </MenuList>
        </Menu>
    );
};
