import React, { useCallback } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    Center,
    Image,
    Spinner,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '../../../components';
import {
    modalRegularTextStyleProps,
    modalSubHeaderStyleProps,
} from '../steps/helper';
import { Alerter } from '../../../utils';
import { useSignedUrl } from '../../../hooks/useSignedUrl';

interface IUploadWorkspaceAvatarProps {
    title: string;
    imageFromFile: string;
    showImage: (file: File) => void;
    removeImage: VoidFunction;
    uploadLoading?: boolean;
    removeLoading?: boolean;
    isLoading?: boolean;
}

const maxFileSize = 10 * 1024 * 1024; // 10mb

export const UploadWorkspaceAvatar: React.FC<IUploadWorkspaceAvatarProps> = ({
    imageFromFile,
    showImage,
    removeImage,
    title,
    removeLoading,
    uploadLoading,
    isLoading,
}) => {
    const [isImageLoading, setIsImageLoading] = React.useState(true);
    const isStoragePath = imageFromFile?.includes('workspace-files');
    const storagePath = isStoragePath
        ? imageFromFile.split('workspace-files/')[1]?.split('?')[0]
        : null;
    const signedUrl = useSignedUrl(storagePath);

    // Use the direct imageFromFile for local uploads, and signedUrl for storage images
    const imageSource = isStoragePath ? signedUrl : imageFromFile;

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length && acceptedFiles[0].size > maxFileSize) {
                Alerter.error('Max file size should not exceed 10MB');
                return;
            }
            showImage(acceptedFiles[0]);
        },
        [showImage],
    );

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        multiple: false,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/gif': ['.gif'],
        },
        maxFiles: 1,
    });

    return (
        <Box>
            <Text {...modalSubHeaderStyleProps}>{title}</Text>
            <Flex gap={'15px'} mt={'10px'}>
                {isLoading ? (
                    <Center
                        boxSize="70px"
                        flexShrink={0}
                        borderRadius="4px"
                        bg="gray.15"
                    >
                        <Spinner size="sm" />
                    </Center>
                ) : !imageFromFile ? (
                    <Center
                        {...getRootProps()}
                        boxSize="70px"
                        flexShrink={0}
                        borderRadius="4px"
                        border={'none'}
                        bg={isDragActive ? 'gray.25' : 'gray.15'}
                        cursor="pointer"
                        transition="background-color 0.2s"
                        _hover={{
                            bg: 'gray.25',
                        }}
                    >
                        <Icon name="image-upload" width="16" height="16" />
                        <input {...getInputProps()} />
                    </Center>
                ) : (
                    <>
                        {isImageLoading && (
                            <Center
                                boxSize="70px"
                                flexShrink={0}
                                borderRadius="4px"
                                bg="gray.15"
                            >
                                <Spinner size="sm" />
                            </Center>
                        )}
                        <Image
                            src={imageSource || undefined}
                            alt={'logo'}
                            width="70px"
                            height="70px"
                            flexShrink={0}
                            objectFit="cover"
                            borderRadius="4px"
                            onLoad={() => setIsImageLoading(false)}
                            style={{
                                display: isImageLoading ? 'none' : 'block',
                            }}
                        />
                    </>
                )}
                <Box>
                    <Flex gap={'10px'}>
                        <Button
                            onClick={open}
                            variant="outline"
                            isLoading={uploadLoading}
                        >
                            <Icon
                                width={'14px'}
                                height={'14px'}
                                name={'upload'}
                            />
                            <Text ml={'10px'} color={'inherit'}>
                                Upload
                            </Text>
                        </Button>
                        <Button
                            variant={'outline'}
                            isDisabled={!imageFromFile}
                            onClick={removeImage}
                            isLoading={removeLoading}
                        >
                            <Icon
                                width={'14px'}
                                height={'14px'}
                                name={'delete'}
                            />
                            <Text ml={'10px'} color={'inherit'}>
                                Remove
                            </Text>
                        </Button>
                    </Flex>
                    <Text {...modalRegularTextStyleProps} mt={'10px'}>
                        We support your best PNGs, JPEGs and GIFs portraits
                        under 10MB
                    </Text>
                </Box>
            </Flex>
        </Box>
    );
};
