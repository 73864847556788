import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalFooter,
    Button,
    Center,
} from '@chakra-ui/react';
import { IconPlus } from '@tabler/icons-react';
import { useModals } from '@router';

import Selector from './selector';

import css from './index.module.css';

const SelectWorkspaceNew = () => {
    const modals = useModals();

    return (
        <Modal isOpen={true} isCentered={true} onClose={() => {}}>
            <ModalOverlay />

            <ModalContent className={css.content}>
                <ModalHeader className={css.header}>
                    Select a workspace
                </ModalHeader>

                <ModalBody className={css.body}>
                    <Selector />
                </ModalBody>

                <ModalFooter className={css.footer}>
                    <Center w="100%">
                        <Button
                            colorScheme="blue"
                            size="md"
                            variant="outline"
                            onClick={() => modals.open('/create-workspace')}
                        >
                            Create a workspace
                        </Button>
                    </Center>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default SelectWorkspaceNew;
