import { useMutation, useQueryClient } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { QueryKeys } from '../../../constants';
import { Alerter } from '../../../utils';
import { useTgFolders } from '../../../hooks/useTgFolders';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { trpc } from '@core/api/trpc';

// UNUSED with cycles useSyncFolders --- useTgFolders, remove with old wync-chats|onboarding?
export const useSyncFolders = ({ onFinish }: { onFinish: VoidFunction }) => {
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();
    const tgFolders = useTgFolders();
    const syncCardsMutation = trpc.workspace.syncCards.useMutation({
        onSuccess: (data, variables) => {
            console.log('data', data);
            // if (!data.success) {
            //     Alerter.error(data?.errors?.[0]?.message);
            //     return;
            // }

            onFinish();

            if (variables?.silent) {
                return;
            }
            Alerter.success('Chats sync settings updated!');
        },
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_WORKSPACE_CARDS_STATUSES],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_BOARD_CARDS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.TG_CHATS],
            });
            queryClient.refetchQueries({
                queryKey: [QueryKeys.TG_CHATS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_TG_FOLDERS, workspaceId],
            });
        },
    });

    const syncFolders = ({ silent }: { silent: boolean }) => {
        const allFolders = tgFolders.all;
        console.log({ allFolders });

        if (!allFolders && !silent) {
            throw new Error('Folders are undefined');
        }

        const selectedFolders = tgFolders.selected.map((id) =>
            allFolders?.find((folder) => folder.telegramFolderId === id),
        );

        const data = {
            workspaceId: workspaceId,
            folders: silent
                ? []
                : selectedFolders
                      .filter((f) => f?.telegramFolderId)
                      .map((folder) => ({
                          telegramFolderId: folder!.telegramFolderId!,
                          chats:
                              folder?.chats.map((i) => ({
                                  telegramChatId: Number(i.id),
                                  name: i.chat?.title || '',
                              })) ?? [],
                      })),
        };

        syncCardsMutation.mutate({
            silent,
            folders: data.folders,
            workspaceId,
        });
    };

    return { ...syncCardsMutation, mutate: syncFolders };
};
