import { trpc } from '@core/api/trpc';
import { ChatTasksListResponse } from '../types/tasks.types';

export const useGetCardTasks = (workspaceId?: number, cardId?: number) => {
    return trpc.cardTasks.getCardTasks.useQuery(
        { workspaceId: workspaceId!, cardId: cardId! },
        {
            refetchOnWindowFocus: false,
            enabled: !!workspaceId && !!cardId,
        },
    );
};
