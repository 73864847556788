import React from 'react';
import { IconButton, useDisclosure } from '@chakra-ui/react';
import {
    Icon,
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from '../../../../../components';
import {
    useCreateCardActivity,
    useGetCardActivitiesOptions,
} from '../../../queries';
import { Alerter, getActivityIconByTypeId } from '../../../../../utils';
import { trpc } from '@core/api/trpc';
import { useQueryClient } from '@tanstack/react-query';

interface NewActionMenuProps {
    cardId?: number;
    workspaceId?: number;
}

export const NewActionMenu: React.FC<NewActionMenuProps> = ({
    workspaceId,
    cardId,
}) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const { data } = useGetCardActivitiesOptions(false, workspaceId, cardId);
    const { mutateAsync: createCardActivity } = useCreateCardActivity();
    const markChatAsReadMutation = trpc.messages.markChatAsRead.useMutation();

    const workspaceCardActivities = data?.workspaceCardActivities;

    const onCreateCardActivity = async (
        workspaceCardActivityId: number,
        cardId?: number,
        workspaceId?: number,
    ) => {
        try {
            if (!workspaceId) {
                return Alerter.error('Workspace is missing');
            }
            if (!cardId) {
                return Alerter.error('Card does not exist');
            }

            // Close the dropdown menu immediately for better UX
            onClose();

            // Create the activity - optimistic updates are handled in the mutation
            const res = await createCardActivity({
                cardId,
                workspaceCardActivityId,
                workspaceId,
            });

            if (!res.success) {
                return;
            }

            // Mark chat as read
            await markChatAsReadMutation.mutateAsync({
                workspaceId,
                cardId,
            });

            // No need for manual invalidation here as it's handled in the mutation
        } catch (error) {
            console.log('onCreateCardActivity: ', error);
        }
    };

    return (
        <DropdownMenu open={isOpen} onOpenChange={onToggle}>
            <DropdownMenuTrigger asChild={true}>
                <IconButton
                    variant="outline"
                    aria-label="Actions menu"
                    title="Actions menu"
                    minW="auto"
                    borderRadius="4px"
                    bg={isOpen ? 'transparentLight.10' : undefined}
                    boxSize="24px"
                >
                    <Icon
                        name={isOpen ? 'minus' : 'plus'}
                        height="16"
                        width="16"
                    />
                </IconButton>
            </DropdownMenuTrigger>

            <DropdownMenuContent align="end">
                {workspaceCardActivities
                    ?.sort((a, b) => a.position - b.position)
                    .map((activity) => {
                        const menuIconName = getActivityIconByTypeId(
                            activity.typeId,
                        );
                        return (
                            <DropdownMenuItem
                                key={activity.id}
                                onClick={() =>
                                    onCreateCardActivity(
                                        activity.id,
                                        cardId,
                                        workspaceId,
                                    )
                                }
                            >
                                {menuIconName ? (
                                    <Icon
                                        name={menuIconName}
                                        height="16"
                                        width="16"
                                    />
                                ) : null}
                                {activity.description}
                            </DropdownMenuItem>
                        );
                    })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
