import { trpc } from '@core/api/trpc';
import { CardActivityOption } from '../types';
import { QueryKeys } from '../../../constants/query-keys';

export const useGetCardActivitiesOptions = (
    isFetchEnabled: boolean,
    workspaceId?: number,
    cardId?: number,
) => {
    return trpc.activities.getCardActivitiesOptions.useQuery(
        {
            workspaceId: workspaceId ?? 0,
            cardId: cardId ?? 0,
        },
        {
            enabled: isFetchEnabled && !!workspaceId && !!cardId,
            refetchOnWindowFocus: false,
        },
    );
};
