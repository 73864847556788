import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, VStack, Box, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getAutomationNavLinkItems, getNavLinkItems } from '../constants';
import { SidebarNavItem } from './SidebarNavItem';
import { SidebarCompanyHeader } from './SidebarCompanyHeader';
// import { LogoutButton } from './LogoutButton';
import { TaskCardSolo } from '../../TaskCard/TaskCardSolo';
import { TeamChatPlaceholder } from '../../TaskCard/TeamChatPlaceholder';
import { SidebarChatView } from './SidebarChatView';
import { SidebarSettingsView } from './SidebarSettingsView';
import { ApiResponse } from '../../../types';
import { useGetNotificationsCount } from '../../../queries';
import { QueryKeys } from '../../../constants';
import { BatchSendButton } from './batch-send-button';
import { ConfModeButton } from './conf-mode-button';
// import {
//     invoke,
//     SignalRMethods,
//     SignalRTopics,
//     useSignalREffect,
// } from '../../../services';
import { RootStore } from '../../../redux/createStore';
import { useGetWorkspaceId } from '../../../hooks';
import { UserInformBlock } from './UserInformBlock';
import { useChats } from '../../../hooks/useChats';
import { trpc } from '@core/api/trpc';
import { NotificationsCountUpdatePayload } from '../../../types';
import { LogoutButton } from './LogoutButton';

// Add type declaration for window.electron
declare global {
    interface Window {
        electron?: {
            on: (channel: string, listener: (...args: any[]) => void) => void;
            off: (channel: string, listener: (...args: any[]) => void) => void;
        };
    }
}

export const SidebarContent = () => {
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);
    const { teamChat } = useSelector((state: RootStore) => state.auth.user);

    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const workspaceId = useGetWorkspaceId();
    const chats = useChats();

    const teamTgIds =
        queryClient.getQueryData<ApiResponse<{ telegramUserIds: number[] }>>([
            QueryKeys.GET_WORKSPACE_TELEGRAM_IDS,
            workspaceId,
        ])?.value?.telegramUserIds || [];

    const { isDashboard, isTasks } = useMemo(() => {
        const dashboardRouteRegex = /^\/\d+$/;
        const tasksRouteRegex = /^\/\d+\/tasks$/;
        const isDashboard = dashboardRouteRegex.test(location.pathname);
        const isTasks = tasksRouteRegex.test(location.pathname);

        return { isDashboard, isTasks };
    }, [location.pathname]);

    const { data: notificationsCountData } = useGetNotificationsCount();

    // Check if user is workspace owner
    const ownershipQuery = trpc.workspace.isWorkspaceOwner.useQuery(
        { workspaceId },
        {
            retry: false,
            enabled: !!workspaceId,
        },
    );

    // Compute view states based on location
    const showSidebarSettingsView = location.pathname.includes('settings');
    const showSidebarChatView = location.pathname.includes('chat');

    // Filter navigation items
    const navigationItems = useMemo(() => {
        return getNavLinkItems(workspaceId).filter((elem) => {
            // Hide CRM Settings if user is not confirmed as owner
            if (elem.name === 'CRM Settings') {
                return ownershipQuery.data?.isOwner === true;
            }
            return true;
        });
    }, [workspaceId, ownershipQuery.data?.isOwner]);

    const onRedirect = (chatTelegramId?: number, cardId?: number) => {
        if (!chatTelegramId) {
            return;
        }

        navigate(`/${workspaceId}/chat/${cardId}`, {
            state: {
                chatTelegramId,
                isTeamChat: true,
                id: cardId,
            },
        });
    };

    useEffect(() => {
        if (workspaceId && (isTasks || isDashboard)) {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
            });
        }
    }, [isTasks, isDashboard, workspaceId, queryClient]);

    const getActivityCount = (id: number) => {
        if (id === 3) {
            return typeof notificationsCountData === 'number'
                ? notificationsCountData
                : 0;
        }
        return 0;
    };

    useEffect(() => {
        const handleNotificationsCountUpdate = (
            data: NotificationsCountUpdatePayload,
        ) => {
            queryClient.setQueryData(
                [
                    'notifications',
                    'getWorkspaceNotificationsCount',
                    { workspaceId },
                ],
                data.isAdded
                    ? (prev: number = 0) => prev + 1
                    : (prev: number = 0) => Math.max(0, prev - 1),
            );
        };

        window.electron?.on(
            'notifications-count-update',
            handleNotificationsCountUpdate,
        );

        return () => {
            window.electron?.off(
                'notifications-count-update',
                handleNotificationsCountUpdate,
            );
        };
    }, [queryClient, workspaceId]);

    // useEffect(() => {
    //     if (signalRConnected && workspaceId) {
    //         invoke(SignalRMethods.WorkspaceJoinChat, workspaceId);
    //     }

    //     return () => {
    //         if (workspaceId && signalRConnected) {
    //             invoke(SignalRMethods.WorkspaceLeaveChat, workspaceId);
    //         }
    //     };
    // }, [signalRConnected, workspaceId]);

    return (
        <>
            <Flex
                as="aside"
                direction="column"
                alignItems="space-between"
                h="100vh"
                p="0.75rem 4px 0.75rem 8px"
                w="220px"
                flexShrink={0}
                overflowY="auto"
                sx={{
                    '&::-webkit-scrollbar': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-track': {
                        width: 0,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: 0,
                    },
                }}
            >
                {workSpace ? (
                    <SidebarCompanyHeader
                        wrapperProps={{
                            display: {
                                base: 'none',
                                md: !showSidebarSettingsView ? 'flex' : 'none',
                            },
                        }}
                        showSidebarChatView={showSidebarChatView}
                    />
                ) : null}

                <Flex
                    direction="column"
                    alignItems="stretch"
                    position="relative"
                    id="kek"
                    display={{ base: 'flex', md: 'none' }}
                >
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        mx={{ base: 0, md: 4 }}
                    >
                        {workSpace ? (
                            <SidebarCompanyHeader
                                showSidebarChatView={showSidebarChatView}
                            />
                        ) : null}
                    </Flex>
                </Flex>

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <VStack spacing={'2px'} my={'2rem'} w={'100%'}>
                        {navigationItems.map((elem) => (
                            <SidebarNavItem
                                key={elem.id}
                                name={elem.name}
                                path={elem.path}
                                iconName={elem.iconName}
                                location={location.pathname}
                                isSoon={elem.isSoon}
                                isActive={elem.path === location.pathname}
                                activity={getActivityCount(elem.id)}
                            />
                        ))}

                        <LogoutButton />
                    </VStack>
                ) : null}

                {showSidebarChatView ? null : !showSidebarSettingsView ? (
                    <>
                        <Text
                            fontSize={'0.75rem'}
                            mb={'0.5rem'}
                            color={'#B3B3B3'}
                            fontWeight={600}
                            textTransform={'uppercase'}
                        >
                            Automation
                        </Text>

                        <VStack spacing={'2px'} w={'100%'} mb={'2rem'}>
                            <BatchSendButton
                                name="Batch Send"
                                iconName="mail-share"
                            />
                            <ConfModeButton
                                name="Conference Mode™"
                                iconName="door"
                            />
                            {getAutomationNavLinkItems(workspaceId).map(
                                (elem) => (
                                    <SidebarNavItem
                                        key={elem.id}
                                        name={elem.name}
                                        path={elem.path}
                                        iconName={elem.iconName}
                                        location={location.pathname}
                                        isSoon={elem.isSoon}
                                        isActive={false}
                                    />
                                ),
                            )}
                        </VStack>
                    </>
                ) : null}

                <UserInformBlock isEnabled={isDashboard} />

                {showSidebarChatView ? (
                    <SidebarChatView teamTgIds={teamTgIds} />
                ) : null}

                {showSidebarSettingsView ? <SidebarSettingsView /> : null}

                {/* {!showSidebarSettingsView ? (
                    <Box
                        mt="auto"
                        w="100%"
                        display={{ base: 'none', md: 'block' }}
                    >
                        {teamChat && teamChat.id !== null ? (
                            <TaskCardSolo
                                chats={chats}
                                chat={chats.getChatInfo(
                                    teamChat?.id ? teamChat.id.toString() : '',
                                )}
                                isDragging={false}
                                cardName={teamChat?.name || ''}
                                chatTelegramId={teamChat?.id || 0}
                                onRedirect={() =>
                                    onRedirect(teamChat?.id, teamChat?.cardId)
                                }
                                isTeamChat={true}
                                cardId={teamChat?.cardId}
                                teamTgIds={teamTgIds}
                            />
                        ) : (
                            <TeamChatPlaceholder
                                teamChatData={teamChat}
                                isAdmin={workSpace?.isAdmin}
                            />
                        )}
                    </Box>
                ) : null} */}
            </Flex>
        </>
    );
};
