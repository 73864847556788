import React from 'react';
import {
    Flex,
    Box,
    Center,
    IconButton,
    Editable,
    EditablePreview,
    Input,
    EditableInput,
    HStack,
} from '@chakra-ui/react';
import { Controller, Control } from 'react-hook-form';
import { Icon } from '../../../components';
import { colors } from '../../../theme/colors';
import { PipelineForm } from '../types';
import { StageItemEditableControls } from './StageItemEditableControls';
import { PipelineDefaultStages } from '../../../constants';

interface IStageItemProps {
    index: number;
    name: string;
    control: Control<PipelineForm>;
    onDelete: VoidFunction;
    onMoveUp?: VoidFunction;
    onMoveDown?: VoidFunction;
    isFirst?: boolean;
    isLast?: boolean;
    isArchive?: boolean;
}

export const StageItem: React.FC<IStageItemProps> = ({
    index,
    name,
    control,
    onDelete,
    onMoveUp,
    onMoveDown,
    isFirst = false,
    isLast = false,
    isArchive = false,
}) => {
    return (
        <Flex flexDirection="row" gap="8px" py="4px" align="center">
            <Center boxSize="24px">
                <Box
                    w="12px"
                    h="16px"
                    bg={PipelineDefaultStages[index].color}
                    borderRadius="60px"
                />
            </Center>
            <Controller
                control={control}
                name={`stages.${index}.name`}
                render={({ field }) => (
                    <Editable
                        defaultValue={name || PipelineDefaultStages[index].name}
                        isPreviewFocusable={false}
                        display="flex"
                        w="100%"
                        gap="8px"
                        onChange={(nextValue) => field.onChange(nextValue)}
                        value={field.value}
                        onSubmit={(value) => {
                            if (!value) {
                                field.onChange(
                                    PipelineDefaultStages[index].name,
                                );
                            }
                        }}
                    >
                        <EditablePreview
                            bg="#00000005"
                            padding="0px 4px 0px 8px"
                            borderRadius="4px"
                            border="1px solid"
                            borderColor="gray.20"
                            w="100%"
                            fontSize="13px"
                            fontWeight={400}
                            color="primary"
                            lineHeight="22px"
                        />
                        <Input
                            as={EditableInput}
                            height="24px"
                            fontSize="13px"
                            fontWeight={400}
                            color="primary"
                            borderRadius="4px"
                            padding="0px 4px 0px 8px"
                            _focusVisible={{
                                border: '1px solid #1961ED',
                                boxShadow: '0px 0px 0px 3px #1961ED1A',
                            }}
                        />
                        <StageItemEditableControls />
                    </Editable>
                )}
            />

            <HStack spacing={1}>
                {!isArchive && (
                    <>
                        <IconButton
                            aria-label="move up"
                            variant="ghost"
                            minW="auto"
                            borderRadius="4px"
                            onClick={onMoveUp}
                            isDisabled={isFirst}
                            boxSize="24px"
                        >
                            <Icon
                                name="chevron-down"
                                style={{ transform: 'rotate(180deg)' }}
                                height="16px"
                                width="16px"
                                color={
                                    isFirst ? colors.gray[30] : colors.secondary
                                }
                            />
                        </IconButton>
                        <IconButton
                            aria-label="move down"
                            variant="ghost"
                            minW="auto"
                            borderRadius="4px"
                            onClick={onMoveDown}
                            isDisabled={isLast}
                            boxSize="24px"
                        >
                            <Icon
                                name="chevron-down"
                                height="16px"
                                width="16px"
                                color={
                                    isLast ? colors.gray[30] : colors.secondary
                                }
                            />
                        </IconButton>
                    </>
                )}
                {!isFirst && !isArchive && (
                    <IconButton
                        aria-label="delete"
                        variant="ghost"
                        minW="auto"
                        borderRadius="4px"
                        onClick={onDelete}
                        boxSize="24px"
                    >
                        <Icon
                            name="delete-red-bin"
                            height="16px"
                            width="16px"
                            color={colors.red['DEFAULT']}
                        />
                    </IconButton>
                )}
            </HStack>
        </Flex>
    );
};
