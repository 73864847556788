import { Box, Button, Text } from "@chakra-ui/react";
import { Select } from "@components/RadixSelect/Select";
import * as RadixSelect from '@radix-ui/react-select';
import { useExistingConference } from "../hooks/useExistingConference";

interface ExistingConferenceProps {
  selectedConferenceId: string;
  onConferenceSelect: (id: string) => void;
}

export const ExistingConference = ({ selectedConferenceId, onConferenceSelect }: ExistingConferenceProps) => {
  const { conferences, handleAddToExisting, isLoading } = useExistingConference();

  return (
    <Box width="200px">
      <Text color={"#B3B3B3"} fontSize={"13px"} fontWeight={"600"} mb={"0px"}>
        YOUR CONFERENCES
      </Text>
      <Select 
        placeholder="Select a conference"
        triggerStyles={{ width: '100%', height: '40px' }}
        contentStyle={{ 
          backgroundColor: 'white', 
          opacity: 1,
          backdropFilter: 'none',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          border: '1px solid #ebebeb'
        }}
        viewportStyle={{
          backgroundColor: 'white'
        }}
        removePortal={true}
        rootProps={{
          value: selectedConferenceId,
          onValueChange: onConferenceSelect
        }}
        valueProps={{
          children: conferences?.find(conf => conf.workspaces_table.id.toString() === selectedConferenceId)?.workspaces_table.name
        }}
      >
        {conferences?.map((conf) => (
          <RadixSelect.Item 
            key={conf.workspaces_table.id} 
            value={conf.workspaces_table.id.toString()}
            className="SelectItem"
          >
            {conf.workspaces_table.name}
          </RadixSelect.Item>
        ))}
      </Select>
      <Button 
        onClick={() => handleAddToExisting(selectedConferenceId)}
        isDisabled={!selectedConferenceId || isLoading}
        mt={4}
        width="100%"
      >
        Add to existing
      </Button>
    </Box>
  );
}; 