import {
    Box,
    Button,
    Flex,
    Text,
    Spinner,
    Center,
    Image,
} from '@chakra-ui/react';
import { ConferenceWorkspaceProps } from './types';
import { MenuItem } from './MenuItem';
import styles from './index.module.css';
import { mockHandlers } from './mockData';
import { trpc } from '@core/api/trpc';
import { useGetCurrentUserQuery } from '@core/api/queries';
import { useModals, useNavigate } from '@router';
import { useSetCurrentWorkspaceMutation } from '@core/api';
import dogSvg from '../../../assets/dog.svg';

export const ConferenceWorkspace: React.FC<ConferenceWorkspaceProps> = ({
    isCollectingMode = false,
    onStartCollecting = mockHandlers.onStartCollecting,
    goToNext,
    goToPrevious,
    cancel,
}) => {
    const modals = useModals();
    const navigate = useNavigate();
    const { data: currentUser } = useGetCurrentUserQuery();
    const { data: isConferenceStarted, isLoading: isStatusLoading } =
        trpc.conference.isConferenceStarted.useQuery({
            userId: Number(currentUser?.id),
        });

    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();
    const getActiveConferences =
        trpc.conference.getStartedConferencesByID.useQuery({
            userId: Number(currentUser?.id),
        });
    const updateConferenceEnded =
        trpc.conference.updateConferenceEnded.useMutation();
    const handleStartCollecting = () => {
        onStartCollecting?.();
        goToNext?.();
    };

  const handleCancel = () => {
        console.log('conferences', getActiveConferences.data);
        getActiveConferences.data?.forEach((conference) => {
            updateConferenceEnded.mutate({
                workspaceId: conference.workspaces_table.id,
            });
        });

        modals.close();
    };

    const { data: conferences, isLoading: isConferencesLoading } =
        trpc.conference.getAllConferences.useQuery({
            userId: Number(currentUser?.id),
        });

    const handleWorkspaceSelect = async (workspaceId: number) => {
        try {
            await setCurrentWorkspaceMutation.mutateAsync({ workspaceId });
            modals.close();
            navigate('/:workspaceId', {
                params: { workspaceId: String(workspaceId) },
            });
        } catch (error) {
            console.error('Error selecting workspace:', error);
        }
    };

    return (
        <>
            <Box className={styles.header}>
                <Text
                    className={styles.title}
                    fontSize="24px"
                    fontWeight="600"
                    color="primary"
                >
                    Your Conferences
                </Text>
                <Box className={styles.workspaceList}>
                    {isConferencesLoading ? (
                        <Center p={4}>
                            <Spinner size="lg" color="primary" />
                        </Center>
                    ) : conferences && conferences.length > 0 ? (
                        <Box className={styles.menuContainer}>
                            {conferences.map((conference) => (
                                <Box
                                    key={conference.workspaces_table.id}
                                    className={styles.menuSection}
                                >
                                    <MenuItem
                                        icon={
                                            conference.workspaces_table
                                                .thumbnailId
                                                ? `/api/files/${conference.workspaces_table.thumbnailId}`
                                                : undefined
                                        }
                                        name={
                                            conference.workspaces_table.name ||
                                            'Unnamed Workspace'
                                        }
                                        isCompany={false}
                                        onClick={() =>
                                            handleWorkspaceSelect(
                                                conference.workspaces_table.id,
                                            )
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                    ) : (
                        <Center flexDirection="column" p={8}>
                            <Image
                                src={dogSvg}
                                alt="Empty state"
                                width="60px"
                                height="52px"
                                mb={4}
                            />
                            <Text color="gray.500" fontSize="lg">
                                Wow, such empty...
                            </Text>
                        </Center>
                    )}
                </Box>
            </Box>

            <Box className={styles.separator} mt="10px" />

            <Box className={styles.footer} mt="20px">
                <Box className={styles.footerContent}>
                    <Text
                        className={styles.footerTitle}
                        fontSize="24px"
                        fontWeight="600"
                        color="primary"
                    >
                        Collect new leads
                    </Text>
                    <Text className={styles.footerDescription}>
                        Activate Conference Mode™ during an event period to
                        collect all the new chats in a designated separate
                        workspace
                    </Text>
                </Box>
                <Box className={styles.buttonContainer}>
                    {isStatusLoading ? (
                        <Center>
                            <Spinner size="md" color="primary" />
                        </Center>
                    ) : (
                        <>
                            <Button
                                onClick={
                                    isConferenceStarted
                                        ? handleCancel
                                        : handleStartCollecting
                                }
                                className={styles.mainButton}
                            >
                                {isConferenceStarted
                                    ? 'Stop collecting'
                                    : 'Start collecting'}
                            </Button>
                            <Flex className={styles.statusIndicator}>
                                <Box
                                    className={styles.statusDot}
                                    backgroundColor={
                                        isConferenceStarted
                                            ? '#4CAF50'
                                            : 'rgba(214, 214, 214, 1)'
                                    }
                                />
                                <Text className={styles.statusText}>
                                    Conference Mode™ is{' '}
                                    {isConferenceStarted ? 'ON' : 'OFF'}
                                </Text>
                            </Flex>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};
