import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Box, Flex, Textarea, FormLabel, Button, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverClose,
} from '../../../../components';
import { ReminderField } from '../ReminderField';
import styles from './ReminderPopup.module.css';
import {
    useCreateReminder,
    useUpdateReminder,
} from '../../tabs/Reminders/queries';
import { convertDateToReminderPayload } from '../../utils/convertDateToReminderPayload';
import { getReminderMinDate } from '../../../../utils';
import { useGetWorkspaceId } from '../../../../hooks';

interface ReminderPopupProps {
    isOpen: boolean;
    onClose: VoidFunction;
    children: ReactElement;
    cardId: number;
    onToggle?: VoidFunction;
    reminderDescription?: string;
    reminderDate?: string;
    reminderId?: number;
}

export const ReminderPopup = React.forwardRef<
    HTMLDivElement | null,
    ReminderPopupProps
>(
    (
        {
            isOpen,
            onClose,
            children,
            cardId,
            reminderDescription,
            onToggle,
            reminderDate,
            reminderId,
        },
        ref,
    ) => {
        const workspaceId = useGetWorkspaceId();
        const createReminderMutation = useCreateReminder();
        const updateReminderMutation = useUpdateReminder(cardId);

        const [description, setDescription] = useState('');
        const [remindDate, setRemindDate] = useState<Date | null>(null);

        const resetAllState = () => {
            setDescription('');
            setRemindDate(null);
        };

        const onSubmit = useCallback(() => {
            if (!workspaceId || !cardId || !remindDate) {
                return;
            }

            if (reminderId) {
                updateReminderMutation.mutate(
                    {
                        reminderId: reminderId,
                        text: description,
                        remindOn: convertDateToReminderPayload(remindDate),
                    },
                    {
                        onSuccess: () => {
                            onClose();
                            resetAllState();
                        },
                    },
                );
                return;
            }

            createReminderMutation.mutate(
                {
                    cardId: cardId,
                    text: description,
                    remindOn: convertDateToReminderPayload(remindDate),
                },
                {
                    onSuccess: (data) => {
                        onClose();
                        resetAllState();
                    },
                },
            );
        }, [
            workspaceId,
            cardId,
            description,
            remindDate,
            reminderId,
            onClose,
            createReminderMutation,
            updateReminderMutation,
        ]);

        useEffect(() => {
            if (reminderDescription && isOpen) {
                setDescription(reminderDescription);
            }
            if (reminderDate && isOpen) {
                setRemindDate(dayjs(reminderDate).toDate());
            }
            if (!isOpen) {
                resetAllState();
            }
        }, [reminderDescription, reminderDate, isOpen]);

        const isSubmitDisabled = useMemo(() => {
            const reminderMinDate = getReminderMinDate();
            const isReminderDateInvalid =
                remindDate && remindDate < reminderMinDate;

            return (
                !description.length || !remindDate || !!isReminderDateInvalid
            );
        }, [description, remindDate]);

        const isReminderEmptyOrOutdated = useMemo(() => {
            const reminderMinDate = getReminderMinDate();

            return !remindDate || remindDate < reminderMinDate;
        }, [remindDate]);

        return (
            <Popover open={isOpen} onOpenChange={onToggle} modal={true}>
                <PopoverTrigger asChild={true}>{children}</PopoverTrigger>
                <PopoverContent
                    ref={ref}
                    sideOffset={5}
                    align="end"
                    className={styles.ReminderPopoverContent}
                >
                    <Flex
                        flexDirection="row"
                        padding="8px"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                        align="center"
                        justify="space-between"
                    >
                        <Text color="primary" fontSize="13px" fontWeight="600">
                            Set reminder to yourself
                        </Text>
                        <PopoverClose aria-label="Close" onClick={onClose}>
                            <Icon name="close-cross" height="16" width="16" />
                        </PopoverClose>
                    </Flex>
                    <Flex
                        padding="8px"
                        gap="8px"
                        flexDirection="column"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                    >
                        <Box>
                            <FormLabel
                                textTransform="uppercase"
                                color="gray.35"
                                fontSize="11px"
                                fontWeight="600"
                                mb="4px"
                            >
                                Text
                            </FormLabel>
                            <Textarea
                                bg="#00000005"
                                border="1px solid"
                                borderColor="gray.20"
                                borderRadius="4px"
                                padding="8px"
                                fontSize="13px"
                                fontWeight="400"
                                value={description}
                                resize="none"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Box>

                        <ReminderField
                            date={remindDate}
                            onChangeDate={(date) => setRemindDate(date)}
                            label="Remind on"
                        />
                    </Flex>
                    <Box padding="8px">
                        <Button
                            variant="popup"
                            w="100%"
                            isDisabled={isSubmitDisabled}
                            title={
                                !!reminderId && isReminderEmptyOrOutdated
                                    ? 'Provide the correct reminder date'
                                    : undefined
                            }
                            isLoading={
                                updateReminderMutation.isPending ||
                                createReminderMutation.isPending
                            }
                            onClick={onSubmit}
                        >
                            {reminderId ? 'Update' : 'Create'}
                        </Button>
                    </Box>
                </PopoverContent>
            </Popover>
        );
    },
);
