import React from 'react';
import { Skeleton, Text, Tooltip, VStack } from '@chakra-ui/react';
import { isEmptyCard } from '../../../utils';

interface LimitedAccessViewProps {
    hasAccess: boolean;
    onClick?: () => void;
    isLoaded?: boolean;
    chatTelegramId: number;
}

export const LimitedAccessView: React.FC<LimitedAccessViewProps> = ({
    hasAccess,
    isLoaded,
    chatTelegramId,
    // onClick,
}) => {
    // If card has no chat (empty card) or has access - show nothing
    if (isEmptyCard(chatTelegramId) || hasAccess) {
        return null;
    }

    if (!isLoaded) {
        return (
            <VStack w="full" pb={1.5} spacing={1}>
                <Skeleton
                    isLoaded={isLoaded}
                    w="full"
                    h="20px"
                    startColor="gray.40"
                    endColor="gray.20"
                />
                <Skeleton
                    isLoaded={isLoaded}
                    w="full"
                    h="20px"
                    startColor="gray.40"
                    endColor="gray.20"
                />
            </VStack>
        );
    }

    return (
        <VStack w="full" spacing={1} pb="10px">
            <Tooltip
                label={`This may happen when
1. You're not in this chat
2. The chat history is deleted (or chat is too old)
3. Chat data is not available in the telegram servers`}
            >
                <Text>You're not in this chat</Text>
            </Tooltip>
        </VStack>
    );
};
