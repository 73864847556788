import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { useGetWorkspaceId } from '../../../../../hooks';
import { trpc } from '@core/api/trpc';

export const useDeleteReminder = (cardId: number) => {
    const utils = trpc.useUtils();

    return trpc.reminders.deleteReminder.useMutation({
        onSuccess: () => {
            utils.reminders.getCardReminders.invalidate();
        },
        onError: (error) => {
            Alerter.error(`Error deleting reminder: ${error.message}`);
        },
    });
};
