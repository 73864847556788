import { useMutation } from '@tanstack/react-query';
import { trpc } from '@core/api/trpc';

interface GetOtpPayload {
    telegramUserId: number;
}

interface GetOtpResult {
    otpCode: string;
}

const GET_OTP_MUTATION = 'GET_OTP_MUTATION';

function useGetOtp() {
    const createOtpMutation = trpc.auth.createOtp.useMutation();

    return useMutation<GetOtpResult, unknown, GetOtpPayload>({
        mutationKey: [GET_OTP_MUTATION],
        mutationFn: async ({ telegramUserId }) => {
            const data = await createOtpMutation.mutateAsync({
                telegramUserId,
            });

            return { otpCode: data.id };
        },
    });
}

export { useGetOtp, GET_OTP_MUTATION };
