import { CustomTag } from '../../../components';

import { Filters } from './FilterSelect/useCardFilters';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';
import { Text } from '@chakra-ui/react';
import { IconCalendarEvent } from '@tabler/icons-react';

type TimeFilter = {
    label: string;
} & Filters['lastActivity'];

type LastActivitySelectProps = {
    initialValue: string;
    onChange: (value: string) => void;
};

export const LastActivitySelect = ({
    initialValue,
    onChange,
}: LastActivitySelectProps) => {
    // Try to parse the initialValue as JSON if it's not empty
    let parsedFilter: Filters['lastActivity'] | null = null;
    let selectedLastActivity: TimeFilter | undefined;

    if (initialValue) {
        try {
            parsedFilter = JSON.parse(initialValue);
            // Find the matching filter by operator and timeMs
            selectedLastActivity = timeFilters.find(
                (x) =>
                    x.operator === parsedFilter?.operator &&
                    x.timeMs === parsedFilter?.timeMs,
            );
        } catch (e) {
            // Fallback to the old behavior for backward compatibility
            selectedLastActivity = timeFilters.find(
                (x) => x.label === initialValue,
            );
        }
    }

    return (
        <FilterSelect
            title="Last Activity"
            items={timeFilters.map((x) => (
                <FilterSelectItem
                    key={x.label}
                    onClick={() => {
                        // Convert the filter object to a JSON string
                        const filterValue = JSON.stringify({
                            operator: x.operator,
                            timeMs: x.timeMs,
                        });
                        onChange(filterValue);
                    }}
                >
                    <Text color={'secondary'}>{x.label}</Text>
                </FilterSelectItem>
            ))}
            isLoading={false}
            selectedItem={
                selectedLastActivity ? (
                    <CustomTag
                        label={selectedLastActivity.label}
                        bgColor={'transparentLight.5'}
                        labelColor={'primary'}
                        enableCloseButton={true}
                        wrapperProps={{
                            pr: '3px',
                            maxW: '160px',
                        }}
                        closeButtonProps={{
                            'aria-label': 'last activity filter',
                            onClick: (ev) => {
                                ev.stopPropagation();
                                onChange('');
                            },
                        }}
                    />
                ) : null
            }
            icon={<IconCalendarEvent height="16" width="16" />}
        />
    );
};

const enum TimeMs {
    Second = 1000,
    Minute = 60 * Second,
    Hour = 60 * Minute,
    Day = 24 * Hour,
    Week = 7 * Day,
    Month = 30 * Day,
    Year = 365 * Day,
}

const timeFilters: TimeFilter[] = [
    {
        label: '< a week ago',
        timeMs: TimeMs.Week,
        operator: 'lt',
    },
    {
        label: '< a month ago',
        timeMs: TimeMs.Month,
        operator: 'lt',
    },
    {
        label: '< 3 months ago',
        timeMs: 3 * TimeMs.Month,
        operator: 'lt',
    },
    {
        label: '> a week ago',
        timeMs: TimeMs.Week,
        operator: 'gt',
    },
    {
        label: '> a month ago',
        timeMs: TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 3 months ago',
        timeMs: 3 * TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 6 months ago',
        timeMs: 6 * TimeMs.Month,
        operator: 'gt',
    },
    {
        label: '> 12 months ago',
        timeMs: TimeMs.Year,
        operator: 'gt',
    },
];
