import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import {
    useToast,
    Box,
    Text,
    VStack,
    HStack,
    Button,
    Tag,
    FormControl,
    FormLabel,
    Input,
    Avatar,
    IconButton,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useDisclosure,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react';
import {
    IconTarget,
    IconInfoCircle,
    IconTag,
    IconUserCircle,
    IconBuildingSkyscraper,
} from '@tabler/icons-react';
import { ChevronDownIcon } from '@chakra-ui/icons';

import {
    ModalWrapper,
    Icon,
    Select,
    SelectItem,
    InputText,
    CustomTag,
} from '../../components';

import { createCardModalAtom } from '@atoms/modals.atoms';
import { useGetMatchedWorkspaceId } from '../../hooks';
import { trpc } from '../../core/api/trpc';
import { QueryKeys } from '../../constants';
import { PipelineDefaultStages } from '../../constants/pipeline-default-stages';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useGetWorkspaceLabels } from '../../queries';
import { useCreateLabel } from '../../features/Chat/tabs/Info/queries';
import { EditLabelPopup } from '../../features/Chat/tabs/Info/components/EditLabelPopup/EditLabelPopup';
import type { RootStore } from '../../redux/createStore';
import type { ApiResponse, BaseUserData } from '../../types';
import { getLabelInfoByType } from '../../utils';
import styles from './create-card.module.css';
import { useGetWorkspaceCardsStatuses } from '../../queries';

/* ---------------------------------- *
 * Types
 * ---------------------------------- */
type FormValues = {
    cardName: string;
    info: string;
    owner: string;
};

type PipelineStage = {
    name: string;
    color: string;
    textColor?: string;
    statusId: number;
};

type Owner = BaseUserData & {
    avatar?: string;
};

type LabelType = {
    workspaceLabelId: number;
    colourId: number;
    name: string;
};

/* ---------------------------------- *
 * Subcomponent: Stage Selector
 * ---------------------------------- */
function StageSelector({
    stages,
    selectedStage,
    onSelectStage,
}: {
    stages: PipelineStage[];
    selectedStage?: PipelineStage;
    onSelectStage: (stage: PipelineStage) => void;
}) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <FormControl>
            <HStack spacing={4} align="center" mb={1.5}>
                <HStack spacing={1} width="120px">
                    <IconTarget size={16} color="gray" />
                    <FormLabel margin={0} color="#999" fontSize="12px">
                        Stage
                    </FormLabel>
                </HStack>
                <Box position="relative" flex={1}>
                    <Button
                        w="100%"
                        onClick={isOpen ? onClose : onOpen}
                        variant="unstyled"
                        display="flex"
                        justifyContent="flex-start"
                        bg="white"
                        h="auto"
                        p={0}
                        _focus={{ outline: 'none' }}
                    >
                        <Tag
                            bg={selectedStage?.color}
                            color={selectedStage?.textColor || 'gray.700'}
                            borderRadius="full"
                            mr={1}
                            style={{
                                fontSize: '13px',
                            }}
                        >
                            {selectedStage?.name || 'Select stage'}
                        </Tag>
                        <ChevronDownIcon color="#999" />
                    </Button>

                    {isOpen && (
                        <Box
                            position="absolute"
                            top="100%"
                            left={0}
                            right={0}
                            mt={2}
                            bg="white"
                            borderRadius="md"
                            boxShadow="lg"
                            zIndex={10}
                            maxH="200px"
                            overflowY="auto"
                        >
                            {stages.map((stage) => (
                                <Button
                                    key={stage.name}
                                    w="100%"
                                    justifyContent="flex-start"
                                    variant="ghost"
                                    onClick={() => {
                                        onSelectStage(stage);
                                        onClose();
                                    }}
                                    pl={1}
                                    py={2}
                                >
                                    <Tag
                                        bg={stage.color}
                                        color={stage.textColor}
                                        borderRadius="full"
                                    >
                                        {stage.name}
                                    </Tag>
                                </Button>
                            ))}
                        </Box>
                    )}
                </Box>
            </HStack>
        </FormControl>
    );
}

/* ---------------------------------- *
 * Subcomponent: Owner Selector
 * ---------------------------------- */
function OwnerSelector({
    owners,
    selectedOwner,
    onSelectOwner,
}: {
    owners: Owner[];
    selectedOwner?: string;
    onSelectOwner: (value: string) => void;
}) {
    return (
        <FormControl>
            <HStack spacing={4} align="center" height="24px">
                <HStack spacing={1} width="120px">
                    <IconUserCircle size={16} color="gray" />
                    <FormLabel margin={0} color="#999" fontSize="12px">
                        Owner
                    </FormLabel>
                </HStack>
                <Select
                    placeholder="Assign to"
                    rootProps={{
                        value: selectedOwner || undefined,
                        onValueChange: onSelectOwner,
                    }}
                    chevronColor="#999"
                    chevronSize="14px"
                    triggerStyles={{
                        position: 'relative',
                        border: 'none',
                        padding: '0',
                        marginLeft: selectedOwner ? '-7px' : '0',
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        outline: 'none',
                        flex: 1,
                        gap: '4px',
                        lineHeight: '1.5',
                        justifyContent: 'flex-start',
                        width: 'min-content',
                        fontSize: '13px',
                        color: selectedOwner
                            ? 'black'
                            : 'var(--chakra-colors-gray-500)',
                    }}
                    contentStyle={{
                        boxShadow: 'sm',
                        borderRadius: 'md',
                        marginTop: '1px',
                    }}
                    triggerClassName={`SingleLine ${selectedOwner ? 'AvatarSpacing' : ''}`}
                    removePortal={true}
                >
                    {owners.map((user) => (
                        <SelectItem
                            key={user.id}
                            value={`${user.id}`}
                            selectItemIndicatorProps={{
                                style: { left: 'calc(100% - 25px)' },
                            }}
                            style={{ position: 'relative' }}
                            className="SingleLine"
                            onClick={(e) => e.preventDefault()}
                        >
                            <Avatar
                                position="absolute"
                                left="4px"
                                top="50%"
                                transform="translateY(-50%)"
                                src={user.avatar || ''}
                                color="gray.600"
                                width="16px"
                                height="16px"
                                bg="transparent"
                                icon={
                                    <Icon
                                        name="unknown-user"
                                        width="16px"
                                        height="16px"
                                        style={{
                                            marginRight: selectedOwner
                                                ? '4px'
                                                : '0',
                                            marginLeft: selectedOwner
                                                ? '1px'
                                                : '0',
                                        }}
                                    />
                                }
                            />
                            {`${user.firstName || 'unknown'} ${user.lastName || ''}`}
                        </SelectItem>
                    ))}
                </Select>
            </HStack>
        </FormControl>
    );
}

/* ---------------------------------- *
 * Subcomponent: Labels Selector
 * ---------------------------------- */
function LabelsSelector({
    workspaceId,
    selectedLabelIds,
    onSelectLabels,
}: {
    workspaceId?: number;
    selectedLabelIds: number[];
    onSelectLabels: (labelIds: number[]) => void;
}) {
    const {
        isOpen: isLabelsOpen,
        onOpen: onLabelsOpen,
        onClose: onLabelsClose,
    } = useDisclosure();

    const [newLabelName, setNewLabelName] = useState('');
    const [newLabelId, setNewLabelId] = useState(0);

    const labelsRef = useRef<HTMLDivElement>(null);
    const labelEditPopupRef = useRef<HTMLDivElement | null>(null);

    // Fetch labels
    const { data: labelsData } = useGetWorkspaceLabels();
    const workspaceLabels = useMemo(() => labelsData || [], [labelsData]);

    // For newly created labels
    const createLabelMutation = useCreateLabel();
    const queryClient = useQueryClient();

    const selectedLabels = useMemo(
        () =>
            workspaceLabels.filter((label) =>
                selectedLabelIds.includes(label.id),
            ),
        [workspaceLabels, selectedLabelIds],
    );

    const isLabelAlreadyExist = useMemo(
        () =>
            !!workspaceLabels.find(
                (el) =>
                    el.name.trim().toLowerCase() ===
                    newLabelName.trim().toLowerCase(),
            ),
        [newLabelName, workspaceLabels],
    );

    // Handle click outside of label popover (still valid if you prefer to close on outside click)
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                labelsRef.current &&
                !labelsRef.current.contains(event.target as Node)
            ) {
                onLabelsClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onLabelsClose]);

    const onAddLabel = useCallback(() => {
        if (!workspaceId) return;
        createLabelMutation.mutate(
            { name: newLabelName.trim(), colourId: 1 },
            {
                onSuccess: (data) => {
                    if (!data.success) return;
                    setNewLabelId(data.labelId);
                    onSelectLabels([...selectedLabelIds, data.labelId]);
                },
            },
        );
    }, [
        workspaceId,
        createLabelMutation,
        newLabelName,
        selectedLabelIds,
        onSelectLabels,
    ]);

    const onToggleLabelColorPickerPopup = useCallback(() => {
        if (!newLabelId) {
            return;
        }
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.GET_WORKSPACE_LABELS, workspaceId],
        });
        setNewLabelId(0);
        setNewLabelName('');
        onLabelsOpen();
    }, [newLabelId, workspaceId, queryClient, onLabelsOpen]);

    return (
        <FormControl>
            <HStack spacing={4} align="center" height="24px">
                <HStack spacing={1} width="120px">
                    <IconTag size={16} color="gray" />
                    <FormLabel margin={0} color="#999" fontSize="12px">
                        Labels
                    </FormLabel>
                </HStack>
                <Box position="relative" flex={1} ref={labelsRef}>
                    <Popover isOpen={isLabelsOpen} onClose={onLabelsClose}>
                        <PopoverTrigger>
                            <Box
                                onClick={
                                    isLabelsOpen ? onLabelsClose : onLabelsOpen
                                }
                                cursor="pointer"
                            >
                                {selectedLabels.length > 0 ? (
                                    <HStack spacing={2} wrap="wrap">
                                        {selectedLabels.map((label) => {
                                            const { bgColor, textColor } =
                                                getLabelInfoByType(
                                                    label.colourId,
                                                );
                                            return (
                                                <CustomTag
                                                    key={label.id}
                                                    label={label.name}
                                                    bgColor={bgColor}
                                                    labelColor={textColor}
                                                    wrapperProps={{
                                                        maxW: 'calc(100% - 32px)',
                                                        sx: {
                                                            '& span': {
                                                                fontSize:
                                                                    '13px',
                                                            },
                                                            '& *': {
                                                                fontSize:
                                                                    '13px',
                                                            },
                                                        },
                                                    }}
                                                    style={{
                                                        fontSize: '13px',
                                                        marginLeft: '-1px',
                                                    }}
                                                />
                                            );
                                        })}
                                    </HStack>
                                ) : (
                                    <Text color="gray.500" fontSize="13px">
                                        No labels yet
                                    </Text>
                                )}
                            </Box>
                        </PopoverTrigger>
                        <PopoverContent
                            className={styles.LabelsPopoverContent}
                            style={{
                                width: 'calc(100% - 136px)',
                                maxWidth: '400px',
                                backgroundColor: 'white',
                            }}
                        >
                            <VStack align="stretch" p={2}>
                                <InputText
                                    placeholder="+ Add label"
                                    value={newLabelName}
                                    onChange={(e) =>
                                        setNewLabelName(e.target.value)
                                    }
                                    fontWeight={400}
                                    h="36px"
                                    boxShadow="unset"
                                    borderWidth="0px"
                                    bg="transparent"
                                    px="8px"
                                    borderColor="transparent"
                                    isDisabled={createLabelMutation.isPending}
                                    rightIcon={
                                        <EditLabelPopup
                                            name={newLabelName}
                                            colourId={1}
                                            isCreate
                                            workspaceLabelId={newLabelId}
                                            align="start"
                                            side="left"
                                            sideOffset={-15}
                                            alignOffset={0}
                                            ref={labelEditPopupRef}
                                            isOpen={!!newLabelId}
                                            onToggle={
                                                onToggleLabelColorPickerPopup
                                            }
                                            onClose={() => {
                                                setNewLabelId(0);
                                                setNewLabelName('');
                                            }}
                                            style={{
                                                zIndex: 1400,
                                                position: 'relative',
                                                backgroundColor: 'white',
                                            }}
                                        >
                                            <IconButton
                                                aria-label="add-label"
                                                variant="outline"
                                                title={
                                                    isLabelAlreadyExist
                                                        ? 'Label with this name already exists'
                                                        : 'Add label'
                                                }
                                                minW="24px"
                                                w="24px"
                                                h="24px"
                                                borderRadius="4px"
                                                isLoading={
                                                    createLabelMutation.isPending
                                                }
                                                onClick={onAddLabel}
                                                isDisabled={isLabelAlreadyExist}
                                                icon={
                                                    <Icon
                                                        name="plus"
                                                        width="16px"
                                                        height="16px"
                                                    />
                                                }
                                            />
                                        </EditLabelPopup>
                                    }
                                    inputRightElementProps={{
                                        visibility: newLabelName
                                            ? 'visible'
                                            : 'hidden',
                                        opacity: newLabelName ? 1 : 0,
                                        transition:
                                            '0.2s opacity ease, 0.2s visibility ease',
                                    }}
                                />
                                {/* Existing labels in workspace */}
                                {workspaceLabels.map((label) => {
                                    const { bgColor, textColor } =
                                        getLabelInfoByType(label.colourId);
                                    const isSelected =
                                        selectedLabelIds.includes(label.id);
                                    return (
                                        <HStack
                                            key={label.id}
                                            w="full"
                                            p="4px"
                                            cursor="pointer"
                                            onClick={() => {
                                                onSelectLabels(
                                                    isSelected
                                                        ? selectedLabelIds.filter(
                                                              (id) =>
                                                                  id !==
                                                                  label.id,
                                                          )
                                                        : [
                                                              ...selectedLabelIds,
                                                              label.id,
                                                          ],
                                                );
                                            }}
                                            bg={
                                                isSelected
                                                    ? 'transparentLight.5'
                                                    : 'transparent'
                                            }
                                            _hover={{
                                                bg: 'transparentLight.5',
                                            }}
                                        >
                                            <CustomTag
                                                label={label.name}
                                                bgColor={bgColor}
                                                labelColor={textColor}
                                                wrapperProps={{
                                                    maxW: 'calc(100% - 32px)',
                                                    sx: {
                                                        '& span': {
                                                            fontSize: '13px',
                                                        },
                                                        '& *': {
                                                            fontSize: '13px',
                                                        },
                                                    },
                                                }}
                                                style={{ fontSize: '13px' }}
                                            />
                                        </HStack>
                                    );
                                })}
                            </VStack>
                        </PopoverContent>
                    </Popover>
                </Box>
            </HStack>
        </FormControl>
    );
}

/* ---------------------------------- *
 * Main Component: CreateCardModal
 * ---------------------------------- */
export const CreateCardModal = () => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const workspaceId = useGetMatchedWorkspaceId();

    const [
        { isOpen: isCreateCardModalOpen, statusId: createCardStatusId },
        setCreateCardModal,
    ] = useAtom(createCardModalAtom);

    const storeAvatars = useSelector((state: RootStore) => state.avatars);

    // Board cards (for name uniqueness)
    const { data: boardCards } = useGetBoardCards();

    const allTeamMembersData = queryClient.getQueryData<
        ApiResponse<{ members: BaseUserData[] }>
    >([QueryKeys.GET_WORKSPACE_MEMBERS, workspaceId]);

    const allTeamMembersWithAvatars: Owner[] = useMemo(() => {
        const allTeamMembers = allTeamMembersData?.value?.members || [];
        return allTeamMembers.map((user) => ({
            ...user,
            avatar: storeAvatars[`${user.telegramUserId}`],
        }));
    }, [allTeamMembersData?.value?.members, storeAvatars]);

    const PipelineStages = PipelineDefaultStages.slice(0, 5);

    // Fetch workspace statuses & map them to our pipeline stages
    const { data: stagesData } = useGetWorkspaceCardsStatuses();

    // Map workspace statuses to pipeline stages format, keeping original colors
    const mappedStages = useMemo(() => {
        const statuses = stagesData?.value?.statuses || [];
        return statuses.map((status) => ({
            name: status.name,
            color: PipelineStages[status.position - 1]?.color || '#E2E8F0',
            textColor: PipelineStages[status.position - 1]?.textColor,
            statusId: status.id,
        }));
    }, [stagesData]);

    // Current stage
    const [stage, setStage] = useState<PipelineStage | undefined>(
        mappedStages[0],
    );

    useEffect(() => {
        if (createCardStatusId == null) return;
        const newStage = mappedStages.find(
            (s) => s.statusId === createCardStatusId,
        );
        if (newStage) setStage(newStage);
    }, [createCardStatusId, mappedStages]);

    const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);

    const { mutate: createCard, isPending } = trpc.cards.createCard.useMutation(
        {
            onSuccess: () => {
                onClose();
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_BOARD_CARDS, workspaceId],
                });
            },
            onError: (error) => {
                toast({
                    title: 'Failed to create card',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            },
        },
    );

    const onClose = useCallback(() => {
        setCreateCardModal({ isOpen: false });
        setSelectedLabelIds([]);
    }, [setCreateCardModal]);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
    } = useForm<FormValues>({
        defaultValues: {
            cardName: '',
            info: '',
            owner: '',
        },
        mode: 'onChange',
        delayError: 300,
    });

    const cardName = watch('cardName');
    const owner = watch('owner');

    // Validate card name is unique
    const validateCardName = (value: string) => {
        if (!value.trim()) return true; // 'required' handles blank
        const existingCard = boardCards?.find(
            (card) =>
                card.cardName.toLowerCase() === value.trim().toLowerCase(),
        );
        return existingCard ? 'A card with this name already exists' : true;
    };

    const onSubmit = handleSubmit((data) => {
        if (!workspaceId || createCardStatusId == null || !stage) return;

        createCard({
            workspaceId,
            cardName: data.cardName.trim(),
            statusId: createCardStatusId,
            labelIds: selectedLabelIds,
            info: data.info.trim(),
            owner: data.owner,
        });
    });

    // Add this function to handle stage updates
    const handleStageSelect = useCallback(
        (newStage: PipelineStage) => {
            setStage(newStage);
            setCreateCardModal({ isOpen: true, statusId: newStage.statusId });
        },
        [setCreateCardModal],
    );

    if (!workspaceId) {
        return <Text>Unable to create card: No workspace selected.</Text>;
    }

    return (
        <ModalWrapper
            isOpen={isCreateCardModalOpen}
            onClose={onClose}
            modalContentProps={{
                maxWidth: '400px',
                padding: '40px 40px 0 40px',
                maxHeight: '90vh',
                overflow: 'hidden',
            }}
            modalBodyProps={{
                p: '0px',
                overflow: 'hidden',
            }}
        >
            <ModalHeader
                fontSize="20px"
                fontWeight="semibold"
                textAlign="center"
            >
                Create new deal
            </ModalHeader>

            <Text
                color="gray.600"
                textAlign="center"
                lineHeight="1.5"
                mb="32px"
            >
                Create a deal with anyone even if you don't have a Telegram chat
                yet and connect a chat later
            </Text>

            <form onSubmit={onSubmit}>
                <ModalBody p={0}>
                    <VStack align="stretch">
                        {/* MANDATORY FIELDS */}
                        <Text color="gray.500" fontSize="11px">
                            MANDATORY FIELDS
                        </Text>
                        <Box
                            border="1px"
                            borderColor="gray.200"
                            borderRadius="lg"
                            p={4}
                        >
                            <VStack spacing={3} align="stretch">
                                {/* Card Name */}
                                <FormControl isInvalid={!!errors.cardName}>
                                    <HStack
                                        spacing={4}
                                        align="center"
                                        height="24px"
                                    >
                                        <HStack spacing={1} width="120px">
                                            <IconBuildingSkyscraper
                                                size={16}
                                                color="gray"
                                            />
                                            <FormLabel
                                                fontSize="12px"
                                                margin={0}
                                                color="#999"
                                            >
                                                Company
                                            </FormLabel>
                                        </HStack>
                                        <Box position="relative" flex={1}>
                                            <Input
                                                {...register('cardName', {
                                                    required:
                                                        'Card name cannot be empty',
                                                    validate: validateCardName,
                                                })}
                                                placeholder="Company name"
                                                border="none"
                                                _focus={{ boxShadow: 'none' }}
                                                pl="0px"
                                                fontSize="13px"
                                            />
                                            {errors.cardName && (
                                                <Text
                                                    color="red.500"
                                                    fontSize="13px"
                                                    position="absolute"
                                                    top="-50px"
                                                    left="0"
                                                >
                                                    {errors.cardName.message}
                                                </Text>
                                            )}
                                        </Box>
                                    </HStack>
                                </FormControl>

                                {/* Stage Selector */}
                                <StageSelector
                                    stages={mappedStages}
                                    selectedStage={stage}
                                    onSelectStage={handleStageSelect}
                                />
                            </VStack>
                        </Box>

                        {/* OPTIONAL FIELDS */}
                        <Text color="gray.500" fontSize="11px" mt={8}>
                            OPTIONAL FIELDS
                        </Text>
                        <Box
                            border="1px"
                            borderColor="gray.200"
                            borderRadius="lg"
                            p={4}
                        >
                            <VStack spacing={3} align="stretch">
                                {/* Info */}
                                <FormControl>
                                    <HStack
                                        spacing={4}
                                        align="center"
                                        height="24px"
                                    >
                                        <HStack spacing={1} width="120px">
                                            <IconInfoCircle
                                                size={16}
                                                color="gray"
                                            />
                                            <FormLabel
                                                margin={0}
                                                color="#999"
                                                fontSize="12px"
                                            >
                                                Info
                                            </FormLabel>
                                        </HStack>
                                        <Input
                                            {...register('info')}
                                            placeholder="Additional information"
                                            border="none"
                                            _focus={{ boxShadow: 'none' }}
                                            pl="0px"
                                            flex={1}
                                            fontSize="13px"
                                        />
                                    </HStack>
                                </FormControl>

                                {/* Owner Selector */}
                                <OwnerSelector
                                    owners={allTeamMembersWithAvatars}
                                    selectedOwner={owner}
                                    onSelectOwner={(val) =>
                                        setValue('owner', val)
                                    }
                                />

                                {/* Labels Selector */}
                                <LabelsSelector
                                    workspaceId={workspaceId}
                                    selectedLabelIds={selectedLabelIds}
                                    onSelectLabels={setSelectedLabelIds}
                                />
                            </VStack>
                        </Box>
                    </VStack>
                </ModalBody>

                <ModalFooter flexDirection="column" gap={2}>
                    <Button
                        width="200px"
                        type="submit"
                        fontSize="13px"
                        marginTop="15px"
                        isDisabled={!cardName || !stage}
                        isLoading={isPending}
                        variant="solid"
                    >
                        Create
                    </Button>
                    <Button
                        width="200px"
                        fontSize="13px"
                        variant="ghost"
                        onClick={onClose}
                        color="gray.500"
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </form>
        </ModalWrapper>
    );
};
