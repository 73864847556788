import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { CreateWorkspaceForm, createWorkspaceResolver } from './schema';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { InputText } from '@components/index';
import { UploadWorkspaceAvatar } from '../../../modals/Onboarding/components';
import { useCreateWorkspaceMutation, CreateWorkspacePayload } from '@core/api';
import { createdWorkspaceIdAtom } from '../state';
import { useAtom } from 'jotai';
import { OnboardingScreenProps } from '../types';
import { useGetCurrentUserWorkspacesQuery } from '@core/api';
import { Alerter } from '../../../utils';

// Max file size: 5MB
const MAX_FILE_SIZE = 5 * 1024 * 1024;

function OnboardingCreateWorkspace({ goToNext }: OnboardingScreenProps) {
    const [_, setCreatedWorkspaceId] = useAtom(createdWorkspaceIdAtom);
    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

    const [imageFromFile, setImageFromFile] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm<CreateWorkspaceForm>({ resolver: createWorkspaceResolver });

    const createWorkspaceMutation = useCreateWorkspaceMutation();

    const showImage = (file: File) => {
        if (file.size > MAX_FILE_SIZE) {
            Alerter.error('Image size should be less than 5MB');
            return;
        }

        const imageDataUrl = URL.createObjectURL(file);
        setImageFile(file);
        setImageFromFile(imageDataUrl);
        clearErrors('image');
    };

    const removeImage = () => {
        setImageFromFile('');
        setImageFile(null);
    };

    const processImage = async (
        file: File,
    ): Promise<{
        base64: string;
        mimetype: string;
        originalname: string;
        size: number;
    } | null> => {
        try {
            if (file.size > MAX_FILE_SIZE) {
                Alerter.error('Image size should be less than 5MB');
                return null;
            }

            const arrayBuffer = await file.arrayBuffer();
            const base64 = Buffer.from(arrayBuffer).toString('base64');

            return {
                base64,
                mimetype: file.type,
                originalname: file.name,
                size: file.size,
            };
        } catch (error) {
            console.error('Error processing image:', error);
            return null;
        }
    };

    const onSubmit = async (data: CreateWorkspaceForm) => {
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);
            console.log('Starting workspace creation...');

            const payload: CreateWorkspacePayload = {
                name: data.name,
            };

            if (imageFile) {
                console.log('Processing image file...');
                const fileData = await processImage(imageFile);

                if (!fileData) {
                    setIsSubmitting(false);
                    return;
                }

                payload.file = fileData;
                console.log('File payload prepared');
            }

            console.log('Calling mutateAsync...');
            const newWorkspaceId =
                await createWorkspaceMutation.mutateAsync(payload);
            console.log('Workspace created with ID:', newWorkspaceId);

            setCreatedWorkspaceId(newWorkspaceId);
            await getCurrentUserWorkspacesQuery.refetch();
            goToNext();
        } catch (error) {
            console.error('Workspace creation error:', error);
            Alerter.error('Failed to create workspace. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection={'column'} align={'center'} gap={'30px'}>
                <Box>
                    <Text
                        fontWeight={'600'}
                        fontSize={'20px'}
                        lineHeight={'24px'}
                        textAlign={'center'}
                    >
                        Create your workspace
                    </Text>

                    <Text textAlign={'center'} mt={'16px'} color={'secondary'}>
                        A shared environment where you will be able to manage
                        your customer relations with your team.
                    </Text>
                </Box>

                <UploadWorkspaceAvatar
                    imageFromFile={imageFromFile}
                    removeImage={removeImage}
                    showImage={showImage}
                    title="Workspace logo"
                />

                <Box w={'100%'}>
                    <Text
                        fontWeight={'600'}
                        fontSize={'13px'}
                        color={'primary'}
                    >
                        Workspace name
                    </Text>

                    <Text
                        fontWeight={'400'}
                        fontSize={'12px'}
                        color={'gray.35'}
                    >
                        The name of your organization
                    </Text>

                    <InputText
                        {...register('name')}
                        mt={'16px'}
                        placeholder={'Apple'}
                        errorMsg={errors?.name?.message}
                    />
                </Box>

                <Button
                    w={'65%'}
                    type="submit"
                    isLoading={
                        createWorkspaceMutation.isPending || isSubmitting
                    }
                >
                    Continue
                </Button>
            </Flex>
        </form>
    );
}

export default OnboardingCreateWorkspace;
