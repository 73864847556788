export type UserInvitePayload = {
    telegramUserId: number;
    firstName: string;
    lastName: string | undefined;
};

export enum OnboardingSteps {
    SELECT_WORKSPACE,
    CREATE_WORKSPACE,
    INVITE_TEAM,
    SYNC_CHATS,
}
