import React, { useMemo, useRef } from 'react';
import {
    Badge,
    Box,
    Flex,
    HStack,
    Skeleton,
    Text,
    useDisclosure,
    useOutsideClick,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { CardLabelItem, LabelsPopup } from '../components';
import { LabelItemType } from '../../../../../types';
import { colors } from '../../../../../theme/colors';
import { useUpdateLabelAssignStatus } from '../queries';
import { trpc } from '@core/api/trpc';

interface LabelsSectionProps {
    cardId: number;
    cardLabels: LabelItemType[];
}

export const LabelsSection: React.FC<LabelsSectionProps> = ({ cardId }) => {
    const { isPending, variables } = useUpdateLabelAssignStatus();
    const { isOpen, onToggle, onClose, onOpen } = useDisclosure();

    const { data: chatData, isFetching } = trpc.cards.getCardAndOwner.useQuery({
        cardId,
    });

    const cardLabels = chatData?.card?.labels;

    const labelsPopupRef = useRef<HTMLDivElement | null>(null);
    const labelsBoxRef = useRef<HTMLDivElement | null>(null);
    const labelEditPopupRef = useRef<HTMLDivElement | null>(null);

    useOutsideClick({
        ref: labelsPopupRef,
        handler: (event) => {
            if (
                labelsBoxRef?.current?.contains(event.target as Node) ||
                labelEditPopupRef?.current?.contains(event.target as Node) ||
                labelsPopupRef?.current?.contains(event.target as Node) ||
                !isOpen
            ) {
                return;
            }

            onClose();
        },
    });

    const cardLabelIds = useMemo(
        () => cardLabels?.map((el) => el.id) || [],
        [cardLabels],
    );

    const renderedLabels = useMemo(() => {
        let data = cardLabels?.length ? cardLabels : [];
        let isLoading = isPending || (data && isFetching);
        const isDeleting = data.find((el) => el.id === variables?.labelId);

        if (isLoading && isDeleting) {
            data = data.filter((el) => el.id !== variables?.labelId);
        }

        if (!data?.length) {
            return (
                <Text
                    w="full"
                    fontSize="12px"
                    lineHeight="20px"
                    color="secondary"
                >
                    No labels yet
                </Text>
            );
        }

        return (
            <>
                {data.map((el) => (
                    <CardLabelItem
                        key={el.id}
                        enableCloseButton={isOpen}
                        cardId={cardId}
                        {...el}
                    />
                ))}
                {isLoading && !isDeleting && (
                    <Skeleton w="48px" borderRadius="20px" h="20px" />
                )}
            </>
        );
    }, [cardLabels, isOpen, cardId, isPending, variables]);

    return (
        <HStack spacing={1} align="flex-start">
            <HStack
                spacing={1}
                pt="8px"
                pb="4px"
                whiteSpace="nowrap"
                w="92px"
                flexShrink={0}
            >
                <Icon
                    name="label"
                    width="16px"
                    height="16px"
                    color={colors.tertiary}
                />
                <Text variant="bodyMedium" color="tertiary">
                    Labels
                </Text>
            </HStack>
            <LabelsPopup
                cardId={cardId}
                labelEditPopupRef={labelEditPopupRef}
                ref={labelsPopupRef}
                cardLabelIds={cardLabelIds}
                isOpen={isOpen}
                onClose={onClose}
                onToggle={onToggle}
            >
                <Box
                    ref={labelsBoxRef}
                    w="full"
                    h="100%"
                    bg={isOpen ? 'gray.15' : 'transparent'}
                    transition="0.3s background ease"
                    borderRadius="4px"
                    p="6px 8px"
                    minH="24px"
                    maxH="calc(100vh - 200px)"
                    overflow="auto"
                    cursor="pointer"
                    onClick={onOpen}
                >
                    <Flex flexWrap="wrap" columnGap={1} rowGap={1}>
                        {renderedLabels}
                    </Flex>
                </Box>
            </LabelsPopup>
        </HStack>
    );
};
