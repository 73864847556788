import { useQueryClient } from '@tanstack/react-query';
import { trpc } from '../core/api/trpc';
import { QueryKeys } from '../constants';
import { useToast } from '@chakra-ui/react';

type MergeCardsPayload = {
    workspaceId: number;
    sourceCardId: number;
    targetChatId: number;
};

export const useMergeCards = (
    onClose: () => void,
    options?: {
        onError?: (error: any) => void;
    },
) => {
    const queryClient = useQueryClient();
    const toast = useToast();

    return trpc.cards.mergeCards.useMutation<MergeCardsPayload>({
        onSuccess: (_, variables) => {
            onClose();

            // Invalidate all related queries
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_BOARD_CARDS, variables.workspaceId],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CHAT_MESSAGES],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CARD_TASKS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CARD_ACTIVITIES_LIST],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CARD_ACTIVITIES_OPTIONS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.GET_CARD_REMINDERS],
            });

            toast({
                title: 'Cards merged successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            // Metadata updates only if reopen card from dashboard
            setTimeout(() => {
                window.location.href = `/${variables.workspaceId}`;
            }, 1000);
        },
        onError: (error) => {
            options?.onError?.(error);

            if (error.message === 'duplicate_deal') {
                return;
            }

            toast({
                title: 'Failed to merge cards',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        },
    });
};
