import React, { useRef } from 'react';
import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { Icon, LabelWithImage } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { getAvatarBg } from '../../../../../utils';
import { OwnerPopup } from '../components/OwnerPopup/OwnerPopup';
import { trpc } from '@core/api/trpc';

interface OwnerSectionProps {
    cardOwnerQuery: any;
    teamMembers: Array<{
        id: number;
        firstName: string | null;
        lastName: string | null;
        thumbnail: string | null;
        telegramUserId: number;
    }>;
    workspaceId: number;
    cardId: number;
    onOwnerChange: (userId: number | null) => void;
}

export const OwnerSection: React.FC<OwnerSectionProps> = ({
    cardOwnerQuery,
    teamMembers,
    workspaceId,
    cardId,
    onOwnerChange,
}) => {
    const ownerBoxRef = useRef<HTMLDivElement | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const utils = trpc.useUtils();

    return (
        <HStack spacing={1} align="flex-start">
            <HStack
                spacing={1}
                pt="8px"
                pb="4px"
                whiteSpace="nowrap"
                w="92px"
                flexShrink={0}
            >
                <Icon
                    name="user-circle"
                    width="16px"
                    height="16px"
                    color={colors.tertiary}
                />
                <Text variant="bodyMedium" color="tertiary">
                    Owner
                </Text>
            </HStack>
            <OwnerPopup
                ref={ownerBoxRef}
                isOpen={isOpen}
                onClose={onClose}
                teamMembers={teamMembers}
                workspaceId={workspaceId}
                cardId={cardId}
                onOwnerChange={(userId) => {
                    onOwnerChange(userId);
                    utils.cards.getCardAndOwner.invalidate({
                        cardId,
                        workspaceId,
                    });
                    onClose();
                }}
                currentOwnerId={cardOwnerQuery?.data?.user?.id}
            >
                <Box
                    ref={ownerBoxRef}
                    w="full"
                    h="100%"
                    bg="transparent"
                    borderRadius="4px"
                    p="6px 8px"
                    minH="24px"
                    maxH="calc(100vh - 200px)"
                    overflow="auto"
                    cursor="pointer"
                    onClick={onOpen}
                >
                    <Flex flexWrap="wrap" columnGap={1} rowGap={1}>
                        {cardOwnerQuery?.data?.user ? (
                            <LabelWithImage
                                label={`${cardOwnerQuery.data.user.firstName || ''} ${cardOwnerQuery.data.user.lastName || ''}`}
                                imageLink={
                                    cardOwnerQuery.data.user.thumbnail || ''
                                }
                                includeAvatar={true}
                                avatarProps={{
                                    bg: getAvatarBg(
                                        cardOwnerQuery.data.user
                                            .telegramUserId || 0,
                                    ),
                                }}
                            />
                        ) : (
                            <Text
                                w="full"
                                fontSize="12px"
                                lineHeight="20px"
                                color="secondary"
                            >
                                No owner yet
                            </Text>
                        )}
                    </Flex>
                </Box>
            </OwnerPopup>
        </HStack>
    );
};
