import React from 'react';
import { HStack, Button, Box } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { Icon } from '../../../components';
import { selectTeamChatModalOpenAtom } from '../../../atoms/modals.atoms';
import { MiniTeamChatCard } from './MiniTeamChatCard';
import { SectionTitle } from './SectionTitle';
import { SectionDescription } from './SectionDescription';

export const TeamChatSelector: React.FC = () => {
    const [, setIsOpen] = useAtom(selectTeamChatModalOpenAtom);

    return (
        <Box>
            <SectionTitle>Team Chat</SectionTitle>
            <SectionDescription mt="12px" mb="16px">
                The pinned chat with your team
            </SectionDescription>
            <HStack spacing="16px">
                <MiniTeamChatCard />
                <Button
                    variant="outline"
                    onClick={() => setIsOpen(true)}
                    leftIcon={
                        <Icon name="refresh" height="16px" width="16px" />
                    }
                >
                    Select a new chat
                </Button>
            </HStack>
        </Box>
    );
};
