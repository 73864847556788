import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, ApiResponseEmpty } from '../../../../../types';
import API from '../../../../../api/api.base';
import { CardReminderItem, UpdateReminderPayload } from '../../../types';
import { QueryKeys } from '../../../../../constants';
import { Alerter } from '../../../../../utils';
import { trpc } from '@core/api/trpc';

export const useUpdateReminder = (cardId: number) => {
    const utils = trpc.useUtils();

    return trpc.reminders.updateReminder.useMutation({
        onSuccess: () => {
            utils.reminders.getCardReminders.invalidate();
        },
    });
    // const queryClient = useQueryClient();

    // return useMutation<ApiResponseEmpty, unknown, UpdateReminderPayload>({
    // 	mutationFn: payload => {
    // 		const { workspaceId, cardReminder, ...bodyPayload } = payload;
    // 		return API.put(
    // 			`/api/workspaces/${workspaceId}/cards/reminders/${cardReminder}/update-reminder`,
    // 			bodyPayload,
    // 		);
    // 	},
    // 	onSuccess: async (data, payload) => {
    // 		const { cardReminder, text, remindOn, workspaceId } = payload;

    // 		if (!data.success) {
    // 			Alerter.error(
    // 				data.errors?.[0]?.message ||
    // 					'Error occurred while updating reminder',
    // 			);
    // 			return;
    // 		}

    // 		Alerter.success(
    // 			data.messages?.[0]?.message || 'Reminder successfully updated',
    // 		);

    // 		queryClient.setQueryData<
    // 			ApiResponse<{ reminders: CardReminderItem[] }>
    // 		>([QueryKeys.GET_CARD_REMINDERS, cardId, workspaceId], oldData => {
    // 			if (oldData?.value.reminders) {
    // 				const updatedReminders = oldData.value.reminders.map(
    // 					reminder => {
    // 						if (reminder.id !== cardReminder) {
    // 							return reminder;
    // 						}
    // 						return {
    // 							...reminder,
    // 							remindOn,
    // 							text,
    // 						};
    // 					},
    // 				);
    // 				return {
    // 					...oldData,
    // 					value: {
    // 						reminders: updatedReminders || [],
    // 					},
    // 				};
    // 			}

    // 			return oldData;
    // 		});
    // 	},
    // });
};
