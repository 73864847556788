import React, { useCallback } from 'react';
import { HStack, IconButton, useDisclosure } from '@chakra-ui/react';
import { CustomTag, Icon, Loader } from '../../../../../../../components';
import { EditLabelPopup } from '../../EditLabelPopup/EditLabelPopup';
import { colors } from '../../../../../../../theme/colors';
import { getLabelInfoByType } from '../../../../../../../utils';
import { LabelItemType } from '../../../../../../../types';
import { useUpdateLabelAssignStatus } from '../../../queries';

interface LabelPopupItemProps extends LabelItemType {
    labelEditPopupRef: React.MutableRefObject<HTMLDivElement | null>;
    cardId: number;
}

export const LabelPopupItem: React.FC<LabelPopupItemProps> = ({
    colourId,
    name,
    labelEditPopupRef,
    id,
    cardId,
}) => {
    const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

    const updateLabelAssignStatusMutation = useUpdateLabelAssignStatus();

    const { bgColor, textColor } = getLabelInfoByType(colourId);

    const onAssignLabelToCard = useCallback(
        () =>
            updateLabelAssignStatusMutation.mutate({
                labelId: id,
                cardId,
                isAssigned: true,
            }),
        [cardId, updateLabelAssignStatusMutation, id],
    );

    return (
        <HStack
            w="full"
            p="4px"
            bg={isOpen ? 'transparentLight.5' : 'transparent'}
            transition="0.3s background ease"
            cursor={
                updateLabelAssignStatusMutation.isPending
                    ? 'not-allowed'
                    : 'pointer'
            }
            borderRadius="4px"
            position="relative"
            opacity={updateLabelAssignStatusMutation.isPending ? 0.6 : 1}
            onClick={() => {
                if (isOpen || updateLabelAssignStatusMutation.isPending) {
                    return;
                }

                onAssignLabelToCard();
            }}
            _hover={{
                bg: 'transparentLight.5',
                '.edit-label-icon': {
                    visibility: 'visible',
                    opacity: 1,
                },
            }}
        >
            {updateLabelAssignStatusMutation.isPending ? (
                <Loader
                    centerProps={{
                        position: 'absolute',
                        inset: 1,
                        zIndex: 50,
                        w: 'full',
                    }}
                    spinnerProps={{
                        thickness: '2px',
                        color: 'gray.40',
                        size: 'sm',
                        emptyColor: 'transparent',
                        speed: '0.5s',
                    }}
                />
            ) : null}
            <CustomTag
                label={name}
                bgColor={bgColor}
                labelColor={textColor}
                wrapperProps={{ maxW: 'calc(100% - 32px)' }}
            />
            <EditLabelPopup
                name={name}
                colourId={colourId}
                workspaceLabelId={id}
                ref={labelEditPopupRef}
                isOpen={isOpen}
                onClose={onClose}
                onToggle={onToggle}
            >
                <IconButton
                    aria-label="edit-label"
                    variant="ghost"
                    className="edit-label-icon"
                    borderRadius="4px"
                    visibility={isOpen ? 'visible' : 'hidden'}
                    ml="auto"
                    w="24px"
                    minW="24px"
                    h="24px"
                    p="4px"
                    opacity={isOpen ? 1 : 0}
                    transition="0.3s opacity ease, 0.3s visibility ease"
                    onClick={(e) => {
                        e.stopPropagation();
                        onOpen();
                    }}
                    icon={
                        <Icon
                            name="edit"
                            width="16px"
                            height="16px"
                            color={colors.secondary}
                        />
                    }
                />
            </EditLabelPopup>
        </HStack>
    );
};
