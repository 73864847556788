// labelMutations.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import { trpc } from '../../../../../core/api/trpc'; // Adjust import path as needed
import { Alerter } from '../../../../../utils'; // Adjust import path as needed

// Define the type for our mutation
type UpdateLabelAssignStatusMutation = ReturnType<
    typeof trpc.labels.updateLabelAssignStatus.useMutation
>;

// Create a context for our mutation
const LabelMutationsContext = createContext<{
    updateLabelAssignStatus: UpdateLabelAssignStatusMutation | null;
}>({
    updateLabelAssignStatus: null,
});

// Provider component that will wrap parts of our app that need the mutation
export const LabelMutationsProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const utils = trpc.useUtils();

    // Create the mutation once
    const updateLabelAssignStatus =
        trpc.labels.updateLabelAssignStatus.useMutation({
            onError: (error) => {
                console.error('Error updating label assign status:', error);
                if (
                    error.message?.includes('duplicate key value') ||
                    error.message?.includes('already exists')
                ) {
                    Alerter.error(
                        'Cannot remove this label because another unassigned label with the same name already exists. Try renaming one of the labels first.',
                    );
                } else {
                    Alerter.error(
                        'Failed to update label status: ' + error.message,
                    );
                }
            },
            onSuccess: (result) => {
                // Invalidate and refetch relevant queries
                utils.workspace.getWorkspaceCards.invalidate();
                utils.cards.getCardAndOwner.invalidate();

                if (result.success) {
                    // Show different messages based on the action taken
                    if (result.action === 'merged') {
                        Alerter.success(
                            'Label was merged with an existing unassigned label',
                        );
                    } else {
                        Alerter.success('Label status updated successfully');
                    }
                }
            },
        });

    return (
        <LabelMutationsContext.Provider value={{ updateLabelAssignStatus }}>
            {children}
        </LabelMutationsContext.Provider>
    );
};

// Custom hook to use the singleton mutation
export const useUpdateLabelAssignStatus = () => {
    const context = useContext(LabelMutationsContext);
    if (context.updateLabelAssignStatus === null) {
        throw new Error(
            'useUpdateLabelAssignStatus must be used within a LabelMutationsProvider',
        );
    }
    return context.updateLabelAssignStatus;
};
