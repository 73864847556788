import React from 'react';
import { HStack, Text, Avatar } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useGetChatInfo } from '../../../hooks';

export const MiniTeamChatCard: React.FC = () => {
	const { user } = useSelector((state: RootStore) => state.auth);
	console.log('use1r', user);
	
	const avatarUri = useGetChatInfo({
		chatId: user.teamChat?.id,
		includeUsernamesFetch: true,
		isEnabled: !!user.teamChat?.id,
	});

	return (
		<HStack
			spacing="4px"
			p="0px 16px"
			borderRadius="4px"
			bg="#00000005"
			border="1px solid"
			borderColor="gray.20"
			h="32px">
			<Avatar
				boxSize="14px"
				borderRadius="2px"
				name={user.teamChat?.name || ''}
				src={user.teamChat && avatarUri ? avatarUri : ''}
				sx={{	
					'> div': {
						fontSize: '8px',
					},
				}}
			/>
			{user.teamChat?.name ? (
				<Text color="primary" fontSize="13px" fontWeight="500">
					{user.teamChat.name}
				</Text>
			) : null}
		</HStack>
	);
};
