import { trpc } from '@core/api/trpc';

export const useGetAllCurrentUserTasks = (
    workspaceId?: number | null,
    filterStatusId: 1 | 2 | 3 = 1,
) => {
    const query = trpc.tasks.getCurrentUserTasks.useQuery(
        {
            offset: 0,
            limit: 20,
            filterStatus: filterStatusId,
        },
        {
            enabled: !!workspaceId,
            refetchOnWindowFocus: false,
        },
    );

    return query;
};
