import { trpc } from '../core/api/trpc';

export const useUpdateWorkspaceCardStatus = () => {
    const utils = trpc.useUtils();

    return trpc.workspace.updateCardStatus.useMutation({
        onSuccess: () => {
            utils.workspace.getWorkspaceCards.invalidate();
        },
    });
};
