import { memo, useEffect, useMemo, useState, useCallback } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { KanbanBoardColumn } from './components';
import { BoardCardItemResponse, BoardFilterValue } from './types';
import {
    useGetWorkspaceCardsStatuses,
    useUpdateWorkspaceCardStatus,
} from '../../queries';
import { CardDefaultStatusEnum, CardStatusItem } from '../../types';
import { useUpdateBoardCardData } from './hooks';
import { useUpdateArchiveVisibility } from './queries';
import { moveTask, tasksAtom, TasksGroupedByStatus } from '@atoms/tasksAtom';
import { useAtom, useAtomValue } from 'jotai';
import { LoadingOverlay } from './loading-overlay';
import { useChats } from '../../hooks/useChats';
import { sortCardsByDateAndAccess } from '../../utils';
import { useSearchParams } from 'react-router-dom';
import { isSortedReverseAtom } from '../../atoms/tasksAtom';

interface DashboardPanelTemplateTabsProps {
    tabIndex: BoardFilterValue;
    data: BoardCardItemResponse[];
    workspaceId?: number;
    chats: ReturnType<typeof useChats>;
    isArchiveVisible?: boolean;
}

// Moved outside component for performance
const parseArrayByStatusId = (
    cards: BoardCardItemResponse[],
    statuses: CardStatusItem[],
): TasksGroupedByStatus => {
    if (!cards.length || !statuses.length) {
        return {};
    }

    const cardsByStatus: TasksGroupedByStatus = {};

    // Initialize all status arrays first
    statuses.forEach((status) => {
        cardsByStatus[status.id] = [];
    });

    // Group cards by status ID in a single pass
    cards.forEach((card) => {
        const statusId = card.status.id;
        if (cardsByStatus[statusId]) {
            cardsByStatus[statusId].push(card);
        }
    });

    return cardsByStatus;
};

const isObjEmpty = (obj: { [key: string]: BoardCardItemResponse[] }) =>
    Object.keys(obj).length === 0;

const DashboardPanelTemplateTabs = memo(
    ({
        tabIndex,
        data,
        workspaceId,
        chats,
        isArchiveVisible,
    }: DashboardPanelTemplateTabsProps) => {
        const { data: statusesData } = useGetWorkspaceCardsStatuses();
        const { updateBoardCardData } = useUpdateBoardCardData();
        const { mutateAsync: updateWorkspaceCardStatus } =
            useUpdateWorkspaceCardStatus();
        const { mutateAsync: updateArchiveVisibility } =
            useUpdateArchiveVisibility();
        const [searchParams, setSearchParams] = useSearchParams();

        // On component mount, set the filter in URL if not already present
        useEffect(() => {
            const currentFilter = searchParams.get('filter');
            if (!currentFilter && tabIndex !== undefined) {
                const newParams = new URLSearchParams(searchParams);
                newParams.set('filter', BoardFilterValue[tabIndex]);
                setSearchParams(newParams);
            }
        }, []); // Empty dependency array since this should only run once

        // Load next page of chats data efficiently
        useEffect(() => {
            if (chats.hasNextPage && !chats.isLoading) {
                const timer = setTimeout(() => {
                    chats.fetchNextPage();
                }, 5000);
                return () => clearTimeout(timer); // Cleanup timeout on unmount
            }
        }, [chats.hasNextPage, chats.isLoading]);

        // Memoize statuses to prevent unnecessary recalculations
        const statuses = useMemo(
            () => statusesData?.value?.statuses || [],
            [statusesData?.value?.statuses],
        );

        // Memoize tasksDefault to avoid recalculation on every render
        const tasksDefault = useMemo(
            () => parseArrayByStatusId(data, statuses),
            [data, statuses],
        );

        const [tasks, setTasks] = useAtom(tasksAtom);

        // Track if data has changed using a more efficient approach
        const [dataSignature, setDataSignature] = useState('');

        // Calculate new signature for current data
        const newDataSignature = useMemo(() => {
            // Create a lighter signature based on card IDs and status IDs
            return data
                .map((card) => `${card.cardId}:${card.status.id}`)
                .join('|');
        }, [data]);

        // Update tasks only when data has actually changed
        useEffect(() => {
            // Only update if signature changes
            if (newDataSignature !== dataSignature) {
                setDataSignature(newDataSignature);
                setTasks(tasksDefault);
            }
        }, [newDataSignature, dataSignature, tasksDefault]);

        // Initialize tasks when component mounts
        useEffect(() => {
            if (!isObjEmpty(tasksDefault) && isObjEmpty(tasks)) {
                setTasks(tasksDefault);
            }
        }, [tasksDefault, tasks]);

        // Add this line to use the atom value outside of useCallback
        const isSortedReverse = useAtomValue(isSortedReverseAtom);

        // Memoize the sorting function to avoid recreating it on every render
        const getSortedTasks = useCallback(
            (
                tasks: BoardCardItemResponse[],
                chats: ReturnType<typeof useChats>,
            ) => {
                if (!tasks.length) return [];

                return [...tasks].sort((cardA, cardB) => {
                    const normalSort = sortCardsByDateAndAccess(
                        cardA,
                        cardB,
                        chats.data.chats,
                        chats.data.messages,
                        chats.data.lastMessages,
                    );

                    // Return the opposite sort order if reverse is enabled
                    return isSortedReverse ? -normalSort : normalSort;
                });
            },
            [isSortedReverse], // Add the sort order to the dependency array
        );

        // Memoize and optimize onDragEnd to prevent re-creation on every render
        const onDragEnd = useCallback(
            async (result: DropResult) => {
                const { source, destination } = result;

                // Early return if no valid drop destination
                if (
                    !destination ||
                    !workspaceId ||
                    source.droppableId === destination.droppableId
                ) {
                    return;
                }

                const sourceTasks = tasks[source.droppableId];
                const destinationTasks = tasks[destination.droppableId];

                // Early return if source or destination tasks don't exist
                if (!sourceTasks || !destinationTasks) {
                    return;
                }

                const sortedSourceTasks = getSortedTasks(sourceTasks, chats);
                const sortedDestinationTasks = getSortedTasks(
                    destinationTasks,
                    chats,
                );

                // Make sure we have a valid source index
                if (source.index >= sortedSourceTasks.length) {
                    return;
                }

                const movedTask = sortedSourceTasks[source.index];

                const originalSourceIndex = sourceTasks.findIndex(
                    (task) => task.cardId === movedTask.cardId,
                );

                const sortedTasksBefore = sortedDestinationTasks.slice(
                    0,
                    destination.index,
                );
                const originalDestinationIndex = destinationTasks.findIndex(
                    (task) =>
                        sortedTasksBefore.findIndex(
                            (t) => t.cardId === task.cardId,
                        ) === -1,
                );

                setTasks(
                    moveTask({
                        tasks,
                        sourceStatusId: source.droppableId,
                        destinationStatusId: destination.droppableId,
                        sourceIndex: originalSourceIndex,
                        destinationIndex: originalDestinationIndex,
                    }),
                );

                try {
                    await updateWorkspaceCardStatus({
                        cardId: movedTask.cardId,
                        cardStatusId: +destination.droppableId,
                    });
                    updateBoardCardData(
                        movedTask.cardId,
                        +destination.droppableId,
                    );
                } catch (error) {
                    console.error('Failed to update card status:', error);
                    // Could add error handling here
                }
            },
            [
                tasks,
                workspaceId,
                getSortedTasks,
                chats,
                updateWorkspaceCardStatus,
                updateBoardCardData,
                isSortedReverse,
            ],
        );

        // Memoize column visibility handler to prevent recreation on each render
        const handleArchiveVisibilityToggle = useCallback(() => {
            if (workspaceId) {
                updateArchiveVisibility({
                    workspaceId,
                    isArchiveVisible: !isArchiveVisible,
                });
            }
        }, [workspaceId, isArchiveVisible, updateArchiveVisibility]);

        // Memoize the columns to avoid unnecessary recalculations
        const columnElements = useMemo(() => {
            return statuses?.map((status) => {
                const isArchiveColumn =
                    status.typeId === CardDefaultStatusEnum.ARCHIVE;
                return (
                    <KanbanBoardColumn
                        key={status.id}
                        chats={chats}
                        tabIndex={tabIndex}
                        columnStatus={status}
                        enableColumnIcon={isArchiveColumn}
                        isColumnIconActive={
                            isArchiveColumn ? isArchiveVisible : false
                        }
                        onClickColumnIcon={
                            isArchiveColumn
                                ? handleArchiveVisibilityToggle
                                : undefined
                        }
                    />
                );
            });
        }, [
            statuses,
            chats,
            tabIndex,
            isArchiveVisible,
            handleArchiveVisibilityToggle,
            isSortedReverse,
        ]);

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Box
                    id="loading-overlay-holder"
                    pos="relative"
                    height="100%"
                    width="100%"
                    overflow="hidden"
                >
                    <Grid
                        id="dashboard-grid"
                        width="100%"
                        height="100%"
                        overflow="auto"
                        templateColumns={`repeat(${statuses?.length || 0}, minmax(280px, 1fr))`}
                        className="dashboard-grid"
                        sx={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px',
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                borderRadius: '4px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                },
                            },
                            '&::-webkit-scrollbar-track': {
                                backgroundColor: 'transparent',
                            },
                            // Firefox scrollbar
                            scrollbarWidth: 'thin',
                            scrollbarColor: 'rgba(0, 0, 0, 0.1) transparent',
                            // Hide scrollbar by default
                            '&.dashboard-grid::-webkit-scrollbar-thumb': {
                                display: 'none',
                            },
                            // Show on hover
                            '&.dashboard-grid:hover::-webkit-scrollbar-thumb': {
                                display: 'block',
                            },
                        }}
                    >
                        {columnElements}
                    </Grid>

                    <LoadingOverlay />
                </Box>
            </DragDropContext>
        );
    },
);

export default DashboardPanelTemplateTabs;
