type NavLinkItem = {
    id: number;
    name: string;
    path: string;
    iconName: string;
    isSoon: boolean;
};

export const getNavLinkItems = (workspaceId: number): NavLinkItem[] => [
    {
        id: 1,
        name: 'Dashboard',
        path: `/${workspaceId}`,
        iconName: 'kanban',
        isSoon: false,
    },
    {
        id: 2,
        name: 'Tasks',
        path: `/${workspaceId}/tasks`,
        iconName: 'checkbox',
        isSoon: false,
    },
    {
        id: 3,
        name: 'Notifications',
        path: `/${workspaceId}/notifications`,
        iconName: 'bell',
        isSoon: false,
    },
    {
        id: 4,
        name: 'CRM Settings',
        path: `/${workspaceId}/settings`,
        iconName: 'crm-settings',
        isSoon: false,
    },
    {
        id: 5,
        name: 'Telegram Settings',
        path: `/${workspaceId}/telegram-setting`,
        iconName: 'telegram-icon',
        isSoon: false,
    },
];

export const getAutomationNavLinkItems = (
    workspaceId: number,
): NavLinkItem[] => [
    {
        id: 2,
        name: 'Add/Remove Users',
        path: `/${workspaceId}/users-management`,
        iconName: 'users',
        isSoon: true,
    },
    {
        id: 3,
        name: 'Auto Sequences',
        path: `/${workspaceId}/auto-sequences`,
        iconName: 'mail-code',
        isSoon: true,
    },
];

export const getSettingsNavItems = (workspaceId: number): NavLinkItem[] => [
    {
        id: 1,
        name: 'General',
        path: `/${workspaceId}/settings`,
        iconName: 'crm-settings',
        isSoon: false,
    },
    {
        id: 2,
        name: 'Members',
        path: `/${workspaceId}/settings/members`,
        iconName: 'users',
        isSoon: false,
    },
    {
        id: 3,
        name: 'Billing',
        path: `/${workspaceId}/settings/billing`,
        iconName: 'credit-card',
        isSoon: true,
    },
    {
        id: 4,
        name: 'Pipeline',
        path: `/${workspaceId}/settings/pipeline`,
        iconName: 'table-import',
        isSoon: false,
    },
    {
        id: 5,
        name: 'Activity',
        path: `/${workspaceId}/settings/activity`,
        iconName: 'progress-check',
        isSoon: true,
    },
];
