import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { resetRecentStack } from '../redux/reducers/search-recent-stack.reducer';
import { RootStore } from '../redux/createStore';
import { useGetMatchedCardId } from '../hooks';
import { resetRequestPromise } from '../hooks/useChats';
import { useGetCurrentUserQuery } from '@core/api';
import { setAuthUser } from '../redux/reducers/auth.reducer';
import { useSetCurrentWorkspaceMutation } from '@core/api';

export const useSetWorkspace = () => {
    const queryClient = useQueryClient();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cardId = useGetMatchedCardId();
    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    const getCurrentUserQuery = useGetCurrentUserQuery();
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();

    return useMutation<void, unknown, number>({
        mutationKey: [QueryKeys.SET_WORKSPACE],
        mutationFn: (payload) =>
            setCurrentWorkspaceMutation.mutateAsync({ workspaceId: payload }),
        async onSuccess(_, variables) {
            dispatch(resetRecentStack());

            // Temporal solution, remove later
            const currentUser = await getCurrentUserQuery.refetch();
            if (!currentUser?.data) return;
            dispatch(setAuthUser(currentUser.data));
            //

            if (
                !pathname.includes('/chat') ||
                cardId === workSpace?.teamChat?.cardId
            ) {
                navigate(`/${variables}`, { replace: true });
                return;
            }

            navigate(pathname);
        },
        onError(error) {
            console.log(error, 'SET_WORKSPACE');
            Alerter.error('Error occurred while selecting workspace');
        },
        onSettled() {
            resetRequestPromise();
            queryClient.resetQueries();
        },
    });
};
