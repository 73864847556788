import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    ApiResponse,
    ApiResponseEmpty,
    CardTaskStatusEnum,
} from '../../../types';
import { Alerter } from '../../../utils';
import { ChatTasksListResponse, UpdateChatTaskStatusPayload } from '../types';
import { QueryKeys } from '../../../constants';
import { trpc } from '@core/api/trpc';

export const useUpdateCardTaskStatus = () => {
    const queryClient = useQueryClient();
    const updateTaskStatus = trpc.cardTasks.updateCardTaskStatus.useMutation();

    return useMutation<
        ApiResponseEmpty,
        unknown,
        { bodyPayload: UpdateChatTaskStatusPayload; isCardArchived: boolean }
    >({
        mutationKey: [QueryKeys.UPDATE_TASK_STATUS],
        mutationFn: async (payload) => {
            const { cardTaskId, status } = payload.bodyPayload;
            const result = await updateTaskStatus.mutateAsync({
                cardTaskId,
                status,
            });
            return {
                success: result.success,
                status: result.status,
                errors: result.errors,
                messages: result.messages,
            };
        },
        onMutate: async (body) => {
            if (body.bodyPayload.cardId) {
                queryClient.setQueryData<
                    ApiResponse<ChatTasksListResponse> | undefined
                >(
                    [
                        QueryKeys.GET_CARD_TASKS,
                        body.bodyPayload.cardId,
                        body.bodyPayload.workspaceId,
                    ],
                    (oldData) => {
                        if (oldData) {
                            return {
                                ...oldData,
                                value: {
                                    ...oldData.value,
                                    yourTasksCount: --oldData.value
                                        .yourTasksCount,
                                    yourTasks: oldData.value.yourTasks.map(
                                        (elem) => {
                                            if (
                                                elem.id ===
                                                body.bodyPayload.cardTaskId
                                            ) {
                                                return {
                                                    ...elem,
                                                    status: body.bodyPayload
                                                        .status,
                                                };
                                            }
                                            return elem;
                                        },
                                    ),
                                },
                            };
                        }

                        return oldData;
                    },
                );
            }
        },
        onSuccess: (data, payload) => {
            if (!data.success) {
                return;
            }

            Alerter.success('Task status was successfully updated!');
            queryClient.invalidateQueries({
                queryKey: [
                    QueryKeys.GET_CARD_TASKS,
                    payload.bodyPayload.cardId,
                    payload.bodyPayload.workspaceId,
                ],
            });

            queryClient.setQueryData<ApiResponse<number> | undefined>(
                [
                    QueryKeys.GET_USER_TASKS_COUNT,
                    payload.bodyPayload.workspaceId,
                ],
                (oldData) => {
                    if (oldData && !payload.isCardArchived) {
                        const newValue =
                            payload.bodyPayload.status ===
                            CardTaskStatusEnum.TODO
                                ? oldData.value + 1
                                : oldData.value
                                  ? oldData.value - 1
                                  : 0;
                        return {
                            ...oldData,
                            value: newValue,
                        };
                    }

                    return oldData;
                },
            );
        },
    });
};
