import { Box, HStack, Text } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { Icon } from '../../../components';
import { colors } from '../../../theme/colors';
import { confModeModalAtom } from '../../../atoms/modals.atoms';

interface ConfModeButtonProps {
    name?: string;
    iconName?: string;
}

export const ConfModeButton = ({
    name = 'Conference Mode™',
    iconName = 'door',
}: ConfModeButtonProps) => {
    const setConfModeModal = useSetAtom(confModeModalAtom);

    const handleOpenConfMode = () => {
        setConfModeModal({ isOpen: true });
    };

    return (
        <Box
            w="full"
            p={1}
            borderRadius="4px"
            bg="transparent"
            transition="background 0.3s ease"
            _hover={{
                bg: 'transparentLight.10',
            }}
        >
            <Box
                as="button"
                width="100%"
                display="block"
                transition=".3s"
                cursor="pointer"
                onClick={handleOpenConfMode}
                _hover={{
                    textDecoration: 'none',
                }}
            >
                <HStack align="center" justify="space-between">
                    <HStack align="center" spacing={2}>
                        <Icon
                            name={iconName}
                            color={colors.secondary}
                            width="16px"
                            height="16px"
                        />
                        <Text lineHeight="16px" color="secondary">
                            {name}
                        </Text>
                    </HStack>
                </HStack>
            </Box>
        </Box>
    );
};
