import {
    useGetCurrentUserQuery,
    useGetCurrentUserWorkspacesQuery,
    useSetCurrentWorkspaceMutation,
} from '@core/api';
import { Avatar, Text, VStack, Center } from '@chakra-ui/react';

import css from './index.module.css';

const Selector = () => {
    const getCurrentUserQuery = useGetCurrentUserQuery({ enabled: false });

    const getCurrentUserWorkspacesQuery = useGetCurrentUserWorkspacesQuery();

    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();

    if (getCurrentUserWorkspacesQuery.isError) return <>error</>;

    if (getCurrentUserWorkspacesQuery.isLoading) return <>loading</>;

    if (!getCurrentUserWorkspacesQuery.data?.length) {
        return (
            <Center h="200px">
                <VStack spacing={6}>
                    <Text textAlign="center" color="gray.600" fontSize="md">
                        You don't have any workspaces yet
                    </Text>
                </VStack>
            </Center>
        );
    }

    const handleSelectWorkspace = async (workspaceId: number) => {
        await setCurrentWorkspaceMutation.mutateAsync({ workspaceId });

        await getCurrentUserQuery.refetch();
    };

    return (
        <div className={css.nav}>
            {getCurrentUserWorkspacesQuery.data.map((workspace) => (
                <button
                    key={workspace.id}
                    className={css.selectorItem}
                    onClick={() => handleSelectWorkspace(workspace.id)}
                >
                    <Avatar
                        size="2xs"
                        name={workspace.name}
                        src={workspace.avatarThumbnailUrl}
                        className={css.selectorItemAvatar}
                    />

                    <span>{workspace.name}</span>
                </button>
            ))}
        </div>
    );
};

export default Selector;
