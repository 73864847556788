import { trpc } from '../trpc';

export function useGetWorkspaceMembersQuery(workspaceId: string) {
    return trpc.workspace.getWorkspaceMembers.useQuery();
    // return useQuery<WorkspaceMembersResult>(
    // 	{
    // 		queryKey: [QUERY_KEY_GET_WORKSPACE_MEMBERS, workspaceId],
    // 		queryFn: () => getWorkspaceMembers(workspaceId),
    // 	},
    // 	queryClientForApi
    // );
}
