import React from 'react';
import {
    Avatar,
    Box,
    Flex,
    HStack,
    IconButton,
    Skeleton,
    Text,
} from '@chakra-ui/react';
import { getAvatarBg } from '../../../utils';
import { useUpdateCardTrackStatus } from '../../../queries';
import { Icon } from '../../Icon/Icon';
import { TgDataForCard } from '../../../types';
import { colors } from '../../../theme/colors';
import { useAvatar } from '../hooks';
import { useAtom } from 'jotai';
import { cardTitlesAtom } from '../../../atoms/cardTitlesAtom';

interface CardHeaderProps {
    title: string | React.ReactNode;
    isLoaded?: boolean;
    chatTelegramId: number;
    chatAccessHash: string;
    lastTgChatMessageDate: number | null;
    trackedLastTelegramMessageUnixTime?: number | null;
    cardId: number;
    workspaceId?: number;
    cachedData?: TgDataForCard;
    onArchiveClick: () => void;
    isArchiveLoading: boolean;
    controlIconsEnabled: boolean;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
    title,
    isLoaded,
    chatTelegramId,
    chatAccessHash,
    lastTgChatMessageDate,
    trackedLastTelegramMessageUnixTime,
    cardId,
    workspaceId,
    onArchiveClick,
    isArchiveLoading,
    controlIconsEnabled,
}) => {
    const updateCardTrackStatusMutation = useUpdateCardTrackStatus(false);
    const [cardTitles] = useAtom(cardTitlesAtom);
    const chatTitle = cardTitles[cardId] || '';

    const extractTitleText = (titleElement: any): string => {
        if (typeof titleElement === 'string') return titleElement;
        if (titleElement?.props?.children?.[0]?.props?.children) {
            return titleElement.props.children[0].props.children;
        }
        return '';
    };

    const titleText = extractTitleText(title);
    const avatar = useAvatar(chatTelegramId?.toString(), chatAccessHash);

    const onChangeTrackState = (
        currentMessageDate: number | null,
        cardMessageDate?: number | null,
    ) => {
        const isLastCardMessageIgnored = currentMessageDate === cardMessageDate;
        updateCardTrackStatusMutation.mutate({
            cardId,
            workspaceId: workspaceId || 0,
            trackedLastTelegramMessageUnixTime: isLastCardMessageIgnored
                ? null
                : currentMessageDate,
        });
    };

    const renderTitle = () => {
        const displayText = typeof title === 'string' ? title : titleText;

        if (chatTitle) {
            return (
                <Flex gap="4px">
                    <Text fontWeight={500}>{chatTitle}</Text>
                    <Text color="#B3B3B3" fontWeight={500}>{displayText}</Text>
                </Flex>
            );
        }
        return displayText || '';
    };

    return (
        <HStack spacing={1} pb="6px" w="full" position="relative">
            <Box
                w={isLoaded ? '14px' : '24px'}
                minW={isLoaded ? '14px' : '24px'}
                h={isLoaded ? '14px' : '24px'}
                borderRadius="2px"
                overflow="hidden"
            >
                <Skeleton
                    w="100%"
                    h="100%"
                    borderRadius="2px"
                    startColor="gray.40"
                    endColor="gray.20"
                    isLoaded={isLoaded}
                >
                    <Avatar
                        src={avatar || ''}
                        w="100%"
                        h="100%"
                        borderRadius="2px"
                        name={typeof title === 'string' ? title : ''}
                        color="white"
                        bg={getAvatarBg(chatTelegramId)}
                        sx={{
                            '> div': {
                                fontSize: '8px',
                            },
                        }}
                    />
                </Skeleton>
            </Box>
            <Skeleton
                isLoaded={isLoaded}
                h="24px"
                startColor="gray.40"
                endColor="gray.20"
                flex={1}
                noOfLines={1}
            >
                <Text
                    as="span"
                    fontWeight={500}
                    maxW="100%"
                    isTruncated={true}
                    title={
                        chatTitle && typeof title === 'string'
                            ? `${chatTitle}   ${titleText}`
                            : undefined
                    }
                    lineHeight="24px"
                >
                    {renderTitle()}
                </Text>
            </Skeleton>

            <Flex
                position="absolute"
                top="0px"
                right="0px"
                gap={'4px'}
                transition="all 0.3s ease"
                opacity={0}
                _groupHover={{
                    opacity: controlIconsEnabled ? 1 : 0,
                }}
            >
                <IconButton
                    aria-label="To track conversation on/off"
                    transition="visibility 0.3s ease, opacity 0.3s ease"
                    minW="auto"
                    boxSize="24px"
                    variant="outline"
                    bg="gray.10"
                    borderRadius="4px"
                    title={
                        trackedLastTelegramMessageUnixTime ===
                        lastTgChatMessageDate
                            ? 'Mark as unanswered'
                            : 'Mark as answered'
                    }
                    isLoading={updateCardTrackStatusMutation.isPending}
                    onClick={(e) => {
                        e.stopPropagation();
                        onChangeTrackState(
                            lastTgChatMessageDate,
                            trackedLastTelegramMessageUnixTime,
                        );
                    }}
                >
                    <Icon
                        color={colors.secondary}
                        name={
                            trackedLastTelegramMessageUnixTime ===
                            lastTgChatMessageDate
                                ? 'eye'
                                : 'eye-off'
                        }
                        height="16"
                        width="16"
                    />
                </IconButton>
                <IconButton
                    aria-label="To track conversation on/off"
                    transition="visibility 0.3s ease, opacity 0.3s ease"
                    minW="auto"
                    boxSize="24px"
                    variant="outline"
                    bg="gray.10"
                    borderRadius="4px"
                    title={'Archive'}
                    isLoading={isArchiveLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        onArchiveClick();
                    }}
                >
                    <Icon
                        color={colors.secondary}
                        name={'trash'}
                        height="16"
                        width="16"
                    />
                </IconButton>
            </Flex>
        </HStack>
    );
};
