import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Flex,
    Textarea,
    FormLabel,
    Button,
    Text,
    Avatar,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import {
    Icon,
    Select,
    SelectItem,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverClose,
} from '../../../../components';
import { useCreateCardTask } from '../../queries';
import { trpc } from '../../../../core/api/trpc';
import { useUpdateTask } from '../../../../queries';
import { useTeamMembersList, useGetWorkspaceId } from '../../../../hooks';
import { ReminderField } from '../ReminderField';
import styles from './TaskPopup.module.css';
import { convertDateToReminderPayload } from '../../utils/convertDateToReminderPayload';
import { getReminderMinDate } from '../../../../utils';

interface TaskPopupProps {
    isOpen: boolean;
    onClose: VoidFunction;
    children: ReactElement;
    cardId?: number;
    onToggle?: VoidFunction;
    currentPriorityId?: number;
    currentDescription?: string;
    currentAssignedUserId?: number;
    taskId?: number;
    isTaskPage?: boolean;
    chatTelegramId?: number;
    reminderDate?: string | null;
}

export const TaskPopup = React.forwardRef<
    HTMLDivElement | null,
    TaskPopupProps
>(
    (
        {
            isOpen,
            onClose,
            children,
            cardId,
            currentPriorityId,
            currentDescription,
            currentAssignedUserId,
            onToggle,
            taskId,
            isTaskPage,
            chatTelegramId,
            reminderDate,
        },
        ref,
    ) => {
        const workspaceId = useGetWorkspaceId();
        const createTaskMutation = useCreateCardTask();
        const updateTaskMutation = useUpdateTask(isTaskPage);

        const { allTeamMembersWithAvatars, chatTeamMemberIds } =
            useTeamMembersList(chatTelegramId || 0);

        console.log('chatTeamMemberIds', allTeamMembersWithAvatars);

        const markChatAsReadMutation =
            trpc.messages.markChatAsRead.useMutation();

        const [description, setDescription] = useState('');
        const [priorityId, setPriorityId] = useState<string | null>('2');
        const [assignId, setAssignId] = useState<string | null>(null);
        const [remindDate, setRemindDate] = useState<Date | null>(null);

        const resetAllState = () => {
            setDescription('');
            setPriorityId(null);
            setAssignId(null);
            setRemindDate(null);
        };

        const onSubmitTask = (
            description: string,
            priorityId: number,
            userAssignId: number,
            taskId?: number,
        ) => {
            if (!workspaceId || !cardId) {
                return;
            }

            if (taskId) {
                updateTaskMutation.mutate(
                    {
                        workspaceId: workspaceId,
                        cardTaskId: taskId,
                        userId: userAssignId,
                        priorityId,
                        taskDescription: description,
                        assignedToTelegramIds: chatTeamMemberIds,
                        remindDate: remindDate
                            ? convertDateToReminderPayload(remindDate)
                            : null,
                    },
                    {
                        onSuccess: async () => {
                            onClose();
                            resetAllState();
                        },
                    },
                );
                return;
            }

            createTaskMutation.mutate(
                {
                    workspaceId: workspaceId,
                    cardId: cardId,
                    userId: userAssignId,
                    priorityId,
                    description,
                    assignedToTelegramIds: chatTeamMemberIds,
                    remindDate: remindDate
                        ? convertDateToReminderPayload(remindDate)
                        : undefined,
                },
                {
                    onSuccess: async () => {
                        onClose();
                        if (workspaceId && cardId) {
                            await markChatAsReadMutation.mutateAsync({
                                workspaceId: Number(workspaceId),
                                cardId: Number(cardId),
                            });
                        }
                        resetAllState();
                    },
                },
            );
        };

        useEffect(() => {
            if (currentPriorityId && isOpen) {
                setPriorityId(currentPriorityId.toString());
            }
            if (currentDescription && isOpen) {
                setDescription(currentDescription);
            }
            if (currentAssignedUserId && isOpen) {
                setAssignId(currentAssignedUserId.toString());
            }
            if (reminderDate && isOpen) {
                setRemindDate(dayjs(reminderDate).toDate());
            }
            if (!isOpen) {
                resetAllState();
            }
        }, [
            currentPriorityId,
            currentDescription,
            currentAssignedUserId,
            reminderDate,
            isOpen,
        ]);

        const isSubmitDisabled = useMemo(() => {
            const reminderMinDate = getReminderMinDate();

            if (remindDate && remindDate < reminderMinDate) {
                return true;
            }

            return !description.length || !priorityId || !assignId;
        }, [description, priorityId, assignId, remindDate]);

        const isReminderOutdated = useMemo(() => {
            const reminderMinDate = getReminderMinDate();

            return remindDate && remindDate < reminderMinDate;
        }, [remindDate]);

        return (
            <Popover open={isOpen} onOpenChange={onToggle} modal={true}>
                <PopoverTrigger asChild={true}>{children}</PopoverTrigger>
                <PopoverContent
                    ref={ref}
                    sideOffset={5}
                    align="end"
                    className={styles.TaskPopoverContent}
                >
                    <Flex
                        flexDirection="row"
                        padding="8px"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                        align="center"
                        justify="space-between"
                    >
                        <Text color="primary" fontSize="13px" fontWeight="600">
                            {taskId ? 'Edit' : 'New'} task
                        </Text>
                        <PopoverClose aria-label="Close" onClick={onClose}>
                            <Icon name="close-cross" height="16" width="16" />
                        </PopoverClose>
                    </Flex>
                    <Flex
                        padding="8px"
                        gap="8px"
                        flexDirection="column"
                        borderBottomWidth="1px"
                        borderBottomColor="gray.15"
                    >
                        <Box>
                            <FormLabel
                                textTransform="uppercase"
                                color="gray.35"
                                fontSize="11px"
                                fontWeight="600"
                                mb="4px"
                            >
                                Task
                            </FormLabel>
                            <Textarea
                                bg="#00000005"
                                border="1px solid"
                                borderColor="gray.20"
                                borderRadius="4px"
                                padding="8px"
                                fontSize="13px"
                                fontWeight="400"
                                value={description}
                                resize="none"
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <FormLabel
                                textTransform="uppercase"
                                color="gray.35"
                                fontSize="11px"
                                fontWeight="600"
                                mb="4px"
                            >
                                Priority
                            </FormLabel>
                            <Select
                                placeholder="Priority"
                                rootProps={{
                                    value: priorityId || undefined,
                                    defaultValue: priorityId || '2',
                                    onValueChange: (value) =>
                                        setPriorityId(value),
                                }}
                            >
                                <SelectItem
                                    value="1"
                                    selectItemIndicatorProps={{
                                        style: {
                                            left: 'calc(100% - 25px)',
                                        },
                                    }}
                                    style={{
                                        paddingLeft: '8px',
                                    }}
                                >
                                    Low
                                </SelectItem>
                                <SelectItem
                                    value="2"
                                    selectItemIndicatorProps={{
                                        style: {
                                            left: 'calc(100% - 25px)',
                                        },
                                    }}
                                    style={{
                                        paddingLeft: '8px',
                                    }}
                                >
                                    Medium
                                </SelectItem>
                                <SelectItem
                                    value="3"
                                    selectItemIndicatorProps={{
                                        style: {
                                            left: 'calc(100% - 25px)',
                                        },
                                    }}
                                    style={{
                                        paddingLeft: '8px',
                                    }}
                                >
                                    High
                                </SelectItem>
                            </Select>
                        </Box>
                        <Box>
                            <FormLabel
                                textTransform="uppercase"
                                color="gray.35"
                                fontSize="11px"
                                fontWeight="600"
                                mb="4px"
                            >
                                Assign to
                            </FormLabel>
                            <Select
                                placeholder="Assign to"
                                rootProps={{
                                    value: assignId || undefined,
                                    onValueChange: (value) =>
                                        setAssignId(value),
                                }}
                                triggerStyles={{
                                    position: 'relative',
                                }}
                                triggerClassName={`SingleLine ${assignId ? 'AvatarSpacing' : ''}`}
                                removePortal={true}
                            >
                                {allTeamMembersWithAvatars?.map((user) => (
                                    <SelectItem
                                        key={user.id}
                                        value={`${user.id}`}
                                        selectItemIndicatorProps={{
                                            style: {
                                                left: 'calc(100% - 25px)',
                                            },
                                        }}
                                        style={{
                                            position: 'relative',
                                        }}
                                        className="SingleLine"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        <Avatar
                                            position="absolute"
                                            left="5px"
                                            top="50%"
                                            transform="translateY(-50%)"
                                            src={user.avatar || ''}
                                            width="16px"
                                            height="16px"
                                            bg="transparent"
                                            icon={
                                                <Icon
                                                    name="unknown-user"
                                                    width="16px"
                                                    height="16px"
                                                />
                                            }
                                        />
                                        {`${user.firstName || 'unknown'} ${user.lastName || ''}`}
                                    </SelectItem>
                                ))}
                            </Select>
                        </Box>
                        <ReminderField
                            date={remindDate}
                            onChangeDate={(date) => setRemindDate(date)}
                            label="Remind on (optional)"
                            fieldTooltip="Provide the correct reminder date or clear it"
                        />
                    </Flex>
                    <Box padding="8px">
                        <Button
                            variant="popup"
                            w="100%"
                            isDisabled={isSubmitDisabled}
                            title={
                                !!taskId && isReminderOutdated
                                    ? 'Provide the correct reminder date or clear it'
                                    : undefined
                            }
                            isLoading={
                                updateTaskMutation.isPending ||
                                createTaskMutation.isPending
                            }
                            onClick={() => {
                                if (!priorityId || !assignId) {
                                    return;
                                }

                                onSubmitTask(
                                    description,
                                    +priorityId,
                                    +assignId,
                                    taskId,
                                );
                            }}
                        >
                            {taskId ? 'Update' : 'Create'}
                        </Button>
                    </Box>
                </PopoverContent>
            </Popover>
        );
    },
);
