import { useEffect } from 'react';
import { useGetCurrentUserWorkspaceFoldersQuery } from '@core/api/queries/current-user-workspace-folders';
import { useGetWorkspaceId } from '../hooks';
import { useTelegramLock } from '../services/telegram/lockContext';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { useGetCurrentUserQuery } from '@core/api';
import { FolderChatSyncChatsMutationPayload } from '@core/api/types/sync-chats';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { QueryKeys } from '../constants';
import { allowedForSyncChatTypes } from '../constants';
import { trpc } from '@core/api/trpc';
import { useQueryClient } from '@tanstack/react-query';

export const useAutoSync = () => {
    const queryClient = useQueryClient();
    const utils = trpc.useUtils();
    const lock = useTelegramLock();
    const { isSynced } = useSelector((state: RootStore) => state.syncState);
    const workspaceId = useGetWorkspaceId();
    const getCurrentUserQuery = useGetCurrentUserQuery();
    const syncCardsMutation = trpc.workspace.syncCards.useMutation();
    const { data: userFolders } = useGetCurrentUserWorkspaceFoldersQuery();
    useGetBoardCards();

    // Function to perform sync
    const performSync = async () => {
        if (
            !isSynced ||
            !userFolders?.folders ||
            !getCurrentUserQuery.data?.isOnboarded
        ) {
            return;
        }

        try {
            await lock.scheduleBackgroundEvent(async (tg) => {
                const chatFolders = await tg.methods.proxy.fetchChatFolders();
                if (!chatFolders) return;

                const selectedChatFoldersIds = userFolders.folders.map(
                    (folder) => folder.telegramFolderId,
                );

                const chatsIds = selectedChatFoldersIds.flatMap((folderId) => {
                    return chatFolders.byId[folderId].includedChatIds;
                });

                const resolvedChatsInfos = await Promise.allSettled(
                    chatsIds.map((chatId) =>
                        tg.custom.proxy.getChatById(Number(chatId)),
                    ),
                );

                const chatsByIds = resolvedChatsInfos.reduce<
                    Record<string, any>
                >((acc, chat) => {
                    if (
                        chat.status === 'fulfilled' &&
                        chat.value?.chatShortInfo &&
                        allowedForSyncChatTypes.includes(
                            chat.value.chatShortInfo.type,
                        )
                    ) {
                        acc[chat.value.chatShortInfo.id] =
                            chat.value.chatShortInfo;
                    }
                    return acc;
                }, {});

                const foldersPayload = selectedChatFoldersIds.map(
                    (folderId) => {
                        return {
                            telegramFolderId: folderId,
                            chats: chatFolders.byId[
                                folderId
                            ].includedChatIds.reduce<
                                FolderChatSyncChatsMutationPayload[]
                            >((acc, chatId) => {
                                if (!chatsByIds[chatId]) return acc;
                                return acc.concat([
                                    {
                                        telegramChatId: Number(chatId),
                                        name:
                                            chatsByIds[chatId].title ||
                                            'Unknown chat name',
                                    },
                                ]);
                            }, []),
                        };
                    },
                );

                await syncCardsMutation.mutateAsync({
                    workspaceId: workspaceId,
                    folders: foldersPayload,
                });

                utils.workspace.getWorkspaceCards.invalidate();

                queryClient.invalidateQueries({
                    queryKey: [
                        QueryKeys.TG_CHATS,
                        workspaceId.toString(),
                        'individual',
                    ],
                });
                await getCurrentUserQuery.refetch();
            });
        } catch (error) {
            console.error('Auto sync failed:', error);
        }
    };

    // Regular sync effect
    useEffect(() => {
        performSync();
    }, [isSynced, userFolders?.folders]);

    // Window focus effect
    useEffect(() => {
        const handleFocus = () => {
            performSync();
        };

        const handlePageShow = (e: PageTransitionEvent) => {
            if (e.persisted) {
                // Page was restored from bfcache
                performSync();
            }
        };

        window.addEventListener('focus', handleFocus);
        window.addEventListener('pageshow', handlePageShow);

        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);
};
