import { useState, useEffect } from 'react';
import { Box, Text, Flex, VStack } from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import {
    modalSubHeaderStyleProps,
    settingsRegularTextStyleProps,
} from '../../../modals/Onboarding/steps/helper';
import {
    ConfirmDeleteModal,
    StageItem,
    PipelineControlButtons,
    PipelineAddStageButton,
    BlockerDialog,
} from '../components';
import { PipelineForm, UpdateStagesPayload } from '../types';
import { PipelineDefaultStages } from '../../../constants';
import { Loader } from '../../../components';
import { useUnsavedChangesChecker } from '../../../hooks';
import { trpc } from '@core/api/trpc';

const ARCHIVE_STATUS_ID = 6;

const PipelineSettingsPage = () => {
    const [stageIdToDelete, setStageIdToDelete] = useState<null | {
        index: number;
        value: number | string;
    }>(null);

    const {
        control,
        formState: { isDirty },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm<PipelineForm>();

    useUnsavedChangesChecker(isDirty);

    const { data, isLoading } = trpc.workspace.getCardStatuses.useQuery(
        { includeArchive: true },
        {
            select: (data) => data.value.statuses,
        },
    );
    const saveStagesChangesMutation =
        trpc.workspace.saveStagesChanges.useMutation();

    const { fields, append, remove, move } = useFieldArray({
        control,
        name: 'stages',
    });

    const onSubmit = (data: PipelineForm) => {
        const payload: UpdateStagesPayload = {
            deletedWorkspaceStagesIds: data.deletedIds || [],
            newStages: [],
            updatedWorkspaceStages: [],
        };

        data.stages?.forEach((elem, index) => {
            if (typeof elem.identifier === 'string') {
                payload.newStages.push(elem.name);
            } else {
                payload.updatedWorkspaceStages.push({
                    id: elem.identifier,
                    name: elem.name,
                    position: index + 1,
                });
            }
        });

        saveStagesChangesMutation.mutate(payload);
    };

    const onAddStage = () => {
        append({
            name: PipelineDefaultStages[fields.length].name,
            identifier: uuid(),
        });
    };

    const onDeleteConfirm = () => {
        if (typeof stageIdToDelete?.value === 'number') {
            setValue('deletedIds', [
                ...(getValues('deletedIds') || []),
                stageIdToDelete.value,
            ]);
        }
        remove(stageIdToDelete?.index);
        setStageIdToDelete(null);
    };

    const onMoveStage = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        move(index, newIndex);
    };

    useEffect(() => {
        if (data) {
            reset({
                stages: data.map((elem) => ({
                    name: elem.name,
                    identifier: elem.id,
                })),
            });
        }
    }, [data]);

    const onDeleteStage = (id: number | string, index: number) => {
        if (typeof id === 'string') {
            remove(index);
        } else {
            setStageIdToDelete({
                index,
                value: id,
            });
        }
    };

    const onCancelEditing = () => {
        reset({
            stages:
                data?.map((elem) => ({
                    name: elem.name,
                    identifier: elem.id,
                })) || [],
            deletedIds: [],
        });
    };

    return (
        <Box as="section" id="settings-pipeline-layout" h="100%" w="100%">
            <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <Box
                    bg="white"
                    h="100%"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="gray.20"
                    overflowY="auto"
                    p="32px"
                >
                    <Flex flexDirection="column" maxW="container.sm">
                        <PipelineControlButtons
                            isSaveLoading={saveStagesChangesMutation.isPending}
                            isActive={isDirty}
                            onCancel={onCancelEditing}
                        />
                        <Text {...modalSubHeaderStyleProps}>
                            Pipeline stages
                        </Text>
                        <Text
                            {...settingsRegularTextStyleProps}
                            mt="12px"
                            mb="16px"
                        >
                            Add up to 10 stages in your pipeline
                        </Text>
                        <Box
                            bg="gray.10"
                            borderRadius="4px"
                            border="1px solid"
                            borderColor="gray.20"
                        >
                            <Box p="16px">
                                <Text
                                    textTransform="uppercase"
                                    color="gray.35"
                                    fontWeight={600}
                                    fontSize="11px"
                                    mb="14px"
                                    mt="4px"
                                >
                                    Stages
                                </Text>
                                {isLoading ? (
                                    <Loader
                                        spinnerSize="md"
                                        centerHeight="200px"
                                    />
                                ) : (
                                    <VStack spacing="2px" align="stretch">
                                        {fields.map((item, index) => {
                                            const isArchive =
                                                data?.[index]?.typeId ===
                                                ARCHIVE_STATUS_ID;
                                            return (
                                                <StageItem
                                                    key={item.id}
                                                    index={index}
                                                    name={item.name}
                                                    control={control}
                                                    onDelete={() =>
                                                        onDeleteStage(
                                                            item.identifier,
                                                            index,
                                                        )
                                                    }
                                                    onMoveUp={() =>
                                                        onMoveStage(index, 'up')
                                                    }
                                                    onMoveDown={() =>
                                                        onMoveStage(
                                                            index,
                                                            'down',
                                                        )
                                                    }
                                                    isFirst={index === 0}
                                                    isLast={
                                                        index ===
                                                        fields.length - 1
                                                    }
                                                    isArchive={isArchive}
                                                />
                                            );
                                        })}
                                    </VStack>
                                )}
                            </Box>
                            <PipelineAddStageButton
                                onClick={onAddStage}
                                isDisabled={fields.length >= 10}
                            />
                        </Box>
                    </Flex>
                </Box>
                <ConfirmDeleteModal
                    onClose={() => {
                        setStageIdToDelete(null);
                    }}
                    isOpen={!!stageIdToDelete}
                    onConfirm={onDeleteConfirm}
                    confirmLoading={false}
                    title="Delete stage"
                    description="This will permanently delete this stage and reassign its deals to the initial stage"
                    confirmLabel="Delete stage"
                />
                <BlockerDialog isDirty={isDirty} />
            </form>
        </Box>
    );
};

export default PipelineSettingsPage;
