import React, { useMemo, useRef, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useSendMessage } from '../../queries';
import { RootStore } from '../../../../../../redux/createStore';
import { ApiResponse, BaseUserData } from '../../../../../../types';
import { QueryKeys } from '../../../../../../constants';
import { Mention, MentionsInput } from '../../../../../../components';
import {
    extractMarkedMentions,
    removeMentionsMarkup,
    extractUnmarkedMentions,
} from '../../../../utils';
import { useChats } from '../../../../../../hooks/useChats';
import { MessageTaggedUsers } from '../../../../types';
import { trpc } from '@core/api/trpc';

interface ITextareaProps {
    cardId: number;
    workspaceId?: number;
    onSendMessage?: (message: string, taggedUsers?: number[]) => void;
    isLoading?: boolean;
}

export const Textarea: React.FC<ITextareaProps> = ({
    cardId,
    workspaceId,
    onSendMessage,
    isLoading = false,
}) => {
    const queryClient = useQueryClient();
    const { state } = useLocation();

    const { chatTelegramId } = state || {};

    const sendMessageMutation = useSendMessage(workspaceId, cardId);

    const storeAvatars = useSelector((state: RootStore) => state.avatars);
    const telegramUserId = useSelector(
        (state: RootStore) => state.auth.user.telegramUserId,
    );

    const { getChatInfo } = useChats();

    const chat = chatTelegramId ? getChatInfo(chatTelegramId.toString()) : null;
    const cachedUsers = useSelector(
        (state: RootStore) => state.telegramState.users,
    );

    const [text, setText] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const teamMembersQuery = trpc.cards.getAllTeamMembers.useQuery(
        {
            workspaceId: workspaceId || 0,
        },
        {
            enabled: !!workspaceId,
        },
    );

    const allTeamMembersData = queryClient.getQueryData<
        ApiResponse<{ members: BaseUserData[] }>
    >([QueryKeys.GET_WORKSPACE_MEMBERS, workspaceId]);

    const chatTeamMembers = useMemo(() => {
        if (chat?.type === 'chatTypePrivate') {
            return [];
        }

        const teamMembersFromQuery = teamMembersQuery.data || [];
        const allTeamMembers =
            teamMembersFromQuery.length > 0
                ? teamMembersFromQuery
                : allTeamMembersData?.value?.members || [];

        // Define a type for team members to match the actual structure
        type TeamMember = {
            firstName: string | null;
            lastName: string | null;
            id: number;
            telegramUserId: number;
            isOnboarded?: boolean;
            timeZoneId?: string | null;
            changedAt?: string | null;
            createdAt?: string | null;
            thumbnailId?: number | null;
            avatarId?: number | null;
        };

        // Cast allTeamMembers to TeamMember[] to ensure type compatibility
        const typedTeamMembers = allTeamMembers as unknown as TeamMember[];

        const teamMembers = new Map<number, TeamMember>();

        // Populate the map with team members
        typedTeamMembers.forEach((obj) => {
            if (obj.telegramUserId) {
                teamMembers.set(obj.telegramUserId, obj);
            }
        });

        return typedTeamMembers
            .filter(
                (teamMember) =>
                    teamMember.telegramUserId &&
                    teamMembers.has(teamMember.telegramUserId) &&
                    teamMember.telegramUserId !== telegramUserId,
            )
            .map((teamMember) => ({
                id: teamMember.telegramUserId || 0,
                username:
                    teamMember.telegramUserId &&
                    cachedUsers?.[teamMember.telegramUserId]
                        ? cachedUsers?.[teamMember.telegramUserId]?.username
                        : null,
                avatar: storeAvatars[`${teamMember.telegramUserId}`],
                name: `${teamMember.firstName || ''}${teamMember.lastName ? ` ${teamMember.lastName}` : ''}`,
            }));
    }, [
        chat,
        storeAvatars,
        cachedUsers,
        telegramUserId,
        allTeamMembersData?.success,
        teamMembersQuery.data,
    ]);

    const onSend = () => {
        if (text.trim() === '') {
            return;
        }

        const plainText = removeMentionsMarkup(text);
        const markedMentions = extractMarkedMentions(text);
        const unmarkedMentions = extractUnmarkedMentions(text, chatTeamMembers);

        const taggedUserIds = [...markedMentions, ...unmarkedMentions].map(
            (mention: MessageTaggedUsers) => mention.taggedUserTelegramIds,
        );

        if (onSendMessage) {
            onSendMessage(plainText, taggedUserIds);
            setText('');
        } else {
            console.warn('No onSendMessage provided to Textarea component');
        }
    };

    const handleEnterInput = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (ev.key === 'Enter' && !ev.shiftKey) {
            ev.preventDefault();
            ev.stopPropagation();

            onSend();
            return;
        }
    };

    const queryMentionUsers = (
        q: string,
        callback: (val: unknown[]) => void,
    ) => {
        // Define a type for the team member items
        type TeamMemberItem = {
            id: number;
            username: string | null;
            name: string;
            avatar?: string;
        };

        const matchedSuggestions = chatTeamMembers
            .filter((item: TeamMemberItem) => {
                const isUsernameMatched =
                    item.username &&
                    item.username.toLowerCase().startsWith(q.toLowerCase());
                const isNameMatched = item.name
                    .toLowerCase()
                    .startsWith(q.toLowerCase());

                return isUsernameMatched || isNameMatched;
            })
            .map((user: TeamMemberItem) => ({
                name: user.name,
                id: user.id,
                photoURL: user.avatar || '',
                username: user.username,
            }));

        callback(matchedSuggestions);
    };
    const onWrapperClick = () => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    return (
        <Flex align="center" w="100%" onClick={onWrapperClick}>
            <MentionsInput
                inputRef={textareaRef}
                value={text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setText(e.target.value)
                }
                forceSuggestionsAboveCursor={true}
                placeholder="Type a message to the team..."
                onKeyDown={handleEnterInput}
            >
                <Mention
                    displayTransform={(
                        _id: string,
                        name: string,
                        username: string | null,
                    ) => (username ? `@${username}` : `${name}`)}
                    trigger="@"
                    data={(q: string, callback: (val: unknown[]) => void) =>
                        queryMentionUsers(q, callback)
                    }
                    appendSpaceOnAdd={true}
                />
            </MentionsInput>
        </Flex>
    );
};
