import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { ModalWrapper } from '../../components';
import { ChatsSync } from '../Onboarding/steps/ChatsSync';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';
import { useQueryClient } from '@tanstack/react-query';
import { syncSettingsModalAtom } from '../../atoms/modals.atoms';

export const SyncSettingsModal = () => {
    const [syncSettingsModal, setSyncSettingsModal] = useAtom(
        syncSettingsModalAtom,
    );

    const queryClient = useQueryClient();

    const { workSpace } = useSelector((state: RootStore) => state.auth.user);

    return (
        <ModalWrapper
            isOpen={syncSettingsModal.isOpen}
            onClose={() => setSyncSettingsModal({ isOpen: false })}
        >
            <ChatsSync
                includeSkip={true}
                onFinish={() => {
                    setSyncSettingsModal({ isOpen: false });

                    if (!workSpace?.id) {
                        return;
                    }

                    queryClient.invalidateQueries({
                        queryKey: [QueryKeys.GET_TG_FOLDERS, workSpace.id],
                    });

                    queryClient.invalidateQueries({
                        queryKey: [QueryKeys.GET_BOARD_CARDS, workSpace.id],
                    });
                }}
            />
        </ModalWrapper>
    );
};
