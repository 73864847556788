import { Alerter } from '../utils';
import { trpc } from '@core/api/trpc';

export const useUpdateTask = (isTaskPage?: boolean) => {
    const utils = trpc.useUtils();
    return trpc.tasks.updateTask.useMutation({
        onSuccess: async () => {
            Alerter.success('Task successfully updated');
            utils.cardTasks.getCardTasks.invalidate();
        },
    });
};
