import React, { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
} from '../../../../../../components';
import { OwnerPopupItem } from './components/OwnerPopupItem';
import styles from './OwnerPopup.module.css';

interface OwnerPopupProps {
    isOpen: boolean;
    onClose: VoidFunction;
    children: ReactElement;
    teamMembers: Array<{
        id: number;
        firstName: string | null;
        lastName: string | null;
        thumbnail: string | null;
        telegramUserId: number;
    }>;
    workspaceId: number;
    cardId: number;
    onOwnerChange: (userId: number | null) => void;
    currentOwnerId?: number | null;
}

export const OwnerPopup = React.forwardRef<
    HTMLDivElement | null,
    OwnerPopupProps
>(
    (
        {
            isOpen,
            children,
            teamMembers,
            workspaceId,
            cardId,
            onClose,
            onOwnerChange,
            currentOwnerId,
        },
        ref,
    ) => {
        return (
            <Popover
                open={isOpen}
                onOpenChange={(open) => !open && onClose()}
                modal={false}
            >
                <PopoverTrigger asChild>{children}</PopoverTrigger>
                <PopoverContent
                    ref={ref}
                    sideOffset={0}
                    align="start"
                    side="bottom"
                    className={styles.OwnerPopoverContent}
                    style={{
                        zIndex: 1500,
                    }}
                >
                    <VStack
                        p="4px"
                        w="full"
                        align="flex-start"
                        spacing="2px"
                        maxH="300px"
                        overflow="auto"
                    >
                        {teamMembers.map((member) => (
                            <OwnerPopupItem
                                key={member.id}
                                member={member}
                                workspaceId={workspaceId}
                                cardId={cardId}
                                onOwnerChange={onOwnerChange}
                                isSelected={currentOwnerId === member.id}
                            />
                        ))}
                    </VStack>
                </PopoverContent>
            </Popover>
        );
    },
);
