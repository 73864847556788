import { Button, Text, VStack, Center } from '@chakra-ui/react';
import { IconSendOff } from '@tabler/icons-react';
import { useSetAtom } from 'jotai';
import { mergeCardModalAtom } from '@atoms/modals.atoms';

interface EmptyCardPlaceholderProps {
    cardId: number;
}

export const EmptyCardPlaceholder = ({ cardId }: EmptyCardPlaceholderProps) => {
    const setMergeCardModal = useSetAtom(mergeCardModalAtom);

    const handleMergeClick = () => {
        setMergeCardModal({
            isOpen: true,
            sourceCardId: cardId,
            targetCardId: null,
        });
    };

    return (
        <Center
            h="100%"
            bg="white"
            borderRadius="8px"
            border="2px solid #ebebeb"
        >
            <VStack spacing={3}>
                <IconSendOff size={48} color="gray" stroke={1} />
                <Text textAlign="center" color="gray.700">
                    No chats connected
                </Text>
                <Button
                    variant="outline"
                    onClick={handleMergeClick}
                    mt={3}
                    w="140px"
                >
                    Connect
                </Button>
            </VStack>
        </Center>
    );
};
