import { trpc } from '@core/api/trpc';

export type GetCurrentUserResult = {
    avatarThumbnailLink: string | null;
    avatarUrl: string | null;
    firstName: string | null;
    id: number;
    telegramUserId: number;
    lastName: string | null;
    isOnboarded: boolean;
    workSpace: {
        id: number;
        name: string;
        avatarUrl: string | null;
        avatarThumbnailUrl: string | null;
        teamChat: {
            id: number;
            name: string;
            cardId: number;
        } | null;
        isArchiveVisible: boolean;
        isAdmin: boolean;
    } | null;
    timeZoneId: string | null;
};

function useGetCurrentUserQuery(
    options?: Parameters<typeof trpc.users.getCurrentUser.useQuery>[1],
) {
    const userSelect = options?.select;
    return trpc.users.getCurrentUser.useQuery(undefined, {
        ...options,
        select: (data) => {
            const result = userSelect ? userSelect(data) : data;
            return result as GetCurrentUserResult;
        },
    });
}

export { useGetCurrentUserQuery };
