import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Box } from '@chakra-ui/react';

interface VirtualizedListProps<T> {
    items: T[];
    height: number | string;
    itemHeight: number;
    renderItem: (item: T, index: number) => React.ReactNode;
    overscan?: number;
}

export function VirtualizedList<T>({
    items,
    height,
    itemHeight,
    renderItem,
    overscan = 5,
}: VirtualizedListProps<T>) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [scrollTop, setScrollTop] = useState(0);
    const requestIdRef = useRef<number | null>(null);

    // Use a more efficient scroll handler with requestAnimationFrame
    const handleScroll = useCallback(() => {
        if (!containerRef.current) return;

        // Cancel any pending animation frame
        if (requestIdRef.current !== null) {
            cancelAnimationFrame(requestIdRef.current);
        }

        // Schedule a new animation frame
        requestIdRef.current = requestAnimationFrame(() => {
            if (containerRef.current) {
                setScrollTop(containerRef.current.scrollTop);
                requestIdRef.current = null;
            }
        });
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll, {
                passive: true,
            });
            return () => {
                container.removeEventListener('scroll', handleScroll);
                // Clean up any pending animation frame on unmount
                if (requestIdRef.current !== null) {
                    cancelAnimationFrame(requestIdRef.current);
                }
            };
        }
    }, [handleScroll]);

    // Calculate visible items - memoize calculations for better performance
    const containerHeight = typeof height === 'number' ? height : 300; // Default height
    const totalHeight = items.length * itemHeight;

    // Calculate the range of visible items
    const startIndex = Math.max(
        0,
        Math.floor(scrollTop / itemHeight) - overscan,
    );
    const endIndex = Math.min(
        items.length - 1,
        Math.floor((scrollTop + containerHeight) / itemHeight) + overscan,
    );

    const visibleItems = items.slice(startIndex, endIndex + 1);
    const offsetY = startIndex * itemHeight;

    // Prevent unnecessary re-renders by using inline styles for positioning
    const contentStyle = {
        position: 'absolute' as const,
        top: `${offsetY}px`,
        left: 0,
        right: 0,
        willChange: 'transform', // Hint to browser for optimization
    };

    // The Box component is used instead of a native div to ensure Chakra UI styling
    return (
        <Box
            ref={containerRef}
            height={height}
            overflowY="auto"
            position="relative"
            role="list" // Add ARIA role for accessibility
            css={{
                // Optimize scrolling performance with CSS
                WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '3px',
                },
            }}
        >
            <Box
                height={`${totalHeight}px`}
                position="relative"
                pointerEvents="auto"
            >
                <Box style={contentStyle}>
                    {visibleItems.map((item, index) =>
                        renderItem(item, startIndex + index),
                    )}
                </Box>
            </Box>
        </Box>
    );
}
