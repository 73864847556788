import React, { useMemo, useState } from 'react';
import { Box, Center } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loader, EmptyItemsText } from '../../../../components';
import { CardActivityItem } from '../../types';
import { useGetCardActivitiesList } from './queries';
import { ActivityItemSection } from './components';
// import { SignalRTopics, useSignalREffect } from '../../../../services';
import { useQueryClient } from '@tanstack/react-query';

interface ActivityProps {
    workspaceId?: number;
    cardId: number;
}

export const Activity: React.FC<ActivityProps> = ({ workspaceId, cardId }) => {
    const [cardActivities, setCardActivities] = useState<CardActivityItem[]>(
        [],
    );

    const { data, isPending } = useGetCardActivitiesList(
        workspaceId,
        cardId,
        cardActivities.length,
    );

    const combinedWithIncomingRows = useMemo(
        () => [...cardActivities, ...(data?.timeline || [])],
        [cardActivities, data?.timeline],
    );

    // useSignalREffect(
    //     SignalRTopics.NewInnerChatMessageActivity,
    //     (activity: NewInnerChatMessageActivity) => {
    //         console.log(
    //             'NewInnerChatMessageActivity message activity',
    //             activity,
    //         );
    //         setCardActivities((prev) => [activity, ...prev]);
    //     },
    //     [],
    // );

    // useSignalREffect(
    //     SignalRTopics.DeletedInnerChatMessage,
    //     (deletedActivity: DeletedInnerChatMessage) => {
    //         if (!deletedActivity.activityId) {
    //             return;
    //         }
    //         const newActivityIds =
    //             cardActivities?.map((activity) => activity.id) || [];
    //         if (newActivityIds.includes(deletedActivity.activityId)) {
    //             setCardActivities((prev) =>
    //                 prev.filter(
    //                     (activity) =>
    //                         activity.id !== deletedActivity.activityId,
    //                 ),
    //             );
    //             return;
    //         }
    //         const oldActivityIds =
    //             data?.timeline?.map((activity) => activity.id) || [];
    //         if (!oldActivityIds.includes(deletedActivity.activityId)) {
    //             return;
    //         }
    //         queryClient.invalidateQueries({
    //             queryKey: [
    //                 QueryKeys.GET_CARD_ACTIVITIES_LIST,
    //                 cardId,
    //                 workspaceId,
    //             ],
    //         });
    //     },
    //     [cardActivities, cardId, workspaceId, data],
    // );

    if (isPending) {
        return <Loader centerHeight="calc(100vh - 105px)" />;
    }

    return (
        <Box height="100%">
            {combinedWithIncomingRows.length ? (
                <InfiniteScroll
                    dataLength={combinedWithIncomingRows.length}
                    pullDownToRefresh={false}
                    refreshFunction={() => {}}
                    next={() => {}}
                    hasMore={false}
                    height="100%"
                    inverse={false}
                    loader={null}
                    style={{
                        maxHeight: 'calc(100vh - 150px)',
                        paddingTop: '24px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                    }}
                >
                    {combinedWithIncomingRows?.map((activity, index, arr) => (
                        <ActivityItemSection
                            key={activity.id}
                            index={index}
                            activitiesArr={arr}
                            {...activity}
                        />
                    ))}
                </InfiniteScroll>
            ) : (
                <Center height="100%">
                    <EmptyItemsText
                        text="No activity"
                        wrapperProps={{ flex: 0.8 }}
                    />
                </Center>
            )}
        </Box>
    );
};
