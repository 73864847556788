function getDid(): string {
    let did = localStorage.getItem('did');
    if (did) return did;

    did = crypto.randomUUID();
    localStorage.setItem('did', did);
    return did;
}

export const DID = getDid();
