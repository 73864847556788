import { createTRPCReact } from '@trpc/react-query';
import type { AppRouter } from '../../../../api-crm/src/server/router';
import { httpBatchLink, httpLink, loggerLink } from '@trpc/client';
import { store } from '../../redux/createStore';
import superjson from 'superjson';
import { QueryClient } from '@tanstack/react-query';

export const trpc = createTRPCReact<AppRouter>();

function getBaseUrl() {
    if (import.meta.env.VITE_TRPC_API_URL)
        return import.meta.env.VITE_TRPC_API_URL;

    if (import.meta.env.VITE_BASE_URL)
        return `${import.meta.env.VITE_BASE_URL}`;
    return `http://localhost:${import.meta.env.PORT ?? 8787}`;
}

// Create a custom query client with specific configurations
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
            staleTime: 1000 * 60 * 5, // 5 minutes
        },
    },
});

export const trpcClient = trpc.createClient({
    links: [
        loggerLink({
            enabled: (opts) =>
                (process.env.NODE_ENV === 'development' &&
                    typeof window !== 'undefined') ||
                (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpLink({
            transformer: superjson,
            url: getBaseUrl() + '/api/trpc',
            fetch(url, options) {
                return fetch(url, {
                    ...options,
                    credentials: 'include',
                    mode: 'cors',
                });
            },
            // You can pass any HTTP headers you wish here
            async headers() {
                const token = store.getState().auth.token.accessToken;
                return {
                    authorization: token,
                };
            },
        }),
    ],
});

// Configure the React Query client used by tRPC
export const trpcClientOptions = {
    // Global query options
    queries: {
        refetchOnWindowFocus: true,
    },
    queryClient,
};
