import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
    Box,
    Flex,
    HStack,
    Text,
    Input,
    useOutsideClick,
    Skeleton,
} from '@chakra-ui/react';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { trpc } from '@core/api/trpc';
import { useAtom } from 'jotai';
import { cardTitlesAtom } from '@atoms/cardTitlesAtom';

interface CompanySectionProps {
    cardId: number;
    workspaceId: number;
}

export const CompanySection: React.FC<CompanySectionProps> = ({
    cardId,
    workspaceId,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const inputRef = useRef<HTMLDivElement>(null);
    const utils = trpc.useUtils();
    const [cardTitles] = useAtom(cardTitlesAtom);

    const updateCardTitle = trpc.cards.updateTitleByCardId.useMutation({
        onSuccess: () => {
            utils.cards.getTitleByCardId.invalidate({ cardId, workspaceId });
        },
    });

    const [title, setTitle] = useState('');
    const company = cardTitles[cardId] || '';

    useEffect(() => {
        setTitle(company);
        setHasChanges(false);
    }, [company]);

    const handleTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            setTitle(newValue);
            setHasChanges(true);
        },
        [],
    );

    const handleSave = useCallback(() => {
        setIsEditing(false);

        if (!hasChanges) {
            return;
        }

        const trimmedTitle = title.trim();
        const trimmedCompany = (company || '').trim();

        if (trimmedTitle !== trimmedCompany) {
            updateCardTitle.mutateAsync({
                cardId,
                workspaceId,
                title: trimmedTitle,
            });
        }

        setHasChanges(false);
    }, [cardId, title, company, updateCardTitle, workspaceId, hasChanges]);

    useOutsideClick({
        ref: inputRef,
        handler: handleSave,
    });

    const handleFieldClick = useCallback(() => {
        if (!isEditing) {
            setIsEditing(true);
        }
    }, [isEditing]);

    return (
        <HStack spacing={1} align="flex-start">
            <HStack
                spacing={1}
                pt="8px"
                pb="4px"
                whiteSpace="nowrap"
                w="92px"
                flexShrink={0}
            >
                <Icon
                    name="company"
                    width="16px"
                    height="16px"
                    color={colors.tertiary}
                />
                <Text variant="bodyMedium" color="tertiary">
                    Company
                </Text>
            </HStack>
            <Skeleton isLoaded={true} w="full" borderRadius="4px">
                <Box
                    ref={inputRef}
                    w="full"
                    h="100%"
                    bg={isEditing ? 'gray.15' : 'transparent'}
                    transition="0.3s background ease"
                    borderRadius="4px"
                    p="6px 8px"
                    minH="24px"
                    maxH="calc(100vh - 200px)"
                    overflow="auto"
                    onClick={handleFieldClick}
                    cursor="text"
                >
                    <Flex flexWrap="wrap" columnGap={1} rowGap={1}>
                        {isEditing ? (
                            <Input
                                autoFocus
                                value={title}
                                onChange={handleTitleChange}
                                size="sm"
                                borderRadius="4px"
                                fontSize="12px"
                                lineHeight="20px"
                                height="20px"
                                p="0px 4px"
                                border="none"
                                spellCheck={false}
                                _focus={{
                                    border: 'none',
                                    boxShadow: 'none',
                                }}
                                placeholder="Enter company name..."
                            />
                        ) : (
                            <Text
                                w="full"
                                fontSize="12px"
                                lineHeight="20px"
                                color="secondary"
                            >
                                {title || 'No company yet'}
                            </Text>
                        )}
                    </Flex>
                </Box>
            </Skeleton>
        </HStack>
    );
};
