import { useAtom } from 'jotai';
import { ModalWrapper } from '../../components';
import { CreateWorkspace } from '../Onboarding/steps';
import { createWorkspaceModalAtom } from '../../atoms/modals.atoms';

export const CreateWorkspaceModal = () => {
    const [createWorkspaceModal, setCreateWorkspaceModal] = useAtom(
        createWorkspaceModalAtom,
    );

    return (
        <ModalWrapper
            isOpen={createWorkspaceModal.isOpen}
            onClose={() => setCreateWorkspaceModal({ isOpen: false })}
        >
            <CreateWorkspace isIsolated={true} />
        </ModalWrapper>
    );
};
