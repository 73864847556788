import { Text, Center, VStack } from '@chakra-ui/react';
import { IconPlugConnectedX } from '@tabler/icons-react';

export const ArchivedPlaceholder = () => {
    return (
        <Center
            h="100%"
            bg="white"
            borderRadius="8px"
            border="2px solid #ebebeb"
        >
            <VStack spacing={3}>
                <IconPlugConnectedX size={48} color="gray" stroke={1} />
                <Text color="gray.700">Telegram chat disconnected</Text>
            </VStack>
        </Center>
    );
};
