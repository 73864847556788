import { useMemo } from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { CustomTag, Icon } from '../../../components';
import { getLabelInfoByType } from '../../../utils';
import { useGetWorkspaceLabels } from '../../../queries';
import { FilterSelect, FilterSelectItem } from './FilterSelect/FilterSelect';

type LabelsSelectPopupProps = {
    initialValue: string[];
    onChange: (values: string[]) => void;
    useNameAsValue?: boolean;
};

export const LabelsSelectPopup = ({
    initialValue,
    onChange,
    useNameAsValue = false,
}: LabelsSelectPopupProps) => {
    const { data, isLoading } = useGetWorkspaceLabels();

    const activeLabels = useMemo(() => {
        if (!data) return [];

        if (useNameAsValue) {
            const lowercaseNames = initialValue.map((name) =>
                name.toLowerCase(),
            );
            return data.filter((label) =>
                lowercaseNames.includes(label.name.toLowerCase()),
            );
        } else {
            const ids = initialValue.map(Number);
            return data.filter((label) => ids.includes(label.id));
        }
    }, [data, initialValue, useNameAsValue]);

    const activeLabelIds = useMemo(
        () => activeLabels.map((label) => label.id),
        [activeLabels],
    );

    const filteredLabelItems = useMemo(
        () => data?.filter((el) => !activeLabelIds.includes(el.id)) || [],
        [data, activeLabelIds],
    );

    const selectedLabelsData = useMemo(() => {
        return activeLabels.map((label) => {
            const labelData = getLabelInfoByType(label.colourId);
            return {
                bgColor: labelData.bgColor,
                textColor: labelData.textColor,
                id: label.id,
                name: label.name,
            };
        });
    }, [activeLabels]);

    const handleLabelClick = (labelId: number) => {
        const labelToAdd = data?.find((label) => label.id === labelId);
        if (!labelToAdd) return;

        let newSelection;
        if (useNameAsValue) {
            const currentNames = activeLabels.map((label) => label.name);
            newSelection = [...currentNames, labelToAdd.name];
        } else {
            newSelection = [...activeLabelIds, labelId].map((id) =>
                id.toString(),
            );
        }

        onChange(newSelection);
    };

    const handleRemoveLabel = (labelId: number) => {
        const labelToRemove = data?.find((label) => label.id === labelId);
        if (!labelToRemove) return;

        let newSelection;
        if (useNameAsValue) {
            newSelection = activeLabels
                .filter((label) => label.id !== labelId)
                .map((label) => label.name);
        } else {
            newSelection = activeLabelIds
                .filter((id) => id !== labelId)
                .map((id) => id.toString());
        }

        onChange(newSelection);
    };

    return (
        <FilterSelect
            title="Label"
            items={filteredLabelItems.map((x) => {
                const labelData = getLabelInfoByType(x.colourId);
                return (
                    <FilterSelectItem
                        key={x.id}
                        onClick={() => handleLabelClick(x.id)}
                    >
                        <CustomTag
                            label={x.name ?? ''}
                            bgColor={labelData.bgColor}
                            labelColor={labelData.textColor}
                            wrapperProps={{
                                maxW: '100%',
                                textAlign: 'left',
                            }}
                        />
                    </FilterSelectItem>
                );
            })}
            isLoading={isLoading}
            selectedItem={
                selectedLabelsData.length > 0 ? (
                    <Flex gap="5px" flexWrap="wrap" maxW="160px">
                        {selectedLabelsData.map((labelData) => (
                            <CustomTag
                                key={labelData.id}
                                label={labelData.name ?? ''}
                                bgColor={labelData.bgColor}
                                labelColor={labelData.textColor}
                                enableCloseButton={true}
                                wrapperProps={{
                                    pr: '3px',
                                }}
                                closeButtonProps={{
                                    'aria-label': `remove ${labelData.name} filter`,
                                    onClick: (ev) => {
                                        ev.stopPropagation();
                                        handleRemoveLabel(labelData.id);
                                    },
                                }}
                                closeIconColor={labelData.textColor}
                            />
                        ))}
                    </Flex>
                ) : null
            }
            emptyItemsMessage={
                <Text color="secondary" textAlign="center">
                    No labels {data?.length ? 'anymore' : 'yet'}
                </Text>
            }
            icon={
                <Icon name="label" height="16" width="16" color={'inherit'} />
            }
        />
    );
};
