import { useMutation } from '@tanstack/react-query';
import { trpc } from '../trpc';

type SetCurrentWorkspacePayload = {
    workspaceId: number;
};

// The tRPC endpoint doesn't return this data, so we'll remove this type
// and use void as the return type instead

const MUTATION_KEY_SET_CURRENT_WORKSPACE = 'MUTATION_KEY_SET_CURRENT_WORKSPACE';

function useSetCurrentWorkspaceMutation() {
    const setCurrentMutation = trpc.workspace.setCurrent.useMutation();

    return useMutation<void, unknown, SetCurrentWorkspacePayload>({
        mutationKey: [MUTATION_KEY_SET_CURRENT_WORKSPACE],
        mutationFn: ({ workspaceId }) =>
            setCurrentMutation.mutateAsync({
                workspaceId: String(workspaceId),
            }),
    });
}

export { useSetCurrentWorkspaceMutation, MUTATION_KEY_SET_CURRENT_WORKSPACE };
