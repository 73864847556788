import { Alerter } from '../../../utils';
import { trpc } from '@core/api/trpc';

export const useRemoveUserFromWorkspace = () => {
    const utils = trpc.useUtils();
    return trpc.workspace.removeUserFromWorkspace.useMutation({
        onSuccess(data) {
            Alerter.success(data.message);
            utils.workspace.getWorkspaceMembers.invalidate();
        },
        onError(error) {
            Alerter.error(error.message);
        },
    });
};
