import { Alerter } from '../../../../../utils';
import { trpc } from '../../../../../core/api/trpc';

export interface DeleteLabelPayload {
    workspaceId: number;
    workspaceLabelId: number;
}

export const useDeleteLabel = () => {
    const utils = trpc.useUtils();
    const deleteLabelMutation = trpc.labels.deleteWorkspaceLabel.useMutation({
        onMutate: async (variables) => {
            await utils.workspace.getWorkspaceLabels.cancel();
            const previousLabels =
                await utils.workspace.getWorkspaceLabels.getData();
            utils.workspace.getWorkspaceLabels.setData(
                undefined,
                previousLabels?.filter(
                    (label) => label.id !== variables.labelId,
                ),
            );
            return { previousLabels };
        },
        onSuccess: () => {
            Alerter.success('Label was successfully deleted!');
        },
        onSettled: () => {
            utils.workspace.getWorkspaceLabels.invalidate();
        },
    });

    return deleteLabelMutation;

    // return useMutation<ApiResponseEmpty, unknown, DeleteLabelPayload>({
    //     mutationKey: [QueryKeys.DELETE_LABEL],
    //     mutationFn: async (payload) => {
    //         const { workspaceId, workspaceLabelId } = payload;

    //         const result = await deleteLabelMutation.mutateAsync({
    //             workspaceId,
    //             labelId: workspaceLabelId,
    //         });

    //         // Format the response to match the legacy API format
    //         return {
    //             success: true,
    //             status: 200,
    //             errors: [],
    //             messages: [{ title: '', message: result.message }],
    //             value: result,
    //         };
    //     },
    //     // Optimistically update the cache before the server responds
    //     onMutate: async (variables) => {
    //         // Cancel any outgoing refetches to avoid overwriting our optimistic update
    //         await queryClient.cancelQueries({
    //             queryKey: [
    //                 QueryKeys.GET_WORKSPACE_LABELS,
    //                 variables.workspaceId,
    //             ],
    //         });

    //         // Snapshot the previous value
    //         const previousLabels = queryClient.getQueryData<{
    //             value: { workspaceLabels: LabelItemType[] };
    //         }>([QueryKeys.GET_WORKSPACE_LABELS, variables.workspaceId]);

    //         // Optimistically update to the new value
    //         if (previousLabels?.value?.workspaceLabels) {
    //             queryClient.setQueryData(
    //                 [QueryKeys.GET_WORKSPACE_LABELS, variables.workspaceId],
    //                 {
    //                     ...previousLabels,
    //                     value: {
    //                         ...previousLabels.value,
    //                         workspaceLabels:
    //                             previousLabels.value.workspaceLabels.filter(
    //                                 (label) =>
    //                                     label.id !== variables.workspaceLabelId,
    //                             ),
    //                     },
    //                 },
    //             );
    //         }

    //         // Return a context object with the snapshotted value
    //         return { previousLabels };
    //     },
    //     // If the mutation fails, use the context returned from onMutate to roll back
    //     onError: (_err, variables, context) => {
    //         if ((context as any)?.previousLabels) {
    //             queryClient.setQueryData(
    //                 [QueryKeys.GET_WORKSPACE_LABELS, variables.workspaceId],
    //                 (context as any).previousLabels,
    //             );
    //         }
    //         Alerter.error('Failed to delete label. Please try again.');
    //     },
    //     onSuccess: (data, variables) => {
    //         if (!data.success) {
    //             Alerter.error('Failed to delete label');
    //             return;
    //         }

    //         Alerter.success(
    //             data.messages?.[0]?.message ||
    //                 'Label was successfully deleted!',
    //         );

    //         const { workspaceId } = variables;

    //         // Use TRPC utils to invalidate the query
    //         utils.workspace.getWorkspaceLabels.invalidate();

    //         // Invalidate the query to trigger a refetch
    //         queryClient.invalidateQueries({
    //             queryKey: [QueryKeys.GET_WORKSPACE_LABELS, workspaceId],
    //             exact: true,
    //         });
    //     },
    // });
};
