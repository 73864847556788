import React, { useCallback, useMemo } from 'react';
import { Box, Button, HStack, StackProps, Text } from '@chakra-ui/react';
import { CustomTag, LabelWithImage, RadixCheckbox } from '../../../components';
import { getAvatarBg, getViewDateByFormat } from '../../../utils';
import { NotificationItemData } from '../types';
import { PriorityTag } from './PriorityTag';
import { getNotificationLabelByType } from '../utils';
import { useArchiveNotification } from '../queries';
import { useChats } from '../../../hooks/useChats';
import { useAvatar } from '../../../components/TaskCard/hooks';

interface NotificationItemProps
    extends Omit<
        NotificationItemData,
        'cardId' | 'priorityId' | 'cardName' | 'priorityName' | 'telegramChatId'
    > {
    containerProps?: StackProps;
    isInboxTab?: boolean;
    date: string;
    cardId: number | null;
    priorityId: number | null;
    cardName: string | null;
    priorityName: string | null;
    telegramChatId: number | null;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
    priorityId,
    id,
    scheduledAt,
    description,
    cardName,
    cardId,
    priorityName,
    type,
    containerProps,
    isInboxTab,
    date,
    telegramChatId,
}) => {
    const { archiveNotification } = useArchiveNotification();

    const chat = useChats().getChatInfo(
        telegramChatId ? telegramChatId.toString() : '',
    );
    const chatAvatar = useAvatar(chat?.id, chat?.accessHash);

    const { formattedDate, formattedTime } = useMemo(
        () => ({
            formattedDate: getViewDateByFormat(date, 'MMM D, YYYY'),
            formattedTime: getViewDateByFormat(scheduledAt, 'hh:mma'),
        }),
        [date, scheduledAt],
    );

    const onChangeStatusClick = useCallback(() => {
        if (!isInboxTab) return;
        archiveNotification(id);
    }, [id, isInboxTab, archiveNotification]);

    return (
        <HStack
            w="full"
            px={4}
            py="14px"
            align="center"
            justify="space-between"
            spacing={2}
            position="relative"
            overflow="hidden"
            borderBottomWidth="1px"
            borderBottomColor="gray.20"
            bg="gray.10"
            _last={{ borderBottomWidth: 0 }}
            {...containerProps}
        >
            <HStack spacing={2}>
                {isInboxTab ? (
                    <Box w="16px" minW="16px" h="16px">
                        <RadixCheckbox
                            checked={false}
                            onClick={onChangeStatusClick}
                        />
                    </Box>
                ) : null}
                <CustomTag
                    label={getNotificationLabelByType(type)}
                    bgColor="#00000005"
                    labelColor="gray.40"
                    wrapperProps={{
                        borderWidth: '1px',
                        borderColor: 'gray.25',
                        userSelect: 'none',
                    }}
                />
                <Text
                    color="primary"
                    fontSize="13px"
                    fontWeight="500"
                    noOfLines={1}
                >
                    {description}
                </Text>
            </HStack>
            <HStack align="center" justify="flex-end" spacing={2}>
                <Button
                    variant="unstyled"
                    h="20px"
                    cursor="default"
                    // onClick={() => navigate(`/${workspaceId}/chat/${workspaceCard.id}`)}
                >
                    <LabelWithImage
                        label={cardName || ''}
                        imageLink={chatAvatar || ''}
                        includeAvatar={true}
                        avatarProps={{
                            bg: getAvatarBg(cardId || 0),
                            borderRadius: '2px',
                        }}
                        imageWrapperProps={{
                            borderRadius: '2px',
                        }}
                    />
                </Button>

                <PriorityTag
                    type={type}
                    priorityId={priorityId}
                    priorityName={priorityName}
                />
                <Text
                    fontSize="13px"
                    lineHeight="20px"
                    fontWeight="400"
                    color="secondary"
                    sx={{
                        textWrap: 'nowrap',
                    }}
                >
                    {formattedTime}
                </Text>

                <Text
                    fontSize="13px"
                    lineHeight="20px"
                    fontWeight="400"
                    color="secondary"
                    sx={{
                        textWrap: 'nowrap',
                    }}
                >
                    {formattedDate}
                </Text>
            </HStack>
        </HStack>
    );
};
