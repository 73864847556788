import { useAtom } from 'jotai';
import { trpc } from '@core/api/trpc';
import { cardInfoAtom } from '../atoms/cardInfoAtom';
import { useEffect } from 'react';

export const useWorkspaceCardInfo = (workspaceId?: number) => {
    const [cardInfo, setCardInfo] = useAtom(cardInfoAtom);

    const { data } = trpc.cards.getAllCardInfo.useQuery(
        { workspaceId: workspaceId || 0 },
        { enabled: !!workspaceId },
    );

    useEffect(() => {
        if (data?.length && workspaceId) {
            const infoMap = data.reduce(
                (acc, card) => ({
                    ...acc,
                    [card.cardId]: card.chatInfo || '',
                }),
                {},
            );
            setCardInfo(infoMap);
        }
    }, [data, workspaceId, setCardInfo]);

    return cardInfo;
};
