import { trpc } from '@core/api/trpc';

export const useGetCardReminders = (workspaceId?: number, cardId?: number) => {
    const { data, isLoading } = trpc.reminders.getCardReminders.useQuery(
        {
            cardId: cardId as number,
        },
        {
            enabled: !!workspaceId && !!cardId,
        },
    );

    return { data, isLoading };
};
