import { BaseUserData } from './global.types';
import { MediaContent } from '../../../telegram-tt/src/api/types';
import { ApiChatType } from './api.types';

export enum CardDefaultStatusEnum {
    LEAD = 1,
    CONTACTED = 2,
    NEGOTIATION = 3,
    READY_TO_START = 4,
    CLOSED = 5,
    ARCHIVE = 6,
}

export enum CardTaskStatusEnum {
    TODO = 1,
    IN_PROGRESS = 2,
    DONE = 3,
}

export enum CardTaskPriorityEnum {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export type CardTaskPriority = {
    id: CardTaskPriorityEnum;
    name: string;
};

//that type will be changed in future with additional bgColor and textColor fields
export type CardStatusItem = {
    id: number;
    name: string;
    typeId: number;
    position: number;
};

export type UpdateWorkspaceCardPayload = {
    workspaceId: number;
    cardId: number;
    statusId: number;
};

export type UpdateCardTrackStatusPayload = {
    workspaceId: number;
    cardId: number;
    trackedLastTelegramMessageUnixTime: number | null;
};

export type TgLastMessage = {
    id: number;
    text: string | null;
    content?: MediaContent;
    date?: number;
};

export type TgChatCardByIdItem = {
    id: number;
    unreadCount: number;
    lastMessage: TgLastMessage | null;
    chatAvatarDataUri?: string;
    sender: BaseUserData | null;
    hasAccess?: boolean;
    isLoaded?: boolean;
};

export type TgFoldersResponseItem = {
    telegramChatsIds: number[];
    telegramFolderId: number;
};

export type TgSyncChatPayloadData = {
    cardId?: number;
    telegramId: number;
};

export type TgUserData = {
    id: number;
    username: string | null;
    // firstName: string;
    // lastName?: string;
};

export type TgSenderUserData = {
    id: number;
    firstName: string;
    lastName: string;
    avatarThumbnailUrl: string;
    accessHash?: string;
};

export type TgDataForCard = {
    id: number;
    isLoaded: boolean;
    title: string;
    type?: ApiChatType;
    hasAccess: boolean;
    unreadCount: number;
    lastMessage: {
        id: number;
        text: string | null;
        content: MediaContent;
        date: number;
    } | null;
    chatAvatarDataUri: string;
    sender: TgSenderUserData | null;
    chatMembers: TgUserData[];
    accessHash?: string;
    isChatFullyLoaded: boolean;
    isRefetchDisabled: boolean;
};
