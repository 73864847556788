import React, { useCallback } from 'react';
import { CustomTag } from '../../../../../components';
import { getLabelInfoByType } from '../../../../../utils';
import { LabelItemType } from '../../../../../types';
import { useUpdateLabelAssignStatus } from '../queries';

interface CardLabelItemProps extends LabelItemType {
    enableCloseButton: boolean;
    cardId: number;
}

export const CardLabelItem: React.FC<CardLabelItemProps> = ({
    enableCloseButton,
    colourId,
    name,
    id,
    cardId,
}) => {
    const updateLabelAssignStatusMutation = useUpdateLabelAssignStatus();
    const { bgColor, textColor } = getLabelInfoByType(colourId);

    const onAssignLabelToCard = useCallback(
        (e: React.MouseEvent) => {
            // Stop propagation to prevent the popover from closing
            e.stopPropagation();
            e.preventDefault();

            console.log('CardLabelItem onAssignLabelToCard', cardId);

            // Call the mutation directly
            updateLabelAssignStatusMutation.mutate({
                labelId: id,
                cardId,
                isAssigned: false,
            });
        },
        [cardId, updateLabelAssignStatusMutation, id],
    );

    return (
        <CustomTag
            label={name}
            bgColor={bgColor}
            labelColor={textColor}
            enableCloseButton={enableCloseButton}
            wrapperProps={{
                pr: enableCloseButton ? '3px' : '8px',
            }}
            labelProps={{
                maxW: '164px',
            }}
            closeButtonProps={{
                'aria-label': 'un-assign label from card',
                onClick: onAssignLabelToCard,
            }}
            closeIconColor={textColor}
        />
    );
};
