import React from 'react';
import { Box, BoxProps, Heading, chakra } from '@chakra-ui/react';
import { CardTaskItem } from '../../Chat/types';
import { Task } from '../../../components';
import { useSelector } from 'react-redux';
import { RootStore } from '../../../redux/createStore';
import { useChats } from '../../../hooks/useChats';

interface TasksSectionProps {
    title: string;
    sectionProps?: BoxProps;
    totalCount: number;
    data: CardTaskItem[];
    isSinglePageSection: boolean;
    onUpdateTaskStatus?: (
        cardTaskId: number,
        status: number,
        isCardArchived: boolean,
        telegramUsersIds: number[],
    ) => void;
}

export const TasksSection: React.FC<TasksSectionProps> = ({
    title,
    sectionProps,
    totalCount,
    data,
    isSinglePageSection,
    onUpdateTaskStatus,
}) => {
    const avatars = useSelector((state: RootStore) => state.avatars);
    const { workSpace, id: currentUserId } = useSelector(
        (state: RootStore) => state.auth.user,
    );
    const chats = useChats();

    if (!data.length) {
        return null;
    }

    return (
        <Box {...sectionProps}>
            <Heading color="primary" fontSize="16px" fontWeight="600">
                {title}
                <chakra.span color="gray.35" ml={2}>
                    {totalCount}
                </chakra.span>
            </Heading>
            <Box
                mt={4}
                maxHeight={
                    isSinglePageSection ? 'calc(100vh - 230px)' : '300px'
                }
                borderWidth="1px"
                borderColor="gray.20"
                background="gray.10"
                borderRadius="8px"
                overflowY="auto"
            >
                {data.map((task) => (
                    <Task
                        key={task.id}
                        onUpdateTaskStatus={(status, telegramUsersIds) =>
                            onUpdateTaskStatus?.(
                                task.id,
                                status,
                                task.isArchived,
                                telegramUsersIds,
                            )
                        }
                        userAvatar={avatars[`${task.telegramUserId}`]}
                        includeActionsMenu={
                            workSpace?.isAdmin ||
                            !task.authorUserId ||
                            currentUserId === task.authorUserId
                        }
                        isTaskPage={true}
                        cardId={task.workspaceCard.id}
                        chatTelegramId={task.workspaceCard.telegramChatId}
                        cachedTgChatData={chats.getChatInfo(
                            task.workspaceCard.telegramChatId.toString(),
                        )}
                        {...task}
                    />
                ))}
            </Box>
        </Box>
    );
};
