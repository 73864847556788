import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

const DASHBOARD_FILTERS_KEY = 'dashboard_filters';

export const useNavigateWithFilters = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const navigateWithFilters = (to: string, state?: Record<string, any>) => {
    // When navigating to dashboard, restore the search params
    if (to.endsWith(location.pathname.split('/')[1])) {
      const dashboardParams = sessionStorage.getItem(DASHBOARD_FILTERS_KEY);
      if (dashboardParams) {
        // Preserve current tab if it exists in the URL
        const currentTab = new URLSearchParams(window.location.search).get('tab');
        const params = new URLSearchParams(dashboardParams);
        if (currentTab) {
          params.set('tab', currentTab);
        }
        navigate(to + '?' + params.toString(), { state });
        return;
      }
    }

    // Store dashboard params when leaving dashboard
    if (location.pathname.endsWith(location.pathname.split('/')[1])) {
      // We're on dashboard, store its params including the tab
      sessionStorage.setItem(DASHBOARD_FILTERS_KEY, searchParams.toString());
      navigate(to, { state });
      return;
    }

    // Default navigation
    navigate(to, { state });
  };

  return navigateWithFilters;
}; 