import { useSelector } from 'react-redux';
import { RootStore } from '../redux/createStore';
import { getMillisecondsUntilNextNineAM } from '../utils';
import { useGetMatchedWorkspaceId } from '../hooks';
import { trpc } from '@core/api/trpc';

export const useGetNotificationsCount = () => {
    const workspaceId = useGetMatchedWorkspaceId();
    const { isOnboarded } = useSelector((state: RootStore) => state.auth.user);
    const refetchInterval = getMillisecondsUntilNextNineAM();

    return trpc.notifications.getWorkspaceNotificationsCount.useQuery(
        { workspaceId: Number(workspaceId) },
        {
            refetchOnWindowFocus: false,
            refetchInterval,
            enabled: !!workspaceId && isOnboarded,
        },
    );
};
