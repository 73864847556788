import { TelegramWindowContextType, useTelegram } from '../../../services';
import { useQuery } from '@tanstack/react-query';

import { Maybe } from '../../../types';

function blobToBase64(blob: Maybe<Blob | string>): Promise<Maybe<string>> {
    if (!blob) return Promise.resolve(null);
    if (typeof blob === 'string') return Promise.resolve(blob);
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
}

const loadImage = async (
    tg: Maybe<TelegramWindowContextType>,
    userId?: string,
    hash?: string,
) => {
    if (!tg || !userId) return null;
    if (['null', 'undefined'].some((v) => hash?.includes(v))) return null;

    try {
        const userInfo = await tg.methods.proxy.fetchFullUser({
            id: userId,
        });

        if (!userInfo?.fullInfo?.profilePhoto?.thumbnail?.dataUri) {
            return null;
        }

        return userInfo.fullInfo.profilePhoto.thumbnail.dataUri;
    } catch (error) {
        console.error('Error loading user avatar:', error);
        return null;
    }
};

export const useAvatar = (
    id?: string,
    avatarHash?: string,
    placeholder?: string,
) => {
    const tg = useTelegram();

    const avatar = useQuery({
        queryKey: ['avatar', id, avatarHash],
        queryFn: () => loadImage(tg, id, avatarHash),
        enabled: !!tg && !!id,
    });

    return (
        avatar.data ??
        (placeholder
            ? `https://api.dicebear.com/9.x/initials/svg?seed=${placeholder}`
            : null)
    );
};
