import React, { useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { InputText } from '../../../components';
import { modalRegularTextStyleProps, modalSubHeaderStyleProps } from './helper';
import { CreateWorkspaceForm, createWorkspaceResolver } from '../validation';
import { UploadWorkspaceAvatar } from '../components';
import { useCreateWorkspaceMutation } from '@core/api';
import { useSetCurrentWorkspaceMutation } from '@core/api/mutations/set-current-workspace';
import { useNavigate } from 'react-router-dom';
import { createWorkspaceModalAtom } from '../../../atoms/modals.atoms';
import { useAtom } from 'jotai';
import { Alerter } from '../../../utils';

interface ICreateWorkspaceProps {
    isIsolated?: boolean;
}

export const CreateWorkspace: React.FC<ICreateWorkspaceProps> = ({
    isIsolated = false,
}) => {
    const [imageFromFile, setImageFromFile] = useState('');
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();
    const [_, setCreateWorkspaceModal] = useAtom(createWorkspaceModalAtom);

    const {
        register,
        handleSubmit,
        formState: { errors },
        clearErrors,
    } = useForm<CreateWorkspaceForm>({ resolver: createWorkspaceResolver });

    const createWorkspaceMutation = useCreateWorkspaceMutation();
    const setCurrentWorkspaceMutation = useSetCurrentWorkspaceMutation();

    const showImage = (file: File) => {
        const imageDataUrl = URL.createObjectURL(file);
        setImageFile(file);
        setImageFromFile(imageDataUrl);
        clearErrors('image');
    };

    const removeImage = () => {
        setImageFromFile('');
        setImageFile(null);
    };

    const onSubmit = async (data: CreateWorkspaceForm) => {
        if (isSubmitting) return;
        setIsSubmitting(true);

        try {
            const fd = new FormData();
            fd.append('Name', data.name);
            if (imageFile) {
                fd.append('NewImage', imageFile);
            }

            let filePayload;
            if (imageFile) {
                const buffer = await imageFile.arrayBuffer();
                const base64 = Buffer.from(buffer).toString('base64');
                filePayload = {
                    base64,
                    mimetype: imageFile.type,
                    originalname: imageFile.name,
                    size: imageFile.size,
                };
            }

            // Create workspace
            const workspaceId = await createWorkspaceMutation.mutateAsync({
                name: data.name,
                file: filePayload,
            });

            // Set as current workspace
            await setCurrentWorkspaceMutation.mutateAsync({
                workspaceId,
            });

            setTimeout(() => {
                // Close modal
                setCreateWorkspaceModal({ isOpen: false });

                // Navigate to workspace
                navigate(`/${workspaceId}`);

                Alerter.success('Workspace created successfully');
            }, 1000);
        } catch (error) {
            console.error('Error creating workspace:', error);
            Alerter.error('Failed to create workspace');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection={'column'} align={'center'} gap={'30px'}>
                <Box>
                    <Text
                        fontWeight={'600'}
                        fontSize={'20px'}
                        lineHeight={'24px'}
                        textAlign={'center'}
                    >
                        Create your workspace
                    </Text>
                    <Text textAlign={'center'} mt={'16px'} color={'secondary'}>
                        A shared environment where you will be able to manage
                        your customer relations with your team.
                    </Text>
                </Box>

                <UploadWorkspaceAvatar
                    imageFromFile={imageFromFile}
                    removeImage={removeImage}
                    showImage={showImage}
                    title="Workspace logo"
                />

                <Box w={'100%'}>
                    <Text {...modalSubHeaderStyleProps}>Workspace name</Text>
                    <Text {...modalRegularTextStyleProps}>
                        The name of your organization
                    </Text>
                    <InputText
                        {...register('name')}
                        mt={'16px'}
                        placeholder={'Apple'}
                        errorMsg={errors?.name?.message}
                    />
                </Box>

                <Button
                    w={'65%'}
                    type="submit"
                    isLoading={
                        createWorkspaceMutation.isPending || isSubmitting
                    }
                >
                    {isIsolated ? 'Create' : 'Continue'}
                </Button>
            </Flex>
        </form>
    );
};
