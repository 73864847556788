import { useQuery } from '@tanstack/react-query';
import API from '../../../api/api.base';
import { BoardCardItemResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { useGetMatchedWorkspaceId } from '../../../hooks';
import { trpc } from '@core/api/trpc';

export const useGetBoardCards = () => {
    const workspaceId = useGetMatchedWorkspaceId();
    const { data: ownersData } = trpc.cards.getAllOwnersCards.useQuery(
        { workspaceId },
        { enabled: !!workspaceId },
    );

    return trpc.workspace.getWorkspaceCards.useQuery(
        {
            workspaceId,
        },
        {
            enabled: !!workspaceId,
            refetchInterval: 30 * 1000, //refetch data every 30s
            select: (data) => {
                // Create BoardCardItemResponse objects manually to avoid type issues
                return (data?.cards ?? []).map((card) => {
                    // Find owner ID or default to card ID
                    const ownerId =
                        ownersData?.find(
                            (ownerCard) => ownerCard.cardId === card.cardId,
                        )?.cardOwnerId ?? card.cardId;

                    // Create a new object that matches BoardCardItemResponse
                    return {
                        cardId: card.cardId,
                        cardName: card.cardName,
                        chatTelegramId: card.chatTelegramId,
                        tasksCount: card.tasksCount,
                        hasAccess: card.hasAccess,
                        trackedLastTelegramMessageUnixTime:
                            card.trackedLastTelegramMessageUnixTime,
                        status: card.status,
                        message: card.message,
                        currentUserTagged: card.currentUserTagged,
                        // Transform labels to match LabelItemType structure
                        labels: (card.labels || []).map((label) => {
                            // A label is assigned to this card if its workspaceCardId matches
                            // the card's id (not cardId) or in the UI when assigning/removing labels
                            return {
                                id: parseInt(label.labelId),
                                workspaceId: workspaceId,
                                name: label.name,
                                colourId: parseInt(label.colourId),
                                changedAt: null,
                                createdAt: new Date().toISOString(),
                                // This is the key field that determines if a label is assigned to this card
                                workspaceCardId: label.workspaceCardId,
                                createdById: null,
                                changedById: null,
                            };
                        }),
                        // Add missing required fields
                        createdById: card.cardId, // Use cardId as fallback
                        ownerId: ownerId,
                        workspaceCardId: card.cardId, // Use cardId as fallback
                    } as BoardCardItemResponse;
                });
            },
        },
    );
};
