import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../constants';
import { Alerter } from '../utils';
import { useGetWorkspaceId } from '../hooks';
import { trpc } from '@core/api/trpc';

export const useDeleteTask = (isTaskPage?: boolean, cardId?: number) => {
    const workspaceId = useGetWorkspaceId();
    const queryClient = useQueryClient();
    const deleteTaskMutation = trpc.tasks.deleteTask.useMutation({
        onSuccess(_) {
            Alerter.success('Task successfully deleted');

            if (isTaskPage) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_ALL_CURRENT_USER_TASKS],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_ALL_TASKS_OTHER_USERS],
                });
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_USER_TASKS_COUNT, workspaceId],
                });
            }

            if (cardId) {
                queryClient.invalidateQueries({
                    queryKey: [QueryKeys.GET_CARD_TASKS, cardId, workspaceId],
                });
            }
        },
        onError(error: any) {
            Alerter.error(
                error?.message || 'Error occurred while deleting task',
            );
        },
    });

    return {
        deleteTask: (taskId: number) =>
            deleteTaskMutation.mutate({
                workspaceId: workspaceId.toString(),
                id: taskId.toString(),
            }),
    };
};
