import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useAtom } from 'jotai';

import css from './index.module.css';

import { selectedCardsAtom } from '../state';
import { useGetBoardCards } from '@features/Dashboard/queries';
import { useGetWorkspaceCardsStatuses } from '@queries/useGetWorkspaceCardsStatuses';
import { BoardCardItemResponse } from '@features/Dashboard/types';
import {
    CardSelectionTree,
    cardKeyPrefix,
} from '../../../components/CardSelectionTree/CardSelectionTree';

const checkedKeysLimit = 30;

interface BatchSendingSelectCardsProps {
    goToNext: () => void;
    goToPrevious: () => void;
    cancel: () => void;
}
function BatchSendingSelectCards({
    goToNext,
    cancel,
}: BatchSendingSelectCardsProps) {
    const [selectedCards, setSelectedCards] = useAtom(selectedCardsAtom);

    const [checkedKeys, setCheckedKeys] = useState<string[]>(
        selectedCards.map(({ cardId }) => `${cardKeyPrefix}${cardId}`),
    );

    const getBoardCards = useGetBoardCards();

    const getWorkspaceCardsStatuses = useGetWorkspaceCardsStatuses();

    if (getBoardCards.isLoading || getWorkspaceCardsStatuses.isLoading)
        return <>Loading</>;

    if (getBoardCards.isError || getWorkspaceCardsStatuses.isError)
        return <>Error</>;

    if (!getBoardCards.data || !getWorkspaceCardsStatuses.data)
        return <>Empty</>;

    const checkedCardKeys = checkedKeys.filter((id) =>
        id.startsWith(cardKeyPrefix),
    );

    const handleCheck = (checkedKeys: string[]) => {
        setCheckedKeys(checkedKeys);
    };

    const handleGoToNext = () => {
        if (!checkedKeys.length) return;

        const selectedCards = checkedKeys.reduce<BoardCardItemResponse[]>(
            (acc, id) => {
                const card = getBoardCards.data.find(
                    (item) =>
                        item.cardId === parseInt(id.replace(cardKeyPrefix, '')),
                );

                if (card) return acc.concat([card]);

                return acc;
            },
            [],
        );

        setSelectedCards(selectedCards);

        goToNext();
    };

    const handleCancel = () => {
        cancel();
    };

    return (
        <>
            <header className={css.header}>
                <h3 className={css.subtitle}>Select Audience</h3>

                <p className={css.hint}>
                    You can also select chats directly on the dashboard
                </p>
            </header>

            <div className={css.selection}>
                <CardSelectionTree
                    checkedKeys={checkedKeys}
                    onCheck={(checkedKeys) => handleCheck(checkedKeys)}
                    className={css.tree}
                />

                <div className={css.total}>
                    {checkedCardKeys.length} chats selected (limited to:{' '}
                    {checkedKeysLimit})
                </div>
            </div>

            <footer className={css.footer}>
                <Button
                    variant="outline"
                    isDisabled={
                        !checkedCardKeys.length ||
                        checkedCardKeys.length > checkedKeysLimit
                    }
                    title={
                        !checkedCardKeys.length ||
                        checkedCardKeys.length > checkedKeysLimit
                            ? `You must select at least one chat, limited to ${checkedKeysLimit}`
                            : 'Continue to next step'
                    }
                    className={css.continueButton}
                    onClick={handleGoToNext}
                >
                    Continue
                </Button>

                <Button variant="ghost" onClick={handleCancel}>
                    Return to dashboard
                </Button>
            </footer>
        </>
    );
}

export default BatchSendingSelectCards;
