import { trpc } from '@core/api/trpc';
import { CardTaskStatusEnum } from '../../../types/cards.types';

export const useGetAllTasksOtherUsers = (
    status: CardTaskStatusEnum,
    workspaceId?: number | null,
) => {
    const query = trpc.tasks.getOtherUsersTasks.useQuery(
        {
            workspaceId: workspaceId ?? 0,
            status,
        },
        {
            enabled: !!workspaceId,
        },
    );
    return query;
};
